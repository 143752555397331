import { Icon } from "@chakra-ui/react";

export default function AddIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <rect x="9" width="2" height="20" />
      <rect x="20" y="9" width="2" height="20" transform="rotate(90 20 9)" />
    </Icon>
  );
}
