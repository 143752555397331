import { Icon } from "@chakra-ui/react";

export default function EyeIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path d="M16.8099 8.76489C15.1249 5.64989 12.1699 3.76489 8.89986 3.76489C5.62986 3.76489 2.66986 5.64989 0.999863 8.76489L0.859863 8.99989L0.989863 9.23989C2.67486 12.3549 5.62986 14.2399 8.89986 14.2399C12.1699 14.2399 15.1299 12.3799 16.8099 9.23989L16.9399 8.99989L16.8099 8.76489ZM8.89986 13.2149C6.08486 13.2149 3.49986 11.6449 1.99986 8.99989C3.49986 6.35489 6.08486 4.78489 8.89986 4.78489C11.7149 4.78489 14.2699 6.35989 15.7949 8.99989C14.2699 11.6449 11.7099 13.2149 8.89986 13.2149Z" />
      <path d="M9.04511 5.58505C8.36717 5.59 7.70589 5.79574 7.14482 6.17629C6.58374 6.55684 6.14802 7.09513 5.89269 7.72317C5.63736 8.35121 5.57387 9.04084 5.71025 9.70494C5.84663 10.369 6.17675 10.9778 6.65892 11.4544C7.1411 11.931 7.75369 12.254 8.41933 12.3827C9.08498 12.5113 9.77381 12.4398 10.3988 12.1772C11.0239 11.9145 11.557 11.4726 11.931 10.9071C12.305 10.3416 12.5031 9.678 12.5001 9.00005C12.4981 8.54877 12.4071 8.10231 12.2323 7.68626C12.0575 7.27021 11.8023 6.89275 11.4814 6.5755C11.1604 6.25826 10.78 6.00747 10.3619 5.83751C9.94387 5.66755 9.49638 5.58176 9.04511 5.58505ZM9.04511 11.4451C8.56623 11.4401 8.0995 11.2938 7.70351 11.0245C7.30753 10.7551 6.99996 10.3748 6.81943 9.93122C6.6389 9.48766 6.59346 9.00063 6.68881 8.53133C6.78417 8.06202 7.01607 7.63135 7.35539 7.29341C7.69472 6.95547 8.12633 6.72533 8.59602 6.63189C9.06572 6.53844 9.55255 6.58587 9.99537 6.76821C10.4382 6.95055 10.8173 7.25967 11.085 7.65675C11.3527 8.05383 11.4971 8.52116 11.5001 9.00005C11.5014 9.32235 11.4388 9.64171 11.3157 9.93961C11.1927 10.2375 11.0118 10.508 10.7834 10.7354C10.555 10.9629 10.2838 11.1427 9.9854 11.2645C9.68701 11.3863 9.3674 11.4477 9.04511 11.4451Z" />
    </Icon>
  );
}
