export default {
  badgeStyle: {
    position: "absolute",
    top: "-1.25",
    left: "7.5",
    backgroundColor: "red.500",
    color: "white.900",
    fontWeight: "small",
    width: "auto",
    maxWidth: "12.5",
    minWidth: "4",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
};
