export default {
  none: "0",
  px: "1px",
  0.5: "0.125rem", // 2px
  0.75: "0.188rem", // 3px
  1: "0.25rem", // 4px
  1.25: "0.313rem", // 5px
  1.5: "0.375rem", // 6px
  1.75: "0.438rem", // 7px
  2: "0.5rem", // 8px
  2.25: "0.563rem", // 9px
  2.5: "0.625rem", // 10px
  2.75: "0.688rem", // 11px
  3: "0.75rem", // 12px
  3.25: "0.813rem", // 13px
  3.5: "0.875rem", // 14px
  3.75: "0.938rem", // 15px
  4: "1rem", // 16px
  4.25: "1.063rem", // 17px
  4.5: "1.125rem", // 18px
  4.75: "1.188rem", // 19px
  5: "1.25rem", // 20px
  5.5: "1.375rem", // 22px
  6: "1.5rem", // 24px
  6.5: "1.625rem", // 26px
  7: "1.75rem", // 28px
  7.5: "1.875rem", // 30px
  8: "2rem", // 32px
  8.5: "2.125rem", // 34px
  9: "2.25rem", // 36px
  9.5: "2.375rem", // 38px
  10: "2.5rem", // 40px
  10.5: "2.625rem", // 42px
  11: "2.75rem", // 44px
  11.5: "2.875rem", // 46px
  12: "3rem", // 48px
  12.5: "3.125rem", // 50px
  13: "3.25rem", // 52px
  13.5: "3.375rem", // 54px
  14: "3.5rem", // 56px
  14.5: "3.625rem", // 58px
  15: "3.75rem", // 60px
  15.5: "3.875rem", // 62px
  16: "4rem", // 64px
  16.5: "4.125rem", // 66px
  17: "4.25rem", // 68px
  17.5: "4.375rem", // 70px
  18: "4.5rem", // 72px
  18.5: "4.625rem", // 74px
  19: "4.75rem", // 76px
  19.5: "4.875rem", // 78px
  20: "5rem", // 80px
  20.5: "5.125rem", // 82px
  21: "5.25rem", // 84px
  21.5: "5.375rem", // 86px
  22: "5.5rem", // 88px
  22.5: "5.625rem", // 90px
  23: "5.75rem", // 92px
  23.5: "5.875rem", // 94px
  24: "6rem", // 96px
  24.5: "6.125rem", // 98px
  25: "6.25rem", // 100px
  25.5: "6.375rem", // 102px
  26: "6.5rem", // 104px
  26.5: "6.625rem", // 106px
  27: "6.75rem", // 108px
  27.5: "6.875rem", // 110px
  28: "7rem", // 112px
  30: "7.25rem", // 116px
  31: "7.5rem", // 120px
  32: "8rem", // 128px
  35: "8.75rem", // 140px
  36: "9rem", // 144px
  37: "9.375rem", // 150px
  38: "9.5rem", // 158px
  40: "10rem", // 160px
  42: "10.5rem", // 168px
  44: "11rem", // 176px
  48: "12rem", // 192px
  50: "12.5rem", // 200px
  52: "13rem", // 208px
  53: "13.25rem", // 212px
  56: "14rem", // 224px
  60: "15rem", // 240px
  62: "15.625rem", // 250px
  64: "16rem", // 256px
  66: "16.25rem", // 260px
  72: "18rem", // 288px
  80: "20rem", // 320px
  88: "21,5rem", // 344px
  96: "24rem", // 384px
  110: "26.25rem", // 420px
  118: "29.625rem", // 474px
  120: "30.375rem", // 486px
  140: "35rem", // 560px
  max: "max-content",
  min: "min-content",
  full: "100%",
  "3xs": "14rem", // 224px
  "2xs": "16rem", // 256px
  xs: "20rem", // 320px
  sm: "24rem", // 384px
  md: "28rem", // 448
  lg: "32rem", // 512px
  xl: "36rem", // 576px
  "2xl": "42rem", // 672px
  "3xl": "48rem", // 768
  "4xl": "56rem", // 896px
  "5xl": "64rem", // 1024px
  "6xl": "72rem", // 1152px
  "7xl": "77rem", // 1232x
  "8xl": "80rem", // 1280px
  "9xl": "90rem", // 1440
};
