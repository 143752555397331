import React from "react";
import { Header } from "components/Header/Header";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import signInBGIcon from "assets/img/signInBG.png";
import registerBGIcon from "assets/img/registerPageBG.png";
import registerMobileBGIcon from "assets/img/mobileBackgroundImg.png";
import { PATHS } from "constants/constants";
import CHS from "./chakra.styles";

export default function LayoutAuth({ children }) {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const location = useLocation();

  const isLoginPage = location.pathname === PATHS.login;

  const returnBackgroundImage = () => {
    if (isMobile) {
      return registerMobileBGIcon;
    }
    if (isLoginPage) {
      return signInBGIcon;
    }
    return registerBGIcon;
  };

  return (
    <Flex sx={CHS.wrapper}>
      <Box
        sx={CHS.imageBlock}
        backgroundImage={returnBackgroundImage()}
        backgroundSize={{
          base: "contain",
          mobile: "cover",
          md: "contain",
          lg: "cover",
          xl: location.pathname === PATHS.register ? "contain" : "cover",
          "2xl": "cover",
        }}
      />
      <Box sx={isMobile ? CHS.rightBlockMobile : CHS.rightBlock}>
        <Box sx={isMobile ? CHS.headerWrapperMobile : CHS.headerWrapperDesktop}>
          <Header isAuthLayout />
        </Box>
        <Box
          sx={CHS.container}
          boxShadow={isMobile ? "0px 4px 40px rgba(0, 0, 0, 0.14)" : ""}
        >
          <Box mb={{ base: 0, md: 2.5, lg: 24 }}>{children}</Box>
          {isMobile && <Box sx={CHS.spacer} />}
        </Box>
      </Box>
    </Flex>
  );
}
