/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import { EditIcon, HandIcon, TrashIcon, SettingsIcon } from "components/Icons";
import { Box, Heading, HStack, Image, Text, Flex } from "@chakra-ui/react";
import { useUtilsContext } from "context/utilsContext/UtilsContext";

import { useCurrentUserContext } from "hooks";
import { GroupModal } from "components/Modals/Group";
import { textSlice } from "utils/textSlice";

import CHS from "./chakra.styles";

export function GroupListItem({ item, deleteHandler, editHandler }) {
  const { currentUser } = useCurrentUserContext();
  const {
    groupListItemIsOpen: isOpen,
    groupListItemOnOpen: onOpen,
    groupListItemOnClose: onClose,
    setGroupId,
    groupId,
  } = useUtilsContext();

  const deleteGroupHandler = () => {
    deleteHandler(item?.id);
  };

  const editGroupHandler = () => {
    editHandler(item?.id);
  };

  return (
    <>
      <Box sx={CHS.itemWrap}>
        <HStack sx={CHS.itemInner} spacing="3">
          <Box sx={CHS.itemImg}>
            {item?.avatarUrl ? (
              <Image
                w="full"
                h="full" // fix cache not update
                src={item?.avatarUrl + `?${new Date().getTime()}`}
                objectFit="cover"
              />
            ) : (
              <HandIcon width="8.5" height="10" fill="brand" />
            )}
          </Box>
          <Box flex="1">
            <Heading sx={CHS.title} variant="cabHeadBox">
              {textSlice({
                text: item?.name,
                count: 13,
                space: true,
              })}
            </Heading>
            <Text>
              {textSlice({
                text: item?.type,
                count: 15,
                space: true,
              })}
            </Text>
          </Box>
          {currentUser?.id === item?.owner?.id ? (
            <Flex justifyContent="space-between" sx={{ marginBottom: "24px" }}>
              <SettingsIcon
                onClick={() => {
                  setGroupId(item.id);
                  onOpen();
                }}
                sx={CHS.eye}
              />
              <EditIcon sx={CHS.redact} onClick={editGroupHandler} />
              <TrashIcon sx={CHS.trash} onClick={deleteGroupHandler} />
            </Flex>
          ) : (
            <Flex justifyContent="flex-end" sx={{ marginBottom: "24px" }}>
              <SettingsIcon
                onClick={() => {
                  setGroupId(item.id);
                  onOpen();
                }}
                sx={CHS.eye2}
              />
            </Flex>
          )}
        </HStack>
      </Box>
      {isOpen && groupId === item?.id && (
        <GroupModal isOpen={isOpen} onClose={onClose} group={item} />
      )}
    </>
  );
}
