export default {
  rowWrapper: {
    "&:after": {
      content: '""',
      display: "inline-flex",
      width: "100%",
      height: "1px",
      mb: "3.75",
      backgroundColor: "black.100",
    },
  },
  rowBody: {
    gap: "3",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  iconBlock: {
    w: "13",
    h: "13",
    backgroundColor: "brand",
    borderRadius: "lg",
    fontSize: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textBlock: {
    flexDirection: "column",
    justifyContent: "space-between",
    flex: "1",
  },
  valueBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "space-between",
  },
  valueBlockInner: {
    textAlign: "end",
  },

  img: {
    height: "inherit",
    borderRadius: "inherit",
    objectFit: "cover",
  },
};
