import { gql } from "@apollo/client";

export const GET_GROUP = gql`
  query getGroup($getGroupId: Int!) {
    getGroup(id: $getGroupId) {
      id
      ownerId
      name
      type
      avatarUrl
      owner {
        id
        fullName
        work
        avatarUrl
      }
      fileUrls {
        qrCodePng
        qrCodePdf
        businessCardPng
        businessCardPdf
        invertedBusinessCardPng
        invertedBusinessCardPdf
      }
      businessCardText
    }
  }
`;
