import { Flex, MenuButton } from "@chakra-ui/react";
import { MenuRightArrowIcon } from "components/Icons";
import CHS from "./chakra.styles";

export function CustomMenuButton({ children }) {
  return (
    <MenuButton role="group" type="button">
      <Flex sx={CHS.customMenuButton}>
        {children}
        <MenuRightArrowIcon sx={CHS.menuIcon} />
      </Flex>
    </MenuButton>
  );
}
