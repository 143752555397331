import { Flex, MenuItem } from "@chakra-ui/react";
import { MenuLeftArrowIcon } from "components/Icons";
import CHS from "./chakra.styles";

export function CustomMenuItem({ onMenuItemClick, children }) {
  return (
    <MenuItem>
      <Flex onClick={onMenuItemClick} sx={CHS.customItem} role="group">
        <MenuLeftArrowIcon sx={CHS.menuIcon} />
        {children}
      </Flex>
    </MenuItem>
  );
}
