export default {
  titleBlock: {
    marginBottom: {
      base: 5,
      md: 1.25,
      lg: 5,
    },
    maxW: {
      base: "full",
      md: 120,
    },
  },
  titleText: {
    fontFamily: "mediumAvenir",
    fontWeight: "bold",
    color: "black.900",
    lineHeight: "130%",
  },
  subTitleBlock: {
    marginRight: 1.5,
  },
};
