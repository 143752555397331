import { Icon } from "@chakra-ui/react";

export function ArrowRightIcon() {
  return (
    <Icon viewBox="0 0 18 14" width="4.5" height="3.5" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4006 0L10.2375 1.175L14.8406 5.83333L0.0166016 5.83333L0.0166016 7.5L14.8406 7.5L10.2375 12.1583L11.4006 13.3333L18.0001 6.66667L11.4006 0Z"
        fill="#333333"
      />
    </Icon>
  );
}
