import { Box, Flex, IconButton, Slide } from "@chakra-ui/react";
import { CloseIcon } from "components/Icons/CloseIcon";
import { SidebarMenu } from "components/SidebarMenu";
import { LogOutIcon } from "components/Icons";
import { useTranslation } from "react-i18next";
import { UserInfo } from "components/UserInfo";
import { useAuthContext } from "hooks";
import { getLeftCoordinates } from "utils/getLeftCoordinates";
import React from "react";

import CHS from "./chakra.styles";

export function Sidebar({ isOpen, onClose }) {
  const { t } = useTranslation();
  const { logout } = useAuthContext();

  return (
    <Slide
      direction="top"
      in={isOpen}
      style={{ zIndex: 10, left: getLeftCoordinates(), height: "100%" }}
    >
      <Box sx={CHS.dropMenu}>
        <Flex sx={CHS.introBlock}>
          <UserInfo />
          <Box>
            <IconButton
              onClick={onClose}
              variant="unstyled"
              aria-label="CloseIcon"
              icon={<CloseIcon fill="white.900" />}
            />
          </Box>
        </Flex>
        <SidebarMenu onClose={onClose} />
        <Flex
          sx={CHS.logOutBlock}
          onClick={() => {
            onClose();
            // hangs overflow: hidden somewhere, give it time to work out
            setTimeout(logout, 0);
          }}
        >
          <LogOutIcon width="5" height="5" fill="white" />
          <Box sx={CHS.text}>{t("app.log_out")}</Box>
        </Flex>
        <Box sx={CHS.horizontalLine} />
      </Box>
    </Slide>
  );
}
