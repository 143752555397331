import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { HeaderCab } from "components/HeaderCab";
import { useTranslation } from "react-i18next";
import { ModalTitle } from "components/Modals/ModalTitle";
import { useApplyForWithdrawal } from "graphql/hooks/useMutations";
import { useCurrentUserContext } from "hooks";
import { AlertModal } from "components/AlertModal";
import React from "react";
import { getFontSize } from "utils/getFontSize";
import { PATHS } from "../../../constants/constants";
import CHS from "./chakra.styles";

export function WithdrawModal({
  isOpen,
  onClose,
  balance,
  refetchTransactions,
}) {
  const { t } = useTranslation();
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();

  const navigate = useNavigate();

  const { currentUser, refetch: refetchCurrentUser } = useCurrentUserContext();
  const feeFormat = balance?.fee;
  const additionalFee = balance?.additionalFee;

  const [applyForWithdrawal, { loading }] = useApplyForWithdrawal({
    onCompleted: () => {
      onOpenAlert();
      refetchCurrentUser();
      refetchTransactions();
    },
  });

  const onSubmit = async () => {
    await applyForWithdrawal();
  };

  const getNumber = (num) => {
    return (String(num)?.includes(".") && Number(num).toFixed(2)) || num;
  };
  const balancePay = getNumber(balance?.balance);
  const creditedPay = getNumber(balance?.net);

  const withdrawalAccept = balancePay > 10;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader sx={CHS.modalHead}>
            <HeaderCab />
            <Flex alignItems="center" px={4}>
              <ModalTitle onClose={onClose} title={t("app.withdraw")} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Box mb="10">
              <Flex sx={CHS.wrap}>
                <Flex sx={CHS.balancWrap}>
                  <Box sx={CHS.balanceBox}>
                    <Text>{t("app.on_account")}</Text>
                    <Text
                      sx={CHS.balanceText}
                      fontSize={getFontSize(creditedPay)}
                      color="black.900"
                    >
                      {balancePay || 0} €
                    </Text>
                  </Box>
                  <Box sx={CHS.verticalLine} />
                  <Box sx={CHS.withdrawBox}>
                    <Box>
                      <Text>{t("app.will_be_counted")}</Text>
                      <Text
                        sx={CHS.balanceText}
                        fontSize={getFontSize(creditedPay)}
                        color="brand"
                      >
                        {creditedPay || 0} €
                      </Text>
                    </Box>
                  </Box>
                </Flex>
              </Flex>
              <Text sx={CHS.infoText}>
                {t("app.the_platform_commission_is")} {feeFormat} % +{" "}
                {additionalFee} €
              </Text>
            </Box>
            <VStack spacing="5" align="flex-start">
              <Heading sx={CHS.ibanTitle}>{t("app.bank_account")}</Heading>
              <Box sx={CHS.wrap}>
                <Text size="small" mb={1}>
                  IBAN
                </Text>
                <Text size="medium" variant="standard">
                  {currentUser?.iban || (
                    <Box
                      onClick={() => {
                        navigate(PATHS.settings);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {t("app.please_check_settings_iban")}
                    </Box>
                  )}
                </Text>
              </Box>
              {currentUser?.iban && withdrawalAccept && (
                <Button
                  variant="cabSolid"
                  size="cabBigModal"
                  onClick={onSubmit}
                  disabled={loading || !currentUser?.iban || creditedPay <= 0}
                >
                  {t("app.send")}
                </Button>
              )}
              {!withdrawalAccept && (
                <Text textAlign="center" width="100%" color="red.500">
                  {t("app.minimum_withdrawal_amount")} 10 €
                </Text>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertModal isOpen={isOpenAlert} onClose={onCloseAlert}>
        <Text size="medium" variant="standard">
          {t("app.withdrawal_initiated")}
        </Text>
      </AlertModal>
    </>
  );
}
