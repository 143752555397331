export default {
  variants: {
    h1: {
      fontFamily: "boldAvenir",
    },
    cabHeadPage: {
      fontFamily: "boldInter",
      fontSize: "xl",
      fontWeight: "bold",
      lineHeight: "9",
    },
    cabHeadBox: {
      fontFamily: "mediumInter",
      fontSize: "md",
      fontWeight: "medium",
      lineHeight: "8",
    },
  },
  defaultProps: {
    variant: "h1",
  },
};
