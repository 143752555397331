import { Navigate } from "react-router-dom";
import { useAuthContext, useCheckServiceRoutes } from "hooks";
import { PATHS } from "constants/constants";

function PublicRoute({ children }) {
  const { isAuth } = useAuthContext();
  const isServiceRoute = useCheckServiceRoutes();

  if (isAuth && isServiceRoute) return <Navigate to={PATHS.cab} />;

  return children;
}

export default PublicRoute;
