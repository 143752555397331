import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useTimerHook = () => {
  const [count, setCount] = useState(10);
  const navigate = useNavigate();
  const pathName = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);

    if (!count) {
      navigate(pathName);
    }
    return () => clearTimeout(timer);
  }, [count]);

  return { count };
};
