import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_API_SOCKET_URL,
    connectionParams: () => {
      const token = localStorage.getItem("TOKEN");
      return {
        Authorization: `Bearer ${token}`,
      };
    },
  })
);

export default wsLink;
