import { Icon } from "@chakra-ui/react";

export default function CameraIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.5 6.75H16.9636L15.6709 4.16453C15.6086 4.03995 15.5128 3.93518 15.3943 3.86197C15.2758 3.78876 15.1393 3.74998 15 3.75H9C8.86071 3.74998 8.72417 3.78876 8.60568 3.86197C8.48719 3.93518 8.39143 4.03995 8.32913 4.16453L7.03635 6.75H4.5C3.90346 6.75066 3.33155 6.98792 2.90973 7.40973C2.48792 7.83155 2.25066 8.40346 2.25 9V18C2.25066 18.5965 2.48792 19.1685 2.90973 19.5903C3.33155 20.0121 3.90346 20.2493 4.5 20.25H13.5C13.6989 20.25 13.8897 20.171 14.0303 20.0303C14.171 19.8897 14.25 19.6989 14.25 19.5C14.25 19.3011 14.171 19.1103 14.0303 18.9697C13.8897 18.829 13.6989 18.75 13.5 18.75H4.5C4.30114 18.7498 4.11048 18.6707 3.96987 18.5301C3.82925 18.3895 3.75018 18.1989 3.75 18V9C3.75026 8.80117 3.82936 8.61055 3.96995 8.46995C4.11055 8.32936 4.30117 8.25026 4.5 8.25H7.5C7.53456 8.24564 7.56875 8.23875 7.6023 8.22937C7.65743 8.22453 7.71184 8.21354 7.76453 8.1966C7.80636 8.17795 7.84631 8.15535 7.88385 8.1291C7.9275 8.10592 7.96862 8.07828 8.00655 8.0466C8.0429 8.00899 8.07513 7.9676 8.1027 7.92315C8.12771 7.89586 8.15051 7.86661 8.17087 7.8357L9.46365 5.25H14.5364L15.2864 6.75H10.5C10.3011 6.75 10.1103 6.82902 9.96967 6.96967C9.82902 7.11032 9.75 7.30109 9.75 7.5C9.75 7.69891 9.82902 7.88968 9.96967 8.03033C10.1103 8.17098 10.3011 8.25 10.5 8.25H19.5C19.6988 8.25026 19.8894 8.32936 20.03 8.46995C20.1706 8.61055 20.2497 8.80117 20.25 9V18C20.2498 18.1989 20.1707 18.3895 20.0301 18.5301C19.8895 18.6707 19.6989 18.7498 19.5 18.75H16.5C16.3011 18.75 16.1103 18.829 15.9697 18.9697C15.829 19.1103 15.75 19.3011 15.75 19.5C15.75 19.6989 15.829 19.8897 15.9697 20.0303C16.1103 20.171 16.3011 20.25 16.5 20.25H19.5C20.0965 20.2493 20.6685 20.0121 21.0903 19.5903C21.5121 19.1685 21.7493 18.5965 21.75 18V9C21.7493 8.40346 21.5121 7.83155 21.0903 7.40973C20.6685 6.98792 20.0965 6.75066 19.5 6.75Z"
        strokeWidth="0.2"
      />
      <path
        d="M12 9.75C11.2583 9.75 10.5333 9.96993 9.91661 10.382C9.29993 10.794 8.81928 11.3797 8.53545 12.0649C8.25162 12.7502 8.17736 13.5041 8.32206 14.2316C8.46675 14.959 8.8239 15.6272 9.34835 16.1516C9.87279 16.6761 10.541 17.0332 11.2684 17.1779C11.9958 17.3226 12.7498 17.2484 13.4351 16.9645C14.1203 16.6807 14.7059 16.2001 15.118 15.5834C15.53 14.9667 15.75 14.2417 15.75 13.5C15.7488 12.5058 15.3533 11.5526 14.6503 10.8496C13.9473 10.1466 12.9942 9.75117 12 9.75ZM12 15.75C11.555 15.75 11.12 15.618 10.75 15.3708C10.38 15.1236 10.0916 14.7722 9.92127 14.361C9.75097 13.9499 9.70641 13.4975 9.79323 13.061C9.88005 12.6246 10.0943 12.2237 10.409 11.909C10.7237 11.5943 11.1246 11.38 11.561 11.2932C11.9975 11.2064 12.4499 11.251 12.861 11.4213C13.2722 11.5916 13.6236 11.88 13.8708 12.25C14.118 12.62 14.25 13.055 14.25 13.5C14.2493 14.0965 14.0121 14.6684 13.5903 15.0903C13.1684 15.5121 12.5965 15.7493 12 15.75Z"
        strokeWidth="0.2"
      />
    </Icon>
  );
}
