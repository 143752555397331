import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

export const i18nInit = (() => {
  return i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ["en", "ru", "et", "fi", "lt", "lv", "nb", "sv"],
      fallbackLng: "en",
      detection: {
        order: ["localStorage", "htmlTag"],
      },
      backend: {
        loadPath: "/assets/locales/{{lng}}/translation.json",
      },
      react: { useSuspense: true },
    });
})();
