import React from "react";
import { AlertModal } from "components/AlertModal";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useConfirmNewEmail } from "graphql/hooks/useMutations";
import { confirmEmail as confirmEmailSchema } from "validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { Box, Heading, Button, Text } from "@chakra-ui/react";
import { useCurrentUserContext } from "hooks";

const INITIAL_VALUES_CONFIRM_EMAIL = { confirmationCode: "" };

export function ConfirmEmailAlert({ isOpen, onClose, newEmail }) {
  const { updateUser } = useCurrentUserContext();
  const [confirmEmail] = useConfirmNewEmail();

  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUES_CONFIRM_EMAIL,
    resolver: yupResolver(confirmEmailSchema),
  });

  const onSubmit = (data) => {
    confirmEmail({
      variables: {
        confirmationCode: data.confirmationCode,
      },
      onCompleted: (response) => {
        updateUser(response?.confirmNewEmailOfUser);
        reset(INITIAL_VALUES_CONFIRM_EMAIL);
        onClose();
      },
      onError: ({ graphQLErrors }) => {
        if (graphQLErrors) {
          switch (graphQLErrors?.[0]?.message) {
            case "Wrong email change code":
              setError("confirmationCode", {
                type: "custom",
                message: t("app.wrong_confirmation_code"),
              });
              break;
            default:
              console.log(graphQLErrors?.[0]?.message);
          }
        }
      },
    });
  };

  return (
    <AlertModal isOpen={isOpen} onClose={onClose} isClosed withBtns={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box px={2}>
          <Heading variant="cabHeadBox" lineHeight={8} pb="4">
            {`${t("app.please_check_your_new_email")}: `}
            <Text size="linkText" variant="brand">
              {newEmail}
            </Text>
            {` ${t("app.and_type_confirm_code")}!`}
          </Heading>
          <TextFieldRHF
            mb={4}
            variant="flushed"
            register={register}
            name="confirmationCode"
            label={t("app.confirm_code")}
            placeholder={t("app.type_code_here")}
            autoComplete="off"
            hasError={errors?.confirmationCode}
            errorMessage={errors?.confirmationCode?.message}
          />
        </Box>
        <Button type="submit" variant="cabSolid" size="cabAlert" width="full">
          {t("app.confirm_new_email")}
        </Button>
      </form>
    </AlertModal>
  );
}
