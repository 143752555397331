import { gql } from "@apollo/client";

export const GET_LIMITED_USER_FOR_PAYMENT = gql`
  query getLimitedDataOfUser($id: Int!) {
    getLimitedDataOfUser(id: $id) {
      id
      avatarUrl
      fullName
    }
  }
`;
