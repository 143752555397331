import { gql } from "@apollo/client";

export const GET_INNER_TRANSACTIONS = gql`
  query getInnerTransactions($input: GettingInnerTransactionsInput!) {
    getInnerTransactions(input: $input) {
      innerTransactions {
        id
        userId
        amount
        percentage
        createdAt
        moderationStatus
        type
      }
      count
    }
  }
`;
