export default {
  variants: {
    base: {
      mb: "2.5",
      fontSize: "md",
      color: "black.900",
    },
    flushed: {
      fontSize: "2xs",
      mb: "-2",
      color: "grey.500",
    },
  },
  defaultProps: {
    variant: "base",
  },
};
