import React from "react";
import Button from "components/Button/Button";
import { Center, Flex } from "@chakra-ui/react";
import CHS from "components/AlertModal/chakra.styles";
import { useTranslation } from "react-i18next";

export function AlertButtons({
  isMoreButtons,
  onClose,
  funcLeftBtn,
  funcRightBtn,
  nameLeftBtn = "no",
  nameRightBtn = "yes",
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <Center w="full">
      {!isMoreButtons ? (
        <Button
          variant="cabSolid"
          size="cabAlert"
          maxWidth="37"
          onClick={onClose}
        >
          {t("app.close")}
        </Button>
      ) : (
        <Flex sx={CHS.btnWrap}>
          <Button
            variant="alertBtnOutlined"
            size="cabAlert"
            disabled={disabled}
            onClick={funcLeftBtn}
          >
            {t(`app.${nameLeftBtn}`)}
          </Button>
          <Button
            variant="cabSolid"
            size="cabAlert"
            onClick={funcRightBtn}
            disabled={disabled}
          >
            {t(`app.${nameRightBtn}`)}
          </Button>
        </Flex>
      )}
    </Center>
  );
}
