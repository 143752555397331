export default {
  variants: {
    options: {
      list: {
        minW: "max",
        w: "194px",
        h: "auto",
        py: "2.5",
        px: "5",
        bgColor: "white.900",
        borderWidth: "0",
        borderRadius: "base",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
        zIndex: "10",
      },
      item: {
        display: "flex",
        alignItems: "center",
        w: "100%",
        py: "2.5",
        px: "0",
        color: "black.900",
        bgColor: "transparent",
        _hover: {
          bg: "transparent",
          cursor: "pointer",
          color: "brand",
        },
        _focus: {
          bg: "transparent",
        },
      },

      button: {
        w: "12.5",
        h: "10",
        px: "2.5",
        bgColor: "transparent",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "grey.300",
        borderRadius: "lg",
      },
    },
    subMenu: {
      list: {
        minW: "max",
        w: "194px",
        h: "auto",
        py: "2.5",
        px: "5",
        bgColor: "white.900",
        borderWidth: "0",
        borderRadius: "base",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
      },
      item: {
        display: "flex",
        alignItems: "center",
        w: "100%",
        py: "2.5",
        px: "0",
        fontSize: "sm",
        fontWeight: "normal",
        lineHeight: "7",
        color: "black.900",
        bgColor: "transparent",
        _hover: {
          bg: "transparent",
          cursor: "pointer",
          color: "brand",
        },
        _focus: {
          bg: "transparent",
        },
      },
      button: {
        display: "block",
        w: "full",
        py: "2.5",
        px: "0",
        color: "black.900",
        bgColor: "transparent",

        _hover: {
          color: "brand",
        },
      },
    },
  },
};
