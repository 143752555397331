export default {
  advantageItem: {
    width: {
      base: "100%",
      md: "47%",
      lg: "33.3333%",
    },
    maxWidth: "375px",
  },

  advantageItemTitle: {
    fontFamily: "normalAvenir",
    fontSize: "md",
    color: "black.900",
  },

  advantageItemText: {
    textAlign: "center",
  },
};
