import { Icon } from "@chakra-ui/react";

export default function ErrorIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 96 96" fill="none" {...props}>
      <rect width="96" height="96" rx="48" fill="#BA1B1B" />
      <path
        d="M62 36.82L59.18 34L48 45.18L36.82 34L34 36.82L45.18 48L34 59.18L36.82 62L48 50.82L59.18 62L62 59.18L50.82 48L62 36.82Z"
        fill="white"
      />
    </Icon>
  );
}
