import { gql } from "@apollo/client";

export const GET_ACTIVITY_LOGS = gql`
  query GetActivityLogs($limit: Int!, $offset: Int!) {
    getActivityLogs(limit: $limit, offset: $offset) {
      count
      rows {
        action
        createdAt
        id
        info
        status
        ip
        user {
          id
          phoneNumber
          work
          iban
          fullName
          email
        }
        userId
      }
    }
  }
`;
