import { HomeMainMenuIcon, QrCodeIcon, SettingsIcon } from "components/Icons";

export const PATHS = {
  solo: "/site/solo",
  team: "/site/team",
  register: "/site/register",
  login: "/site/login",
  forgotPassword: "/site/forgot-password",
  resetPassword: "/site/reset-password/:token",
  confirmEmail: "/site/confirm-email/:token",
  cab: "/cab",
  settings: "/cab/settings",
  qrcodes: "/cab/qrcodes",
  admin: "/cab/admin",
  payment: "/payment",
  paymentCard: "/payment-card",
  paymentSuccess: "/success",
  paymentCanceled: "/canceled",
  privacyPolicy: "/site/privacy_policy",
  cookiePolicy: "/site/cookie_policy",
  termsConditions: "/site/terms_conditions",
  legalNotesContact: "/site/legal_notes_contact",
};

export const ACCESS = {
  private: "private",
  public: "public",
};

// Это общедоступные пути, которые должны быть доступны только если пользователь не авторизован
export const SERVICE_ROUTES = [
  PATHS.login,
  PATHS.register,
  PATHS.forgotPassword,
  PATHS.resetPassword,
];

export const DEFAULT_TOKEN_LIFETIME = 720; // 30 days

export const ERRORS_MESSAGES = {
  VALID_EMAIL: "Enter valid email address",
  REQUIRE_MESSAGE: "Please fill out this field",
  PASSWORD_CONTAIN_MESSAGE:
    "The password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and symbol (@$!%*?&;№”._).",
  PASSWORD_DOES_NOT_MATCH: "Password does not match",
  CORRECT_PHONE: "Please enter valid phone",
  PLEASE_E_GN: "Please enter group name",
  PLEASE_E_GT: "Please enter group type",
  REQUIRE_MESSAGE_ROLE_BTN: "Please choose a role",
  VALID_LANG: "Only English letters are allowed",
  VALID_FULL_NAME: "Please enter valid full name",
  MIN_2_CHARACTERS: "Min 2 characters",
  MAX_40_CHARACTERS: "Max 40 characters",
  MAX_64_CHARACTERS: "Max 64 characters",
  THIS_ENTITY_ALREADY_EXIST: "This entity already exist",
  CORRECT_DATE_CARD: "Enter correct date",
  CORRECT_CARD_NUMBER: "Must be exactly 16 characters",
  CORRECT_CARD_CVV: "Enter correct cvv",
  MUST_BE_6_CHARACTERS_LONG: "Must be 6 characters long",
  CORRECT_IBAN: "Enter correct IBAN",
  MUST_MIN_4_CHARACTERS_LONG: "The minimum string length must be 4 characters",
  MUST_MAX_220_CHARACTERS_LONG:
    "The maximum string length must be 200 characters",
  MIN_3: "Min 3",
};

export const DEFAULT_DROPZONE_OPTIONS = {
  maxSize: 15000000,
  multiple: false,
  accept: {
    "image/jpeg": [],
    "image/png": [],
  },
};

export const sidebarLinks = [
  {
    path: PATHS.cab,
    icon: <HomeMainMenuIcon width="5.5" height="5.5" fill="white.900" />,
  },
  {
    path: PATHS.settings,
    icon: <SettingsIcon width="5.5" height="5.5" fill="white.900" />,
  },
  {
    path: PATHS.qrcodes,
    icon: <QrCodeIcon width="6" height="6" fill="white.900" />,
  },
];

export const dateMask = Array(16).fill("•");

export const PAYMENT_SYSTEM_TYPES = {
  APPLE_PAY: "Apple_Pay",
  GOOGLE_PAY: "Google_Pay",
  VISA: "Visa",
  MASTERCARD: "Mastercard",
};

export const NOTIFICATIONS_TYPES = {
  TRANSACTION: "Transaction",
  WITHDRAW: "Withdraw",
  ADDED_TO_GROUP: "Added you to group",
};

export const CARD_TYPE = {
  CARD: "card",
  QR: "qr",
};

export const COUNTER_PER_PAGE = 4;
export const COUNTER_PER_ACTIVITY_LOGS = 10;
export const COUNTER_PER_USERS_LOGS = 10;

export const PASSWORD_VALIDATION =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&;№.”._])[A-Za-z\d@$!%*?&;№.”._]{8,}$/;

export const IPIFY_URL = "https://api.ipify.org/?format=json";
