import { Icon } from "@chakra-ui/react";

export default function BellMainMenu({ ...props }) {
  return (
    <Icon viewBox="0 0 24 26" fill="none" {...props}>
      <path
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.136 8.20128C19.136 6.29138 18.3773 4.45971 17.0268 3.1092C15.6763 1.7587 13.8446 1 11.9347 1C10.0248 1 8.19316 1.7587 6.84266 3.1092C5.49215 4.45971 4.73345 6.29138 4.73345 8.20128C4.73345 16.6028 1.13281 19.0032 1.13281 19.0032H22.7366C22.7366 19.0032 19.136 16.6028 19.136 8.20128Z"
      />
      <path
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.0105 23.804C13.7995 24.1677 13.4966 24.4697 13.1322 24.6795C12.7678 24.8894 12.3547 24.9999 11.9342 24.9999C11.5136 24.9999 11.1005 24.8894 10.7361 24.6795C10.3717 24.4697 10.0688 24.1677 9.85779 23.804"
      />
    </Icon>
  );
}
