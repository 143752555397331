export default {
  stepItem: {
    w: "full",
    pb: "5",
    borderStyle: "dashed",
    borderColor: "black.500",
    borderBottomWidth: "1px",
    alignItems: {
      base: "center",
      md: "flex-start",
    },
  },
  stepItemIndicator: {
    color: "white.900",
    width: "7.5",
    h: "7.5",
    borderRadius: "50%",
  },

  stepItemTitle: {
    fontFamily: "normalAvenir",
    fontSize: "md",
    color: "black.900",
  },

  stepItemText: {
    w: "full",
    textAlign: {
      base: "center",
      md: "left",
    },
  },
};
