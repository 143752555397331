export default {
  none: "0",
  "3xs": "0.063", // 1px
  "2xs": "0.125rem", // 2px
  xs: "0.25rem", // 4px
  sm: "0.375rem", // 6px
  base: "0.5rem", // 8px
  md: "0.625rem", // 10px
  lg: "0.75rem", // 12px
  xl: "0.875rem", // 14px
  "2xl": "1rem", // 16px
  "3xl": "1.125rem", // 18px
  "4xl": "1.313rem", // 21px
  "5xl": "1.375rem", // 22px
  "6xl": "1.5rem", // 24px
  "7xl": "1.625rem", // 26px
  "8xl": "1.75rem", // 28px
  "9xl": "1.875rem", // 30px
  "10xl": "2rem", // 32px
  "11xl": "2.25rem", // 36px
  "12xl": "3.125rem", // 50px

  full: "9999px",
};
