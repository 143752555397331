export default {
  header: {
    alignItems: "center",
  },

  closeIcon: {
    mr: "3.5",
    cursor: "pointer",
  },

  sectionWrap: {
    flexDir: "column",
    mb: "5",
  },

  list: {
    bgColor: "white.900",
    borderRadius: "lg",

    "div:last-child": {
      _after: {
        display: "none",
      },
    },
  },
};
