export const getDateConvertToNotifyRow = (myDate) => {
  if (!myDate) return "";
  const option = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };
  const newDate = new Date(+myDate).toLocaleString("ru", option);
  const today = new Date().toLocaleString("ru", option);
  const yesterday = new Date(Date.now() - 86400000).toLocaleString(
    "ua",
    option
  );

  if (newDate === today) {
    return "Today";
  }
  if (newDate === yesterday) {
    return "Yesterday";
  }
  return newDate;
};
