import { Badge, Box, IconButton } from "@chakra-ui/react";
import { BellMainMenuIcon } from "components/Icons";
import CHS from "./chakra.styles";

export function NotificationButton({ onToggle, myRef, watchedNotifications }) {
  const isHaveNotifications = watchedNotifications?.length > 0;

  return (
    <Box position="relative">
      <IconButton
        ref={myRef}
        onClick={onToggle}
        colorScheme="brand"
        aria-label="BellMainMenuIcon"
        icon={<BellMainMenuIcon width="6" height="6.5" stroke="white.900" />}
      />
      {isHaveNotifications && (
        <Badge sx={CHS.badgeStyle}>{watchedNotifications?.length}</Badge>
      )}
    </Box>
  );
}
