export default {
  sizes: {
    primary: {
      fontFamily: "normalInter",
      fontWeight: "normal",
      fontSize: "sm",
      lineHeight: "7",
    },
    notify2xs: {
      fontFamily: "normalInter",
      fontWeight: "normal",
      fontSize: "2xs",
      lineHeight: "5",
    },
    notifyXs: {
      fontFamily: "normalInter",
      fontWeight: "medium",
      fontSize: "xs",
      lineHeight: "5",
    },
    notifyXsInterMedium: {
      fontFamily: "mediumInter",
      fontWeight: "medium",
      fontSize: "xs",
      lineHeight: "5",
    },
    small: {
      fontFamily: "normalInter",
      fontWeight: "normal",
      fontSize: "2xs",
      lineHeight: "5",
    },
    smallMedium: {
      fontFamily: "normalInter",
      fontWeight: "bold",
      fontSize: "sm",
      lineHeight: "7",
    },
    large: {
      fontFamily: "normalInter",
      fontWeight: "normal",
      fontSize: "lg",
      lineHeight: "10",
    },
    big: {
      fontFamily: "normalInter",
      fontWeight: "bold",
      fontSize: "1.75xl",
      lineHeight: "10",
    },
    medium: {
      fontFamily: "normalInter",
      fontWeight: "medium",
      fontSize: "md",
      lineHeight: "8",
    },
    answerTitle: {
      mt: "10",
      mb: "4",
      fontFamily: "mediumInter",
      fontSize: "xl",
    },
    linkText: {
      display: "inline-block",
      fontFamily: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      textDecoration: "underline",
      wordBreak: "break-word",
    },
    exLarge: {
      fontFamily: "normalInter",
      fontSize: "3xl",
      fontWeight: "bold",
      lineHeight: "15",
    },
    pay: {
      fontFamily: "normalInter",
      fontSize: "sm",
      lineHeight: "7",
    },
  },
  variants: {
    primary: {
      color: "grey.500",
    },
    brand: {
      color: "brand",
    },
    standard: {
      color: "black.900",
    },
    white: {
      color: "white.900",
    },
    success: {
      color: "green",
    },
    error: {
      color: "red.500",
    },
    grey: {
      color: "grey.400",
    },
  },
  defaultProps: {
    size: "primary",
    variant: "primary",
  },
};
