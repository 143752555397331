import { useContext } from "react";
import NotificationsContext from "context/notifications/notifications.context";

export const useNotificationsContext = () => {
  const notificationsContext = useContext(NotificationsContext);

  if (!notificationsContext) {
    throw new Error(
      "notificationsContext  must be used within a NotificationsProvider"
    );
  }

  return notificationsContext;
};
