import { MenuButton, Menu, MenuList, useTheme } from "@chakra-ui/react";
import { ThreeDotsIcon } from "components/Icons";
import { useCallback, useState } from "react";

export function MenuOptions({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const menuToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <Menu variant="options" onOpen={menuToggle} onClose={menuToggle}>
      <MenuButton type="button">
        <ThreeDotsIcon
          w="7.5"
          h="1.5"
          fill={isOpen ? theme.colors.brand : theme.colors.grey["400"]}
        />
      </MenuButton>
      <MenuList>{children}</MenuList>
    </Menu>
  );
}
