import { onError } from "@apollo/client/link/error";
import create from "zustand";

export const useErrorsStore = create(() => ({
  hasError: false,
  error: undefined,
  date: undefined,
}));

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const graphQLError of graphQLErrors) {
      console.log("graphQLError: ", graphQLError);

      if (graphQLError.message === "Unauthorized") {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("TOKEN_EXPIRY");
      }

      useErrorsStore.setState({
        hasError: true,
        error: graphQLError,
        date: Date.now(),
      });
    }
  } else if (networkError) {
    console.log("networkError: ", networkError);

    useErrorsStore.setState({
      hasError: true,
      error: networkError,
      date: Date.now(),
    });
  }
});

export default errorLink;
