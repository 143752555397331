import { Icon } from "@chakra-ui/react";

export function BurgerMenuIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 20 14" {...props}>
      <rect width="20" height="2" rx="1" />
      <rect y="6" width="20" height="2" rx="1" />
      <rect y="12" width="20" height="2" rx="1" />
    </Icon>
  );
}
