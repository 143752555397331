export default {
  variants: {
    brandSolid: {
      borderRadius: "xs",
      fontWeight: "medium",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },

    brandSolidRounded: {
      borderRadius: "11xl",
      fontWeight: "medium",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },

    cabOutline: {
      background: "transparent",
      fontWeight: "medium",
      color: "brand",
      borderWidth: "1px",
      borderColor: "brand",
      borderRadius: "base",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },
    editBtn: {
      background: "transparent",
      fontWeight: "medium",
      color: "brand",
      borderWidth: "1px",
      borderColor: "brand",
      borderRadius: "base",
      span: {
        margin: 0,
      },
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },
    cabSolid: {
      borderRadius: "base",
      fontWeight: "medium",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },

    cabBtn: {
      fontSize: "sm",
      lineHeight: "7",
      fontWeight: "medium",
      borderRadius: "base",
      bgColor: "rgba(44, 55, 178, 0.1)",
      color: "brand",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },

    alertBtnOutlined: {
      borderRadius: "base",
      fontWeight: "medium",
      backgroundColor: "white.900",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "brand",
      color: "brand",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },

    cabDownBtn: {
      fontSize: "sm",
      lineHeight: "7",
      fontWeight: "medium",
      w: "full",
      _hover: {
        _disabled: {
          bgColor: "red",
        },
      },
    },

    roleBtn: {
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
      px: "20px",
      py: "10px",
      borderRadius: "lg",
      backgroundColor: "white.900",
      color: "black.900",
      fontWeight: "medium",
      fontSize: "xs",
      lineHeight: "6",
    },

    paymentSm: {
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
      borderRadius: "xs",
      backgroundColor: "white.900",
      color: "black.900",
      fontWeight: "medium",
      fontSize: "sm",
      lineHeight: "7",
    },
  },
  sizes: {
    cabinetModal: {
      h: "12",
      w: "150px",
    },
    cabBigModal: {
      h: "12",
      w: "100%",
      maxWidth: "100%",
    },
    registerButton: {
      w: "full",
      h: 16.5,
    },
    cabIconBtn: {
      w: "8.5",
      h: "8.5",
    },
    cabAlert: {
      width: "150px",
      height: "12",
    },
    paymentSize: {
      width: "12.5",
      height: "12.5",
    },
  },

  defaultProps: {
    variant: "brandSolid",
  },
};
