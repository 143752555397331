import { Box, Center, Flex, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "components/Icons/CloseIcon";
import { AlertButtons } from "components/AlertButtons";
import CHS from "./chakra.styles";

export function AlertModal({
  children,
  onClose,
  isOpen,
  isClosed = false,
  isMoreButtons = false,
  withBtns = true,
  ...rest
}) {
  return (
    <Flex sx={CHS.alertWrapper} display={isOpen ? "flex" : "none"}>
      <Box sx={CHS.alertModal}>
        <Box sx={CHS.body}>
          {isClosed && (
            <IconButton
              onClick={onClose}
              sx={CHS.iconBtn}
              variant="unstyled"
              aria-label="CloseIcon"
              icon={<CloseIcon fill="black.900" w={3} h={3} />}
            />
          )}
          <Flex sx={CHS.content}>{children}</Flex>
          {withBtns && (
            <Center>
              <AlertButtons
                isMoreButtons={isMoreButtons}
                onClose={onClose}
                {...rest}
              />
            </Center>
          )}
        </Box>
      </Box>
    </Flex>
  );
}
