import React, { useEffect, useState } from "react";
import { Box, Button, Portal, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { AuthTitleBlock } from "components/AuthTitleBlock/AuthTitleBlock";
import { registerSchema } from "validation";
import { transformSignUp } from "connectors";
import { useSignUp } from "graphql/hooks/useMutations";
import { PhoneInputCustom } from "components/PhoneInputCustom/PhoneInputCustom";
import { AlertModal } from "components/AlertModal";
import { errConvert } from "utils/errConvert";
import { useGetIpHook, useTrimStringStartForm } from "../../hooks";

const INITIAL_VALUES_REGISTER = {
  fullName: "",
  phone: "+",
  email: "",
  password: "",
  confirmPassword: "",
};

export default function Register() {
  const [formSending, setFormSending] = useState(false);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [signUp] = useSignUp();
  const { ip } = useGetIpHook();

  const [error, setErrorServer] = useState();

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUES_REGISTER,
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = (data) => {
    setFormSending(true);
    signUp({
      variables: {
        input: transformSignUp(data),
      },
      context: {
        headers: {
          "user-ip": ip || "",
        },
      },
      onCompleted: (response) => {
        if (!response?.signup?.result) {
          setErrorServer(response?.signup?.message);
        } else {
          onOpen();
          setErrorServer();
        }
        setFormSending(false);
      },
      onError: ({ graphQLErrors }) => {
        setFormSending(false);
        errConvert(graphQLErrors, t, setError);
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useTrimStringStartForm({ stringName: "fullName", watch, setValue });

  return (
    <Box>
      <AuthTitleBlock
        title={t("app.connect_digital_tips_for_your_employees")}
        titleFontSize={{ base: "3xl", md: "4xl" }}
        subTitle={t(
          "app.receive_send_and_manage_tips_directly_touchless_and_cashless"
        )}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldRHF
          register={register}
          name="fullName"
          label={t("app.full_name")}
          hasError={errors?.fullName}
          errorMessage={errors?.fullName?.message}
          placeholder={t("app.enter_full_name")}
          mb={5}
        />
        <PhoneInputCustom
          name="phone"
          label={t("app.phone")}
          placeholder={t("app.enter_phone")}
          setValue={setValue}
          hasError={errors?.phone}
          errorMessage={errors?.phone?.message}
          trigger={trigger}
          mb={5}
        />
        <TextFieldRHF
          mb={5}
          register={register}
          name="email"
          label={t("app.e_mail")}
          placeholder={t("app.enter_your_email")}
          hasError={errors?.email}
          errorMessage={errors?.email?.message}
        />
        <TextFieldRHF
          mb={5}
          register={register}
          name="password"
          label={t("app.password")}
          placeholder={t("app.enter_your_password")}
          hasError={errors?.password}
          errorMessage={errors?.password?.message}
          type="password"
        />
        <TextFieldRHF
          mb={{ base: 5, md: 10 }}
          register={register}
          name="confirmPassword"
          label={t("app.confirm_password")}
          placeholder={t("app.repeat_your_password")}
          hasError={errors?.confirmPassword}
          errorMessage={errors?.confirmPassword?.message}
          type="password"
        />
        {error && (
          <Text sx={{ color: "red" }} textAlign="center">
            {error}
          </Text>
        )}
        <Button size="registerButton" type="submit" disabled={formSending}>
          {t("app.sign_up_now")}
        </Button>
        <Portal>
          <AlertModal isOpen={isOpen} onClose={onClose}>
            <Text size="medium" variant="standard">
              {t("app.please_check_your_email")}
            </Text>
          </AlertModal>
        </Portal>
      </form>
    </Box>
  );
}
