export default {
  brand: "#2C37B2",
  purple: {
    300: "#D9A4FC",
  },
  grey: {
    200: "#F8FBFF",
    250: "#F0F3F8",
    300: "#E5E5E5",
    350: "#F1F3F9",
    375: "#F3F3F3",
    400: "#C4C4C4",
    425: "#ACACAC",
    450: "#A6ACBE",
    500: "#6B768B",
    550: "#6B768B33",
    600: "#4F4F4F",
    700: "#72727280",
    800: "#333333",
  },
  dark: {
    100: "#C9CED6",
  },
  yellow: {
    100: "#FCBE0019",
    400: "#FCBE00",
    500: "#FFC727",
  },
  pink: {
    100: "#FDE1E4",
  },
  black: {
    100: "#0000001A",
    500: "#0000007F",
    900: "#000000",
  },
  white: {
    200: "#00000019",
    300: "#F3F9FF",
    900: "#FFFFFF",
  },
  blue: {
    100: "#F8FBFF",
  },
  red: {
    500: "#ff0000",
  },
};
