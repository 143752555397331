import React from "react";
import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function BusinessCardModal({
  isOpen,
  onClose,
  url,
  isHorizontal = false,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      variant="transparent"
      size="transparent"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("app.business_card")}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Image
            src={
              !isHorizontal
                ? `${url?.invertedBusinessCardPng}?i=${Math.random()}`
                : `${url?.businessCardPng}?i=${Math.random()}`
            }
            alt={url?.businessCardPng}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

// TODO верстка визитных карточек (горизонтальных и вертикальных)
// function PaysLogo({ isHorizontal = false }) {
//   return (
//     <Flex sx={CHS.bottomLogoWrap} gap={isHorizontal ? 10 : 15}>
//       <Box>
//         <Image height={isHorizontal ? 8 : 10} src={appleIcon} alt="appleIcon" />
//       </Box>
//       <Box>
//         <Image
//           height={isHorizontal ? 6 : 8}
//           src={googleIcon}
//           alt="googleIcon"
//         />
//       </Box>
//     </Flex>
//   );
// }

// <>
//             <Flex sx={isHorizontal ? CHS.logoWrapper : CHS.logoWrapperVertical}>
//               <LogoVisitIcon
//                 w={isHorizontal ? "36" : "66"}
//                 h={isHorizontal ? "15" : "27"}
//               />
//             </Flex>
//             <Flex sx={isHorizontal ? CHS.downSection : CHS.downSectionVertical}>
//               <Flex sx={CHS.visitCard}>
//                 <Box maxW={72}>
//                   <Box sx={isHorizontal ? CHS.isHorizontalPayLogo : ""}>
//                     <Text
//                       variant="white"
//                       textAlign="center"
//                       lineHeight={14}
//                       size={isHorizontal ? "small" : "big"}
//                     >
//                       ЧАЕВЫЕ КАРТОЙ БЕЗ КОМИССИИ
//                     </Text>
//                     {isHorizontal && <PaysLogo isHorizontal={isHorizontal} />}
//                   </Box>
//                 </Box>
//               </Flex>
//               <Flex sx={CHS.qrCodeWrapper} flexDirection="column">
//                 <Box position="relative">
//                   <Image
//                     src={url}
//                     w={isHorizontal ? 118 : 212}
//                     h={isHorizontal ? "" : 212}
//                   />
//                   <LogoIcon
//                     w={isHorizontal ? "13.5" : "24"}
//                     h={isHorizontal ? "6" : "10.5"}
//                     backgroundColor="white.900"
//                     sx={CHS.logoQr}
//                   />
//                 </Box>
//                 {!isHorizontal && <PaysLogo />}
//               </Flex>
//             </Flex>
//           </>
