import sectionIntroSoloBGMob from "assets/img/solo/intro/item-1-mob.png";
import sectionIntoSoloBGDesk from "assets/img/solo/intro/item-2-desk.png";

import sectionHowWorkSoloBGMob from "assets/img/solo/how-works/item-1-mob.png";
import sectionHowWorkSoloBGDesk from "assets/img/solo/how-works/item-2-desk.png";

import sectionQuestionsSoloBGDesk from "assets/img/solo/questions/item-desk.png";

import sectionBannerSoloBGDesk from "assets/img/solo/banner/item-desk.png";
import sectionBannerSolo from "assets/img/solo/banner/item-1.png";

import iconCirclePink from "assets/img/icon-circle-pink.png";
import iconRectGreen from "assets/img/icon-rect-green.png";

export default {
  // Intro
  sectionIntoSoloWrap: {
    pt: {
      base: "5",
    },
    pb: {
      base: "266px",
      md: "350px",
      lg: "480px",
    },
    bgImage: {
      base: sectionIntroSoloBGMob,
      lg: sectionIntoSoloBGDesk,
    },
    bgRepeat: "no-repeat",
    bgPosition: "center bottom",
    bgSize: {
      md: "cover",
    },
  },

  // Advantages
  sectionAdvantagesSolo: {
    mt: {
      base: "12.5",
      lg: "150px",
    },
  },

  advatagesListSolo: {
    width: "100%",
    pt: {
      base: "5",
      md: "10",
    },
    flexDirection: {
      base: "column",
      md: "row",
    },
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "15",
    rowGap: {
      base: "10",
      md: "12.5",
    },

    div: {
      width: {
        base: "100%",
        md: "47%",
        lg: "25%",
      },
      maxWidth: {
        base: "330px",
        lg: "255px",
      },
    },
  },

  // How does it work
  sectionHowWorksWrapSolo: {
    mt: {
      base: "25",
      lg: "170px",
    },
    pb: {
      base: "665px",
      md: "80px",
    },
    pt: {
      md: "40px",
      lg: "0",
    },
    bgImage: {
      base: sectionHowWorkSoloBGMob,
      lg: sectionHowWorkSoloBGDesk,
    },
    bgRepeat: "no-repeat",
    bgSize: {
      base: "auto",
    },
    bgPosition: {
      base: "center bottom",
      md: "calc(50% - 200px) center",
      lg: "calc(50% - 300px) center",
    },
  },

  sectionHowWorksSolo: {
    justifyContent: {
      base: "center",
      md: "flex-end",
    },
  },

  sectionHowWorksSoloInner: {
    maxWidth: {
      md: "390px",
      lg: "490px",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
  },

  sectionHowWorksSoloList: {
    "div:last-child": {
      borderBottomWidth: "0",
    },
  },

  // Questions
  sectionQuestionsSolo: {
    mt: {
      base: "12.5",
      lg: "140px",
    },
    bgImage: {
      base: iconCirclePink,
      md: sectionQuestionsSoloBGDesk,
    },
    bgRepeat: "no-repeat",
    bgSize: {
      base: "120px 120px",
      md: "auto",
    },
    bgPosition: {
      base: "calc(100% + 106px ) bottom",
      md: "center 220px",
    },
  },

  sectionQuestionsInner: {
    alignItems: "flex-start",

    h2: {
      textAlign: "left",
    },
  },

  // Banner
  sectionBannerSoloWrap: {
    pt: {
      base: "25",
      md: "20",
      lg: "150px",
    },
    pb: {
      base: "10",
      lg: "15",
    },
    bgImage: {
      base: iconRectGreen,
      md: sectionBannerSoloBGDesk,
    },
    bgRepeat: "no-repeat",
    bgSize: {
      base: "22px 22px",
      md: "auto",
    },
    bgPosition: {
      base: "calc(100% - 60px) 16px",
      md: "center top",
    },
  },

  sectionBannerSolo: {
    justifyContent: {
      md: "flex-start",
    },
    borderRadius: "lg",
    backgroundColor: "yellow.500",
    px: {
      base: "2.5",
      lg: "14",
    },
    pt: {
      base: "2.5",
      md: "16",
    },
    pb: {
      base: "220px",
      md: "11",
    },
    bgImage: sectionBannerSolo,
    bgRepeat: "no-repeat",
    bgPosition: {
      base: "center bottom",
      md: "right bottom",
    },
    bgSize: {
      base: "288px auto",
      lg: "auto",
    },
  },

  sectionBannerSoloInner: {
    maxWidth: {
      md: "calc(100% - 210px)",
      lg: "3xl",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
    button: {
      width: {
        base: "full",
        md: "42",
      },
    },
  },
};
