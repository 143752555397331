export default {
  baseStyle: {
    field: {
      w: "full",
      minWidth: 0,
      outline: 0,
      fontFamily: "normalInter",
      appearance: "none",
      transitionProperty: "common",
      transitionDuration: "normal",
      fontWeight: "medium",
      fontSize: "md",
      lineHeight: 8,
      color: "black.900",
    },
  },

  variants: {
    base: {
      field: {
        h: 15,
        py: 4,
        px: 5,
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "xs",
        backgroundColor: "grey.350",
        _focus: {
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: "brand",
        },
      },
    },

    flushed: {
      field: {
        h: 15,
        borderBottomStyle: "solid",
        borderBottomWidth: 2,
        borderBottomColor: "grey.550",
        _focus: {
          borderColor: "transparent",
          borderBottomColor: "brand",
          boxShadow: "none",
        },
      },
    },

    percentage: {
      field: {
        backgroundColor: "transparent",
        textAlign: "center",
        w: "14",
        h: "10",
        pl: "0",
        pr: "3.75",
        fontSize: "xs",
        borderRadius: "lg",
        borderWidth: "1px",
        borderStyle: "solid",
        _focus: {
          borderWidth: "2px",
        },
      },
    },
  },
};
