export const getFontSize = (str = "") => {
  if (str.length > 10) return "12px";
  switch (str.length) {
    case 6:
      return "30px";
    case 7:
      return "28px";
    case 8:
      return "24px";
    case 9:
      return "22px";
    case 10:
      return "20px";
    default:
      return "32px";
  }
};
