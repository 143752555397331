import { useMutation } from "@apollo/client";
import * as MUTATIONS from "graphql/mutations";

export const useSignUp = (options) => {
  return useMutation(MUTATIONS.SIGN_UP, options);
};

export const useForgotPassword = (options) => {
  return useMutation(MUTATIONS.FORGOT_PASSWORD, options);
};

export const useRecoverPassword = (options) => {
  return useMutation(MUTATIONS.RECOVER_PASSWORD, options);
};

export const useEditUserProfile = (options) => {
  return useMutation(MUTATIONS.EDIT_USER_PROFILE, options);
};

export const useCreateGroup = (options) => {
  return useMutation(MUTATIONS.CREATE_GROUP, options);
};

export const useInviteWorkerToGroup = (options) => {
  return useMutation(MUTATIONS.INVITE_WORKER_TO_GROUP, options);
};

export const useAcceptGroupInvitation = (options) => {
  return useMutation(MUTATIONS.ACCEPT_GROUP_INVITATION, options);
};

export const useDeleteGroup = (options) => {
  return useMutation(MUTATIONS.DELETE_GROUP, options);
};

export const useConfirmNewEmail = (options) => {
  return useMutation(MUTATIONS.CONFIRM_NEW_EMAIL, options);
};

export const useEditGroup = (options) => {
  return useMutation(MUTATIONS.EDIT_GROUP, options);
};

export const useChangeRoleOfGroupMember = (options) => {
  return useMutation(MUTATIONS.CHANGE_ROLE_OF_GROUP_MEMBER, options);
};

export const useDistributeTipPercentage = (options) => {
  return useMutation(MUTATIONS.DISTRIBUTE_TIP_PERCENTAGE, options);
};

export const useRemoveMemberFromGroup = (options) => {
  return useMutation(MUTATIONS.REMOVE_MEMBER_FROM_GROUP, options);
};

export const useLeaveGroup = (options) => {
  return useMutation(MUTATIONS.LEAVE_GROUP, options);
};

export const useCreatePayment = (options) => {
  return useMutation(MUTATIONS.CREATE_PAYMENT, options);
};

export const useCancelPayment = (options) => {
  return useMutation(MUTATIONS.CANCEL_PAYMENT, options);
};

export const useSuccessPayment = (options) => {
  return useMutation(MUTATIONS.SUCCESS_PAYMENT, options);
};

export const useConfirmEmail = (options) => {
  return useMutation(MUTATIONS.CONFIRM_EMAIL, options);
};

export const useReadNotifications = (options) => {
  return useMutation(MUTATIONS.READ_NOTIFICATIONS, options);
};

export const useApplyForWithdrawal = (options) => {
  return useMutation(MUTATIONS.APPLY_FOR_WITHDRAWAL, options);
};

export const useChangeBusinessCardText = (options) => {
  return useMutation(MUTATIONS.CHANGE_BUSINESS_CARD_TEXT, options);
};
export const useConfirmWithdrawalApplication = (options) => {
  return useMutation(MUTATIONS.CONFIRM_WITHDRAWAL_APPLICATION, options);
};
