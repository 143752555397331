import { extendTheme } from "@chakra-ui/react";
import {
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
  LINE_HEIGHTS,
  GLOBAL,
  SIZES,
  RADII,
  SPACES,
  FONTS,
  BREAK_POINTS,
} from "./foundations";
import {
  TEXT,
  BUTTON,
  HEADING,
  CONTAINER,
  INPUT,
  ACCORDION,
  FORM_LABEL,
  MODAL,
  MENU,
  SELECT,
} from "./components";

const theme = extendTheme({
  fonts: FONTS,
  fontSizes: FONT_SIZES,
  fontWeights: FONT_WEIGHTS,
  colors: COLORS,
  lineHeights: LINE_HEIGHTS,
  sizes: SIZES, // widths & heights
  radii: RADII, // border-radiuses
  space: SPACES, // margins & paddings
  breakpoints: BREAK_POINTS,
  components: {
    Text: TEXT,
    Heading: HEADING,
    Button: BUTTON,
    Input: INPUT,
    Container: CONTAINER,
    Accordion: ACCORDION,
    FormLabel: FORM_LABEL,
    Modal: MODAL,
    Menu: MENU,
    Select: SELECT,
  },
  styles: {
    global: GLOBAL,
  },
});

export default theme;
