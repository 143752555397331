import React, { useEffect } from "react";
import { Box, Flex, Heading, Text, Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "rc-pagination/assets/index.css";
import { PaymentTransactionsRow } from "components/PaymentTransactionsRow";
import { useNotificationsContext } from "hooks";
import { TriangleSel } from "components/Icons/CabineteIcon/TriangleSel";
import CHS from "./chakra.styles";

export function PaymentTransactions({
  data,
  refetch,
  handleModal,
  filter,
  setFilter,
}) {
  const { notifications } = useNotificationsContext();

  const dataTransactions =
    data?.getWithdrawalApplications?.withdrawalApplications;

  const { t } = useTranslation();
  const options = [
    { name: "All", value: "All" },
    { name: "Pending", value: "Pending" },
    { name: "Paid", value: "Paid" },
  ];

  const setHandleDay = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [notifications]);

  return (
    <>
      <Flex>
        <Flex
          justifyContent="space-between"
          mb="3.5"
          w="full"
          alignItems="center"
        >
          <Heading variant="cabHeadBox">Payment Transaction</Heading>
          <Box>
            <Select
              value={filter}
              onChange={setHandleDay}
              icon={<TriangleSel />}
            >
              {options.map((val) => (
                <option key={val.value} value={val.value}>
                  {val.name}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Flex>

      {dataTransactions?.length > 0 ? (
        <Flex sx={CHS.table}>
          {dataTransactions?.map((transaction) => (
            <PaymentTransactionsRow
              key={transaction.id}
              data={transaction}
              handleModal={handleModal}
            />
          ))}
        </Flex>
      ) : (
        <Box sx={CHS.emptyList}>
          <Text>{t("app.here_will_be_the_story_of_your_replenishment")}</Text>
        </Box>
      )}
    </>
  );
}
