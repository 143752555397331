export default {
  customMenuButton: {
    w: "full",
    alignItems: "center",
    justifyContent: "space-between",
  },

  menuIcon: {
    w: "2",
    h: "3.5",
    fill: "black.900",

    _groupHover: {
      fill: "brand",
    },
  },
};
