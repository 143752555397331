import React from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AlertModal } from "components/AlertModal";
import { formatDate } from "utils/formatDate";
import { useTranslation } from "react-i18next";
import { MenuOptions } from "components/Menu/MenuOptions";
import { CustomMenuItem } from "components/Menu/CustomMenuItem";
import { CustomMenuButton } from "components/Menu/CustomMenuButton";
import { GET_GROUPS } from "graphql/queries";
import { HandIcon, PercentIcon } from "components/Icons";
import { useCurrentUserContext } from "hooks";
import {
  useChangeRoleOfGroupMember,
  useRemoveMemberFromGroup,
} from "graphql/hooks/useMutations";
import CHS from "./chakra.styles";

export function GroupMemberItem({
  groupMember,
  ownerId,
  register,
  name,
  roles,
  hasError,
  closeModal,
}) {
  const { currentUser } = useCurrentUserContext();
  const { t } = useTranslation();

  const {
    isOpen: isOpenDeleteMemberAlert,
    onOpen: onOpenDeleteMemberAlert,
    onClose: onCloseDeleteMemberAlert,
  } = useDisclosure();

  const [changeRoleOfGroupMember] = useChangeRoleOfGroupMember();
  const [removeMemberFromGroup] = useRemoveMemberFromGroup();

  const showDeleteUserAlert = () => {
    onOpenDeleteMemberAlert();
  };

  const deleteMemberHandler = () => {
    removeMemberFromGroup({
      variables: {
        groupId: groupMember?.groupId,
        userId: groupMember?.userId,
      },
      onCompleted: (response) => {
        console.log(response);
        onCloseDeleteMemberAlert();
        closeModal();
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [GET_GROUPS],
    });
  };

  const transferToNewRole = (id) => {
    changeRoleOfGroupMember({
      variables: {
        groupId: groupMember?.groupId,
        userId: groupMember?.userId,
        newRoleId: id,
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [GET_GROUPS],
    });
  };

  return (
    <>
      <Box sx={CHS.itemWrap}>
        <Flex sx={CHS.item}>
          <HStack spacing="3">
            <Box sx={CHS.itemImg}>
              {groupMember?.user?.avatarUrl ? (
                <Image
                  w="full"
                  h="full"
                  src={groupMember?.user?.avatarUrl}
                  objectFit="cover"
                />
              ) : (
                <HandIcon width="8.5" height="10" fill="brand" />
              )}
            </Box>
            <Box>
              <Heading variant="cabHeadBox">
                {groupMember?.user?.fullName}
              </Heading>
              <Text sx={CHS.smallText}>
                {`${t("app.added")} ${formatDate(groupMember?.createdAt)}`}
              </Text>
            </Box>
          </HStack>
          {ownerId === currentUser?.id && (
            <HStack spacing="2.5">
              {groupMember?.user?.id !== ownerId && (
                <MenuOptions>
                  <MenuItem onClick={showDeleteUserAlert}>
                    {t("app.remove")}
                  </MenuItem>
                  <Menu variant="subMenu">
                    <CustomMenuButton>{t("app.transfer_to")}</CustomMenuButton>
                    <MenuList>
                      <CustomMenuItem isBack>{t("app.back")}</CustomMenuItem>
                      {roles?.map((item) => (
                        <React.Fragment key={item?.id}>
                          <MenuItem onClick={() => transferToNewRole(item?.id)}>
                            {item?.role}
                          </MenuItem>
                        </React.Fragment>
                      ))}
                    </MenuList>
                  </Menu>
                </MenuOptions>
              )}
              <InputGroup>
                <Input
                  {...register(name)}
                  variant="percentage"
                  borderColor={hasError ? "red.500" : "grey.300"}
                  type="number"
                />
                <InputRightElement sx={CHS.percentIcon}>
                  <PercentIcon w="4" h="4" fill="currentColor" />
                </InputRightElement>
              </InputGroup>
            </HStack>
          )}
        </Flex>
      </Box>

      {isOpenDeleteMemberAlert && (
        <AlertModal
          isOpen={isOpenDeleteMemberAlert}
          onClose={onCloseDeleteMemberAlert}
          isClosed
          isMoreButtons
          funcLeftBtn={onCloseDeleteMemberAlert}
          funcRightBtn={deleteMemberHandler}
        >
          <Heading variant="cabHeadPage" lineHeight="11">
            {t("app.you_really_want_to_remove")}
            <Text size="linkText" variant="brand">
              {groupMember?.user?.fullName}
            </Text>
            {` ${t("app.from_group")}`}
          </Heading>
        </AlertModal>
      )}
    </>
  );
}
