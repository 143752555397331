import React, { useState } from "react";
import { PlusIcon } from "components/Icons";
import { CloseIcon } from "components/Icons/CloseIcon";
import { ERRORS_MESSAGES as M } from "constants/constants";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  InputRightElement,
  InputGroup,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import styless from "./chakra.styles";

export function AddEntity({
  name,
  setValue,
  setError,
  label,
  placeholder,
  id,
  getValues,
  isDisabled,
  hasError,
  errorMessage,
}) {
  const [currentValue, setCurrentValue] = useState("");
  const [entityArr, setEntityArr] = useState(getValues(name));

  const onChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const handleAddEntity = () => {
    const entityExist = entityArr.find(
      (item) => item.toLowerCase() === currentValue.toLowerCase()
    );

    if (currentValue.length < 2) {
      setError(name, {
        type: "custom",
        message: M.MIN_2_CHARACTERS,
      });
    } else if (currentValue.length > 64) {
      setError(name, {
        type: "custom",
        message: M.MAX_64_CHARACTERS,
      });
    } else if (entityExist) {
      setError(name, {
        type: "custom",
        message: M.THIS_ENTITY_ALREADY_EXIST,
      });
    } else {
      const newArr = [...entityArr, currentValue];
      setEntityArr(newArr);
      setValue(name, newArr, {
        shouldValidate: true,
      });
      setCurrentValue("");
    }
  };

  const handleRemoveEntity = (item) => {
    const entityArrNew = entityArr.filter((i) => i !== item);
    setEntityArr(entityArrNew);
    setValue(name, entityArrNew, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <FormControl isInvalid={hasError}>
        <FormLabel htmlFor={id} variant="flushed">
          {label}
        </FormLabel>
        <InputGroup alignItems="center">
          <Input
            id={id}
            placeholder={placeholder}
            isDisabled={isDisabled}
            onChange={onChange}
            value={currentValue}
            borderBottomColor={hasError ? "red.500 !important" : "grey.550"}
            variant="flushed"
            autoComplete="off"
          />
          <InputRightElement top="50%" transform="translate(0, -50%)">
            <PlusIcon fill="brand" cursor="pointer" onClick={handleAddEntity} />
          </InputRightElement>
        </InputGroup>
        {hasError && errorMessage && (
          <FormErrorMessage maxW="120">{errorMessage}</FormErrorMessage>
        )}
      </FormControl>
      <Flex sx={styless.entityList}>
        {entityArr?.map((item) => (
          <Flex sx={styless.entityItem} key={item}>
            <Box overflow="hidden" whiteSpace="nowrap">
              <Text sx={styless.entityItemText}>{item}</Text>
            </Box>
            <CloseIcon
              sx={styless.closeIcon}
              onClick={() => handleRemoveEntity(item)}
            />
          </Flex>
        ))}
      </Flex>
    </>
  );
}
