import { gql } from "@apollo/client";

export const GET_LIMITED_USER = gql`
  query getLimitedDataOfUser($id: Int!) {
    getLimitedDataOfUser(id: $id) {
      id
      fullName
      work
      avatarUrl
      avatarName
      qrCodeFilename
      qrCodeUrl
    }
  }
`;
