import { gql } from "@apollo/client";

export const DISTRIBUTE_TIP_PERCENTAGE = gql`
  mutation distributeTipPercentage(
    $groupId: Int!
    $members: [GroupMemberTipPercentageInput!]!
  ) {
    distributeTipPercentage(groupId: $groupId, members: $members) {
      userId
      tipPercentage
    }
  }
`;
