import React from "react";
import { ArrowShortIcon } from "components/Icons";
import { Heading, Flex } from "@chakra-ui/react";

export function ModalTitle({ onClose, title }) {
  return (
    <Flex alignItems="center" cursor="pointer" onClick={onClose}>
      <ArrowShortIcon w="2.5" h="4" fill="black.900" mr="3.5" />
      <Heading variant="cabHeadPage" lineHeight="7">
        {title}
      </Heading>
    </Flex>
  );
}
