export const transformEditGroup = (data) => {
  if (data) {
    const reqObj = {
      newName: data?.name,
      newType: data?.type,
      newRoles: data?.roles,
    };

    if (
      (data?.photo && Object.keys(data?.photo)?.length > 1) ||
      data.photo === null
    ) {
      Object.assign(reqObj, { file: data.photo });
    }

    return reqObj;
  }
  return {};
};
