import { useDropzone } from "react-dropzone";
import { useDropzoneDropStyles, useDropzoneStyles } from "hooks";
import { useCallback, useEffect } from "react";
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
} from "@chakra-ui/react";
import { CameraIcon, TrashIcon } from "components/Icons";
import { DEFAULT_DROPZONE_OPTIONS } from "constants/constants";

export default function Dropzone({
  width,
  height,
  label,
  hasError = false,
  errorMessage,
  name,
  onChange,
  value,
  small = false,
}) {
  const styles = useDropzoneStyles(hasError, width, height);

  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      ...DEFAULT_DROPZONE_OPTIONS,
      onDrop,
    });

  const dropStyle = useDropzoneDropStyles({
    styles,
    isDragAccept,
    isDragReject,
    isFocused,
    small,
  });

  const deletePreviewImage = () => {
    onChange(null);
  };

  useEffect(
    () => () => {
      URL.revokeObjectURL(value?.preview);
    },
    [value]
  );

  return (
    <FormControl isInvalid={hasError} width="auto">
      {label ? <FormLabel>{label}</FormLabel> : null}

      <Box sx={styles.mainContainer}>
        <Box sx={dropStyle} {...getRootProps()}>
          <input type="file" name={name} {...getInputProps({ onChange })} />
          <CameraIcon w="6" h="6" fill="white.900" />
        </Box>
        <Container
          sx={!small ? styles.previewContainer : styles.previewContainerSmall}
        >
          {!value ? (
            <CameraIcon w="10" h="10" fill="grey.700" />
          ) : (
            <>
              <Box
                as="button"
                type="button"
                sx={styles.delBtn}
                onClick={deletePreviewImage}
              >
                <TrashIcon w="6" h="6" fill="none" stroke="white.900" />
              </Box>
              <Image
                src={value.preview}
                sx={styles.previewImage}
                alt="review"
              />
            </>
          )}
        </Container>
      </Box>
      {hasError && errorMessage && (
        <FormErrorMessage maxW="120">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}
