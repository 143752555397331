import sectionIntroTeamBGMob from "assets/img/team/intro/item-1-mob.png";
import sectionIntroTeamBGDesk from "assets/img/team/intro/item-2-desk.png";

import sectionHelperBGMob from "assets/img/team/helper/item-1-mob.png";
import sectionHelperBGDesc from "assets/img/team/helper/item-2-desktop.png";

import sectionHowWorkTeamBGMob from "assets/img/team/how-works/item-1-mob.png";
import sectionHowWorkSoloBGDesk from "assets/img/solo/how-works/item-2-desk.png";

import sectionQuestionsTeamBGDesk from "assets/img/team/questions/item-desk.png";

import sectionOffersBD from "assets/img/team/offers/item1.png";
import sectionOffersBGMob from "assets/img/team/offers/item2.png";

import sectionBannerTeanBGDesk from "assets/img/team/banner/item-desk.png";
import sectionBannerTeam from "assets/img/team/banner/item-1.png";

import iconCirclePink from "assets/img/icon-circle-pink.png";
import iconRectGreen from "assets/img/icon-rect-green.png";

export default {
  // Intro
  sectionIntroTeamWrap: {
    pb: {
      base: "320px",
      md: "40",
      lg: "250px",
    },
    mb: {
      md: "0",
      lg: "0",
    },
    backgroundColor: {
      base: "grey.200",
      md: "transparent",
    },
    bgImage: {
      base: sectionIntroTeamBGMob,
      md: sectionIntroTeamBGDesk,
    },
    bgRepeat: "no-repeat",
    bgPosition: {
      base: "center bottom",
    },
    bgSize: {
      md: "cover",
    },
  },

  sectionIntroTeam: {
    alignItems: "flex-start",
    pb: {
      // lg: "72",
    },
    pt: {
      base: "5",
      lg: "10",
    },
  },

  sectionIntroTeamInner: {
    maxWidth: {
      base: "100%",
      md: "md",
      lg: "2xl",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
  },

  sectionIntroTeamText: {
    textAlign: {
      base: "center",
      md: "left",
    },
    maxWidth: {
      lg: "508px",
    },
  },

  // Helper
  sectionHelperWrap: {
    mt: {
      base: "25",
      md: "0",
      lg: "-130px",
    },
    pb: {
      base: "360px",
      md: "180px",
      lg: "120px",
    },
    pt: {
      md: "15",
    },
    bgImage: {
      base: sectionHelperBGMob,
      lg: sectionHelperBGDesc,
    },
    bgRepeat: "no-repeat",
    bgPosition: {
      base: "center bottom",
      md: "calc(50% - 200px) top",
      lg: "calc(50% - 300px) top",
    },
  },
  sectionHelper: {
    pt: {
      lg: "190px",
    },

    alignItems: {
      base: "center",
      md: "flex-end",
    },
  },

  sectionHelperInner: {
    maxWidth: {
      base: "100%",
      md: "400px",
      lg: "lg",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
  },

  // Advantages
  sectionAdvantagesTeam: {
    mt: {
      base: "10",
      lg: "95px",
    },
  },

  advatagesListTeam: {
    pt: {
      base: "5",
      md: "10",
    },
    flexDirection: {
      base: "column",
      md: "row",
    },
    flexWrap: "wrap",
    justifyContent: "center",
    columnGap: "9",
    rowGap: {
      base: "10",
      md: "12.5",
    },

    div: {
      width: {
        base: "100%",
        md: "47%",
        lg: "33.3333%",
      },
      maxWidth: "375px",
    },
  },

  // How does it works
  sectionHowWorksWrapTeam: {
    mt: {
      base: "25",
      lg: "170px",
    },
    paddingBottom: {
      base: "665px",
      md: "100px",
    },
    bgImage: {
      base: sectionHowWorkTeamBGMob,
      lg: sectionHowWorkSoloBGDesk,
    },
    bgRepeat: "no-repeat",
    bgSize: {
      base: "auto",
    },
    bgPosition: {
      base: "center bottom",
      md: "calc(50% + 200px) center",
      lg: "calc(50% + 300px) center",
    },
  },

  sectionHowWorkteam: {
    justifyContent: {
      base: "center",
      md: "flex-start",
    },
  },

  sectionHowWorksTeamInner: {
    maxWidth: {
      md: "390px",
      lg: "490px",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
  },

  sectionHowWorksTeamList: {
    "div:last-child": {
      borderBottomWidth: "0",
    },
  },

  // Offers
  sectionOffersWrap: {
    mt: {
      lg: "110px",
    },
    pb: {
      base: "280px",
      lg: "200px",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
    bgImage: {
      base: sectionOffersBGMob,
      md: sectionOffersBD,
    },
    bgRepeat: "no-repeat",
    bgPosition: {
      base: "center bottom",
      md: "65% bottom",
      lg: "center center",
    },

    bgSize: {
      base: "auto 220px",
      md: "auto 320px",
      lg: "auto 360px",
    },
  },

  sectionOffers: {
    mt: {
      base: "7",
      lg: "0",
    },
    maxWidth: {
      md: "560px",
    },
  },

  // Questions
  sectionQuestionsTeam: {
    mt: {
      base: "25",
      md: "10",
      lg: "12.5",
    },
    bgImage: {
      base: iconCirclePink,
      md: sectionQuestionsTeamBGDesk,
    },
    bgRepeat: "no-repeat",
    bgSize: {
      base: "120px 120px",
      md: "auto",
    },
    bgPosition: {
      base: "calc(100% + 106px ) bottom",
      md: "center bottom",
    },
  },

  sectionQuestionsInner: {
    alignItems: "flex-start",

    h2: {
      textAlign: "left",
    },
  },

  // SignUpBanner
  sectionSignUpBannerTeamWrap: {
    pt: {
      base: "25",
      lg: "180px",
    },
    pb: {
      base: "10",
      lg: "15",
    },
    bgImage: {
      base: iconRectGreen,
      md: sectionBannerTeanBGDesk,
    },
    bgRepeat: "no-repeat",
    bgSize: {
      base: "22px 22px",
      md: "auto",
    },
    bgPosition: {
      base: "calc(100% - 60px) 16px",
      md: "15% 20px",
    },
  },

  sectionSignUpBannerTeam: {
    justifyContent: {
      md: "flex-end",
    },
    borderRadius: "lg",
    backgroundColor: "pink.100",
    px: {
      base: "2.5",
      lg: "14",
    },
    pt: {
      base: "2.5",
      md: "16",
    },
    pb: {
      base: "270px",
      md: "11",
    },
    bgImage: sectionBannerTeam,
    bgRepeat: "no-repeat",
    bgPosition: {
      base: "center bottom",
      md: "left bottom",
      lg: "70px bottom",
    },
    bgSize: {
      base: "192px 248px",
      lg: "220px 280px",
    },
  },

  sectionSignUpBannerTeamInner: {
    maxWidth: {
      md: "calc(100% - 210px)",
      lg: "3xl",
    },
    alignItems: {
      base: "center",
      md: "flex-start",
    },
    button: {
      width: {
        base: "full",
        md: "42",
      },
    },
  },
};
