import { Icon } from "@chakra-ui/react";

export function ArrowLeft({ props }) {
  return (
    <Icon viewBox="0 0 18 14" width="4.5" height="3.5" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59952 0L7.76267 1.175L3.15955 5.83333L17.9835 5.83333V7.5L3.15955 7.5L7.76267 12.1583L6.59952 13.3333L5.14984e-05 6.66667L6.59952 0Z"
        fill="black"
      />
    </Icon>
  );
}
