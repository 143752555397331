import React, { useEffect, useState } from "react";
import { Box, Container, Flex } from "@chakra-ui/react";
import { HeaderCab } from "components/HeaderCab";
import { FooterCab } from "components/FooterCab";
import CHS from "./chakra.styles";

export default function LayoutCab({ children }) {
  const [scrollContainer, setScrollContainer] = useState(false);

  useEffect(() => {
    if (scrollContainer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [scrollContainer]);

  return (
    <Flex sx={CHS.layoutCabWrapper}>
      <Box sx={CHS.layoutCabInt}>
        <HeaderCab setScrollContainer={setScrollContainer} />
        <Container mb={22.5}>{children}</Container>
        <FooterCab setScrollContainer={setScrollContainer} />
      </Box>
    </Flex>
  );
}
