import { Global } from "@emotion/react";

function Fonts() {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Avenir Next Cyr Regular';
        src: url('/fonts/avenir/AvenirNextCyr-Regular.woff2') format('woff2'),
            url('/fonts/avenir/AvenirNextCyr-Regular.woff') format('woff'),
            url('/fonts/avenir/AvenirNextCyr-Regular.svg#AvenirNextCyr-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Avenir Next Cyr Medium';
        src: url('/fonts/avenir/AvenirNextCyr-Medium.woff2') format('woff2'),
            url('/fonts/avenir/AvenirNextCyr-Medium.woff') format('woff'),
            url('/fonts/avenir/AvenirNextCyr-Medium.svg#AvenirNextCyr-Medium') format('svg');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Avenir Next Cyr Bold';
        src: url('/fonts/avenir/AvenirNextCyr-Bold.woff2') format('woff2'),
            url('/fonts/avenir/AvenirNextCyr-Bold.woff') format('woff'),
            url('/fonts/avenir/AvenirNextCyr-Bold.svg#AvenirNextCyr-Bold') format('svg');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }
        @font-face {
          font-family: 'Inter Bold';
          src: url('/fonts/inter/Inter-Bold.woff2') format('woff2'),
              url('/fonts/inter/Inter-Bold.woff') format('woff'),
              url('/fonts/inter/Inter-Bold.svg#Inter-Bold') format('svg');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Inter Medium';
          src: url('/fonts/inter/Inter-Medium.woff2') format('woff2'),
              url('/fonts/inter/Inter-Medium.woff') format('woff'),
              url('/fonts/inter/Inter-Medium.svg#Inter-Medium') format('svg');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Inter Regular';
          src: url('/fonts/inter/Inter-Regular.woff2') format('woff2'),
              url('/fonts/inter/Inter-Regular.woff') format('woff'),
              url('/fonts/inter/Inter-Regular.svg#Inter-Regular') format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        `}
    />
  );
}

export default Fonts;
