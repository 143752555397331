import { Box, Text } from "@chakra-ui/react";
import CHS from "./chakra.styles";

export function AuthTitleBlock({
  title = "",
  subTitle = "",
  titleFontSize = { base: "4xl", md: "3xl", lg: "5xl" },
  mb = "10",
}) {
  return (
    <Box mb={mb}>
      <Box sx={CHS.titleBlock}>
        <Text sx={CHS.titleText} fontSize={titleFontSize}>
          {title}
        </Text>
      </Box>
      <Box sx={CHS.subTitleBlock}>
        <Text>{subTitle}</Text>
      </Box>
    </Box>
  );
}
