import { gql } from "@apollo/client";
import { FILE_URLS } from "graphql/fragments/fileUrls.fragment";

export const GET_USER = gql`
  ${FILE_URLS}
  query GetUser {
    getUser {
      id
      fullName
      email
      phoneNumber
      work
      iban
      avatarUrl
      createdAt
      balance {
        balance
        fee
        net
        additionalFee
      }
      fileUrls {
        ...fileUrlsFragment
      }
      groupInvitations {
        groupId
        email
      }
      businessCardText
      isAdmin
    }
  }
`;
