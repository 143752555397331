export default {
  alertWrapper: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1600,
    width: "100%",
    height: "100vh",
    background: "black.500",
    alignItems: "center",
    justifyContent: "center",
    color: "white.900",
  },
  alertModal: {
    maxWidth: "110",
    px: "4",
  },
  body: {
    width: "100%",
    maxWidth: "88",
    background: "white.900",
    borderRadius: "lg",
    py: "5",
    px: "5",
    position: "relative",
    color: "black",
  },
  iconBtn: {
    position: "absolute",
    right: 0,
    top: "1.25",
  },
  content: {
    color: "black.900",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    mb: "6",
  },
  btnWrap: {
    width: "full",
    justifyContent: "space-between",
    rowGap: "3",
    columnGap: "0.75",
    flexWrap: "wrap",
  },
};
