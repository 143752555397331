import { IPIFY_URL } from "constants/constants";
import { useEffect, useState } from "react";

export const useGetIpHook = () => {
  const [userIp, setUserIp] = useState();
  const getIp = () => {
    return fetch(IPIFY_URL)
      .then((response) => response.json())
      .then((data) => {
        const { ip } = data;
        return ip;
      });
  };

  useEffect(() => {
    getIp().then((ip) => {
      setUserIp(ip);
    });
  }, []);
  return { ip: userIp };
};
