import React from "react";
import {
  AccordionItem as AccordionItemChakra,
  AccordionButton,
  Box,
  AccordionPanel,
} from "@chakra-ui/react";
import { PlusIcon, MinusIcon } from "components/Icons";

export function AccordionItem({ title, text }) {
  return (
    <AccordionItemChakra>
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {title}
            </Box>
            {isExpanded ? (
              <MinusIcon fill="brand" />
            ) : (
              <PlusIcon w="5" h="5" fill="brand" />
            )}
          </AccordionButton>
          <AccordionPanel>{text}</AccordionPanel>
        </>
      )}
    </AccordionItemChakra>
  );
}
