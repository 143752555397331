import { Icon } from "@chakra-ui/react";

export default function ThreeDotsIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 30 6" fill="none" {...props}>
      <circle cx="3" cy="3" r="3" />
      <circle cx="15" cy="3" r="3" />
      <circle cx="27" cy="3" r="3" />
    </Icon>
  );
}
