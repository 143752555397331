import * as yup from "yup";
import { ERRORS_MESSAGES as M, PASSWORD_VALIDATION } from "constants/constants";

export const confirmPassword = yup.object().shape({
  password: yup
    .string()
    .matches(PASSWORD_VALIDATION, M.PASSWORD_CONTAIN_MESSAGE)
    .required(M.REQUIRE_MESSAGE),
  confirmPassword: yup
    .string()
    .typeError()
    .oneOf([yup.ref("password")], M.PASSWORD_DOES_NOT_MATCH)
    .required(M.REQUIRE_MESSAGE),
});
