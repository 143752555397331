export default {
  "2xs": "0.75rem", // 12px
  xs: "0.875rem", // 14px
  sm: "1rem", // 16px
  md: "1.125rem", // 18px
  lg: "1.25rem", // 20px
  xl: "1.55rem", // 24px
  "1.75xl": "1.75rem", // 28px
  "2xl": "1.875rem", // 30px
  "2.5xl": "2rem", // 32px
  "3xl": "2.25rem", // 36px
  "4xl": "3rem", // 48px
  "5xl": "4rem", // 64px
};
