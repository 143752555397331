import { VStack, Center, Text, HStack } from "@chakra-ui/react";
import React from "react";
import styless from "./chakra.styles";

export function StepItem({ step, title, text, team = false }) {
  return (
    <VStack sx={styless.stepItem} spacing={{ base: "5" }}>
      <HStack spacing={{ base: "2.5", md: "4" }}>
        <Center
          sx={styless.stepItemIndicator}
          bg={`${team ? "purple.300" : "yellow.400"}`}
        >
          {step}
        </Center>
        <Text sx={styless.stepItemTitle}>{title}</Text>
      </HStack>
      <Text sx={styless.stepItemText}>{text}</Text>
    </VStack>
  );
}
