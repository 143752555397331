export default {
  wrapper: {
    position: "fixed",
    width: "full",
    bottom: 0,
    backgroundColor: "brand",
    height: 17.5,
    alignItems: "center",
    justifyContent: "space-between",
    px: 16.5,
    boxShadow: "0px -2px 30px rgba(0, 0, 0, 0.07)",
    borderTopRadius: "9xl",
    maxWidth: "110",
    zIndex: "3",
  },
  centerBtn: {
    width: 15.5,
    height: 15.5,
    borderRadius: "50%",
    backgroundColor: "white.900",
    boxShadow: "0px 8px 40px rgba(0, 0, 0, 0.05)",
    marginBottom: 9,
  },
  dropMenu: {
    backgroundColor: "black.500",
    height: "full",
    color: "white.900",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    width: "100%",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "white.900",
    px: "4",
    pt: "3",
    pb: "21",
    fontSize: "md",
    color: "black.900",
    borderTopRadius: "9xl",
    overflow: "auto",
    width: "100%",
    maxWidth: "110",
  },
  head: {
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  qrCode: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black.900",
    position: "relative",
  },
  logoQr: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  downloadBlock: {
    justifyContent: "center",
    alignItems: "center",
    gap: 2.5,
    mb: 3.75,
    cursor: "pointer",
  },
  horizontalLine: {
    borderWidth: 0.5,
    mb: 3.75,
    width: "auto",
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    gap: 1.5,
    flexDirection: "column",
  },
};
