import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation signUp($input: SignupInput!) {
    signup(input: $input) {
      result
      message
    }
  }
`;
