import LayoutLP from "layouts/LayoutLP";
import LayoutAuth from "layouts/LayoutAuth";
import LayoutCab from "layouts/LayoutCab";
import { PATHS, ACCESS } from "constants/constants";
import Login from "pages/Login";
import Register from "pages/Register";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import Team from "pages/Team";
import Solo from "pages/Solo";
import Home from "pages/Home";
import Settings from "pages/Settings";
import QRCodes from "pages/QRCodes";
import Payment from "pages/Payment";
import LayoutPayment from "layouts/LayoutPayment";
import { PaymentSuccess } from "pages/PaymentSuccess/PaymentSuccess";
import { PaymentCanceled } from "pages/PaymentCanceled/PaymentCanceled";
import { ConfirmEmail } from "pages/confirmEmail/confirmEmail";
import Admin from "pages/Admin/Admin";

export const routes = [
  {
    path: PATHS.admin,
    component: Admin,
    layout: LayoutCab,
    access: ACCESS.private,
  },
  {
    path: PATHS.login,
    component: Login,
    layout: LayoutAuth,
    access: ACCESS.public,
  },
  {
    path: PATHS.register,
    component: Register,
    layout: LayoutAuth,
    access: ACCESS.public,
  },
  {
    path: PATHS.forgotPassword,
    component: ForgotPassword,
    layout: LayoutAuth,
    access: ACCESS.public,
  },
  {
    path: PATHS.resetPassword,
    component: ResetPassword,
    layout: LayoutAuth,
    access: ACCESS.public,
  },
  {
    path: PATHS.team,
    component: Team,
    layout: LayoutLP,
    access: ACCESS.public,
  },
  {
    path: PATHS.solo,
    component: Solo,
    layout: LayoutLP,
    access: ACCESS.public,
  },
  {
    path: PATHS.cab,
    component: Home,
    layout: LayoutCab,
    access: ACCESS.private,
  },
  {
    path: PATHS.qrcodes,
    component: QRCodes,
    layout: LayoutCab,
    access: ACCESS.private,
  },
  {
    path: PATHS.settings,
    component: Settings,
    layout: LayoutCab,
    access: ACCESS.private,
  },
  {
    path: PATHS.payment,
    component: Payment,
    layout: LayoutPayment,
    access: ACCESS.public,
  },
  {
    path: PATHS.paymentSuccess,
    component: PaymentSuccess,
    layout: LayoutPayment,
    access: ACCESS.public,
  },
  {
    path: PATHS.paymentCanceled,
    component: PaymentCanceled,
    layout: LayoutPayment,
    access: ACCESS.public,
  },
  {
    path: PATHS.confirmEmail,
    component: ConfirmEmail,
    layout: LayoutAuth,
    access: ACCESS.public,
  },
];
