import * as yup from "yup";
import { validateIBAN } from "ibantools";
import { ERRORS_MESSAGES as M, PASSWORD_VALIDATION } from "constants/constants";

export const settingsProfileSchema = yup.object().shape({
  fullName: yup
    .string()
    .matches(/^[A-Z][a-z]{1,19} [A-Z][a-z].{1,19}$/, M.VALID_FULL_NAME)
    .required(M.REQUIRE_MESSAGE),
  email: yup.string().email(M.VALID_EMAIL).required(M.REQUIRE_MESSAGE),
  work: yup.string().test("", M.MIN_2_CHARACTERS, (val) => {
    if (val === null) return true;
    return val?.length >= 2 || val?.length === 0;
  }),
  phoneNumber: yup
    .string()
    .test("", M.REQUIRE_MESSAGE, (val) => val.length > 1),
  iban: yup.string().test("", M.CORRECT_IBAN, (value) => {
    if (!value) return true;
    return validateIBAN(value).valid;
  }),
  oldPassword: yup.string(),
  password: yup.string().when("oldPassword", {
    is: (val) => val.length > 0,
    then: yup.string().matches(PASSWORD_VALIDATION, M.PASSWORD_CONTAIN_MESSAGE),
    otherwise: yup.string().nullable(),
  }),
  confirmPassword: yup
    .string()
    .typeError()
    .oneOf([yup.ref("password")], M.PASSWORD_DOES_NOT_MATCH),
});
