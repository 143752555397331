import * as yup from "yup";
import { ERRORS_MESSAGES as M } from "constants/constants";

export const paymentSchema = yup.object().shape({
  sum: yup
    .number()
    .typeError(M.REQUIRE_MESSAGE)
    .min(3, M.MIN_3)
    .required(M.REQUIRE_MESSAGE),
});
