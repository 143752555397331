import { Icon } from "@chakra-ui/react";

export default function SuccessIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 96 96" fill="none" {...props}>
      <rect width="96" height="96" rx="48" fill="#4CAF50" />
      <path
        d="M42 56.4001L33.6 48.0001L30.8 50.8001L42 62.0001L66 38.0001L63.2001 35.2001L42 56.4001Z"
        fill="white"
      />
    </Icon>
  );
}
