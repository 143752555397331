export const transformBeforeUpdateGroup = (data) => {
  if (data) {
    return {
      photo: data?.avatarUrl
        ? {
            preview: data?.avatarUrl,
          }
        : null,
      name: data?.name,
      type: data?.type,
      roles: data?.groupRoles
        ?.map((i) => i?.role)
        .filter((i) => i !== "Owner" && i !== "No role"),
    };
  }
  return {};
};
