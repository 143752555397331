export default {
  dropZone: {
    alignItems: "center",
    justifyContent: "center",
    mb: 6,
  },
  formField: {
    backgroundColor: "white.900",
    borderRadius: "lg",
    p: 4,
    mb: 5,
  },
  passwordField: {
    ".chakra-input": {
      marginTop: 1,
    },
    ".chakra-input__right-element": {
      right: "0",
      svg: {
        stroke: "grey.500",
      },
    },
  },
  button: {
    w: "full",
    borderRadius: "base",
    h: 12,
    mb: 7.5,
    _hover: {
      _disabled: {
        bgColor: "red",
      },
    },
  },
};
