import React, { useEffect } from "react";
import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TriangleSel } from "components/Icons/CabineteIcon/TriangleSel";
import Pagination from "rc-pagination";
import { ArrowLeft } from "components/Icons/CabineteIcon/ArrowLeft";
import "rc-pagination/assets/index.css";
import { ArrowRightIcon } from "components/Icons/CabineteIcon/ArrowRightIcon";
import { LatestTransactionsRow } from "components/LatestTransactionsRow";
import { COUNTER_PER_PAGE } from "constants/constants";
import { useNotificationsContext } from "hooks";
import CHS from "./chakra.styles";

export function LatestTransactions({
  data,
  refetch,
  offset,
  setOffset,
  filter,
  setFilter,
}) {
  const options = ["All", "Today", "Yesterday"];
  const { notifications } = useNotificationsContext();

  const dataCount = data?.getInnerTransactions?.count;
  const dataTransactions = data?.getInnerTransactions?.innerTransactions;

  const { t } = useTranslation();

  const setHandleDay = (e) => {
    setOffset(0);
    setFilter(e.target.value);
  };

  const handlePagination = (page) => {
    setOffset((page - 1) * COUNTER_PER_PAGE);
  };

  const getDefaultPage = () => {
    return offset / COUNTER_PER_PAGE + 1;
  };

  useEffect(() => {
    refetch();
  }, [notifications]);

  return (
    <>
      <Flex>
        <Flex
          justifyContent="space-between"
          mb="3.5"
          w="full"
          alignItems="center"
        >
          <Heading variant="cabHeadBox">{t("app.latest_transactions")}</Heading>
          <Box>
            <Select
              value={filter}
              onChange={setHandleDay}
              icon={<TriangleSel />}
            >
              {options.map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Flex>

      {dataTransactions?.length > 0 ? (
        <Flex sx={CHS.table}>
          {dataTransactions?.map((transaction) => (
            <LatestTransactionsRow key={transaction.id} data={transaction} />
          ))}
          <Pagination
            defaultCurrent={1}
            current={getDefaultPage()}
            total={dataCount}
            pageSize={COUNTER_PER_PAGE}
            onChange={handlePagination}
            showLessItems
            prevIcon={ArrowLeft}
            nextIcon={ArrowRightIcon}
            showPrevNextJumpers={true}
          />
        </Flex>
      ) : (
        <Box sx={CHS.emptyList}>
          <Text>{t("app.here_will_be_the_story_of_your_replenishment")}</Text>
        </Box>
      )}
    </>
  );
}
