export default {
  dropMenu: {
    backgroundColor: "black.500",
    height: "full",
    color: "white.900",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    width: "100%",
    alignItems: "center",
    "*": {
      scrollbarWidth: "thin",
    },
    "& ::-webkit-scrollbar": {
      borderRadius: "sm",
      width: "1.25",
      display: "block",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "brand",
      borderRadius: "lg",
      borderWidth: "1.25",
      borderRightStyle: "solid",
      borderColor: "brand",
      display: "block",
    },
    "& ::-webkit-scrollbar-button": {
      bgColor: "transparent",
      height: "1.5",
      display: "block",
    },
  },
  toMozillaScrollWrapper: {
    borderRadius: "lg",
    overflow: "hidden",
  },
  modal: {
    px: "4",
    fontSize: "md",
    color: "black.900",
    width: "100%",
    maxWidth: "110",
    position: "relative",
    bottom: "100px",
  },
  modalBody: {
    backgroundColor: "white.900",
    borderRadius: "lg",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.08)",
    px: "7.5",
    py: "5",
    maxHeight: "118",
    height: "100%",
    overflow: "auto",
    "&:before": {
      content: '""',
      position: "absolute",
      right: "19",
      bottom: "-4.5",
      borderWidth: "10px", // ! в px (before)
      borderStyle: "solid",
      borderColor: "transparent",
      borderTopWidth: "2.5",
      borderTopStyle: "solid",
      borderTopColor: "white.900",
    },
  },

  dayBlock: {
    flexDirection: "column",
    mb: "2.5",
  },

  rowWrap: {
    flexDirection: "column",
    ":not(:last-child)": {
      "&:after": {
        content: '""',
        display: "inline-flex",
        width: "100%",
        height: "1px",
        mb: "2.5",
        backgroundColor: "black.100",
      },
    },
  },
  topRowAbsolute: {
    position: "relative",
    "&:after": {
      content: '""',
      display: "flex",
      position: "absolute",
      top: "-13px",
      left: 0,
      width: "100%",
      height: "3px",
      backgroundColor: "white.900",
    },
  },

  row: {
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "2.5",
  },

  todayBlock: {
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "2.5",
    "&:before": {
      content: '""',
      position: "absolute",
      right: "1.5px",
      bottom: "1px",
      w: "1.5",
      h: "1.5",
      borderRadius: "50%",
      backgroundColor: "brand",
      left: "0",
      top: "50%",
      transform: "translate(-14px, -50%)",
    },
  },
  content: {
    flex: "1",
    ml: "2.5",
  },
  iconBlock: {
    borderRadius: "md",
    backgroundColor: "white.900",
    w: "10",
    h: "10",
  },
  img: {
    height: "inherit",
    borderRadius: "inherit",
  },
  badgeBlock: {
    alignItems: "center",
    justifyContent: "center",
    width: "12",
    height: "5",
    borderRadius: "xs",
  },
  horizontal: {
    borderWidth: "0.5",
    mb: "3.75",
    width: "auto",
  },

  link: {
    pl: "2",
    color: "brand",
    textDecoration: "underline",
    maxWidth: "150px",
  },
};
