import { Icon } from "@chakra-ui/react";

export default function HomeMainMenuCab({ ...props }) {
  return (
    <Icon viewBox="0 0 24 26" fill="none" {...props}>
      <path
        strokeWidth="0.7"
        d="M10.7993 1.50415C11.1418 1.18039 11.5951 1 12.0664 1C12.5377 1 12.9911 1.18039 13.3335 1.50415L22.2678 9.94967C22.8211 10.4716 23.1328 11.2002 23.1328 11.9601V22.2334C23.1328 22.9671 22.8414 23.6708 22.3225 24.1897C21.8037 24.7085 21.1 25 20.3662 25H16.6774C16.3139 25 15.954 24.9284 15.6183 24.7892C15.2825 24.6501 14.9774 24.4461 14.7205 24.189C14.4636 23.9319 14.2598 23.6267 14.1209 23.2908C13.982 22.955 13.9106 22.595 13.9108 22.2316V17.6205C13.9108 17.376 13.8137 17.1414 13.6407 16.9684C13.4678 16.7955 13.2332 16.6983 12.9886 16.6983H11.1442C10.8996 16.6983 10.6651 16.7955 10.4921 16.9684C10.3192 17.1414 10.222 17.376 10.222 17.6205V22.2316C10.222 22.9653 9.93053 23.669 9.41169 24.1878C8.89285 24.7067 8.18916 24.9982 7.45541 24.9982H3.7666C3.03285 24.9982 2.32916 24.7067 1.81032 24.1878C1.29148 23.669 1 22.9653 1 22.2316V11.9582C1 11.1983 1.31355 10.4698 1.86687 9.94783L10.7993 1.50415ZM12.0664 2.84319L3.13213 11.2906C3.04143 11.3765 2.96915 11.4801 2.91969 11.5948C2.87023 11.7096 2.84462 11.8332 2.8444 11.9582V22.2316C2.8444 22.4761 2.94156 22.7107 3.11451 22.8836C3.28746 23.0566 3.52202 23.1538 3.7666 23.1538H7.45541C7.69999 23.1538 7.93456 23.0566 8.1075 22.8836C8.28045 22.7107 8.37761 22.4761 8.37761 22.2316V17.6205C8.37761 16.8868 8.66909 16.1831 9.18793 15.6643C9.70677 15.1454 10.4105 14.8539 11.1442 14.8539H12.9886C13.7224 14.8539 14.4261 15.1454 14.9449 15.6643C15.4637 16.1831 15.7552 16.8868 15.7552 17.6205V22.2316C15.7552 22.4761 15.8524 22.7107 16.0253 22.8836C16.1983 23.0566 16.4328 23.1538 16.6774 23.1538H20.3662C20.6108 23.1538 20.8454 23.0566 21.0183 22.8836C21.1913 22.7107 21.2884 22.4761 21.2884 22.2316V11.9582C21.2885 11.8329 21.263 11.709 21.2135 11.5938C21.164 11.4787 21.0916 11.3749 21.0007 11.2887L12.0664 2.84319Z"
      />
    </Icon>
  );
}
