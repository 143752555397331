import { Box, Divider, Flex, IconButton, Image, Slide } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { useRef } from "react";

import { useTranslation } from "react-i18next";
import { useCurrentUserContext } from "hooks";
import CHS from "components/FooterCab/chakra.styles";
import { CloseIcon } from "components/Icons/CloseIcon";
import { DownloadIcon } from "components/Icons";

export function QrModal({ isOpen, onClose }) {
  const { currentUser } = useCurrentUserContext();

  const ref = useRef(null);

  const { t } = useTranslation();

  const handleClose = (e) => {
    if (e.target === ref.current) {
      onClose();
    }
  };

  const downloadQrCode = () => {
    saveAs(currentUser?.fileUrls?.qrCodePng, "qr-code");
  };
  return (
    <Slide
      direction="bottom"
      in={isOpen}
      style={{
        zIndex: 10,
        height: "100%",
      }}
    >
      <Box sx={CHS.dropMenu} onClick={handleClose} ref={ref}>
        <Box sx={CHS.modal}>
          <Flex sx={CHS.head}>
            <Box>{t("app.your_code")}</Box>
            <IconButton
              onClick={onClose}
              variant="unstyled"
              aria-label="CloseIcon"
              icon={<CloseIcon fill="black.900" w={3} h={3} />}
            />
          </Flex>
          <Flex justifyContent="center" mb={7.5}>
            <Box sx={CHS.qrCode}>
              <Image
                src={currentUser?.fileUrls?.qrCodePng}
                alt={currentUser?.fileUrls?.qrCodePng}
                w="256px"
                h="256px"
                bgColor="white.900"
              />
            </Box>
          </Flex>
          <Flex sx={CHS.downloadBlock} onClick={downloadQrCode}>
            <DownloadIcon width="4" height="4" stroke="black.900" />
            {t("app.download")}
          </Flex>
          <Box />
          <Divider sx={CHS.horizontalLine} />
          {/* <Flex sx={CHS.footer}>
            <Box>{t("app.invite_friend")}:</Box>
            <Text fontSize="md">{QR_VALUE_MOC.link}</Text>
          </Flex> */}
        </Box>
      </Box>
    </Slide>
  );
}
