export function getInvitationsState(arr) {
  return arr?.map((item) => ({
    id: item.groupId,
    show: true,
  }));
}

export function getInviteObjShow(arr, id) {
  return arr?.find((item) => item.id === id)?.show;
}
