import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Box,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Button from "components/Button/Button";
import { HeaderCab } from "components/HeaderCab";
import { ModalTitle } from "components/Modals/ModalTitle";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { AlertModal } from "components/AlertModal";
import { useForm } from "react-hook-form";
import { getTime } from "utils/getTime";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInviteWorkerToGroup } from "graphql/hooks/useMutations";
import { useTranslation } from "react-i18next";
import { forgotPasswordSchema } from "validation";

const INITIAL_VALUES_ADD_WORKER = {
  email: "",
};

export function AddWorkerModal({ isOpen, onClose, groupId }) {
  const [formSending, setFormSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation();

  const [inviteWorkerToGroup] = useInviteWorkerToGroup();

  const {
    isOpen: isOpenSuccessSentInvitationAlert,
    onOpen: onOpenSuccessSentInvitationAlert,
    onClose: onCloseSuccessSentInvitationAlert,
  } = useDisclosure();

  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultvalues: INITIAL_VALUES_ADD_WORKER,
    resolver: yupResolver(forgotPasswordSchema),
  });

  const closeAlertHandler = () => {
    reset(INITIAL_VALUES_ADD_WORKER);
    setErrorMessage("");
    onCloseSuccessSentInvitationAlert();
  };

  const onSubmit = (data) => {
    setFormSending(true);
    inviteWorkerToGroup({
      variables: {
        groupId,
        email: data.email,
      },
      onCompleted: (response) => {
        setFormSending(false);
        if (response?.inviteWorkerToGroup?.success) {
          onOpenSuccessSentInvitationAlert();
        } else if (
          response?.inviteWorkerToGroup?.numberOfUsedInvitations === 10
        ) {
          setErrorMessage(
            t("app.you_have_used_all_attempts_for_the_current_email")
          );
        } else {
          setErrorMessage(
            `${t("app.used")} ${
              response?.inviteWorkerToGroup?.numberOfUsedInvitations
            } ${t(
              "app.out_of_10_possible_invitations_for_the_current_email_try_again_through"
            )} ${getTime(response?.inviteWorkerToGroup?.delayBeforeNextSend)}`
          );
        }
      },
      onError: ({ graphQLErrors }) => {
        setFormSending(false);
        if (graphQLErrors) {
          switch (graphQLErrors?.[0]?.message) {
            case "This email already in group":
              setError("email", {
                type: "custom",
                message: t(
                  "app.a_user_with_this_email_address_already_exists_in_your_group"
                ),
              });
              break;
            default:
              console.log(graphQLErrors?.[0]?.message);
          }
        }
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader alignItems="center">
          <HeaderCab />
          <Flex alignItems="center" ml={4}>
            <ModalTitle onClose={onClose} title="Add worker" />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box borderRadius="lg" bgColor="white.900" px="4" py="4" mb="5">
              <TextFieldRHF
                mb={1}
                variant="flushed"
                register={register}
                name="email"
                placeholder="Type Email"
                label={t("app.send_invite_to_email")}
                hasError={errors?.email}
                errorMessage={errors?.email?.message}
                autoComplete="off"
              />
              <Text>{errorMessage}</Text>
            </Box>
            <Button
              variant="cabSolid"
              size="cabBigModal"
              type="submit"
              disabled={formSending}
            >
              {t("app.send_invite")}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>

      {isOpenSuccessSentInvitationAlert && (
        <AlertModal
          isOpen={isOpenSuccessSentInvitationAlert}
          onClose={closeAlertHandler}
        >
          <Box px={10}>
            <Heading variant="cabHeadPage" lineHeight="11">
              {t("app.your_invitation_is_sent")}
            </Heading>
          </Box>
        </AlertModal>
      )}
    </Modal>
  );
}
