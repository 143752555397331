import { gql } from "@apollo/client";

export const GET_NOTIFICATION = gql`
  query GetNotifications {
    getNotifications {
      id
      userId
      name
      text
      isWatched
      createdAt
      addingToGroup {
        groupId
        group {
          id
          name
          avatarUrl
          owner {
            fullName
          }
        }
      }
      creditingToBalance {
        value
      }
    }
  }
`;
