import { gql } from "@apollo/client";

export const GET_GROUP_FOR_PAYMENT = gql`
  query getGroup($getGroupId: Int!) {
    getGroup(id: $getGroupId) {
      id
      name
      avatarUrl
    }
  }
`;
