import { Box, Flex, Text } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import React from "react";
import CHS from "./chakra.styles";
import "react-phone-input-2/lib/material.css";
import styles from "./phone.module.css";

export function PhoneInputCustom({
  setValue,
  name,
  label = "Label",
  hasError,
  errorMessage,
  trigger,
  mb = 0,
  placeholder = "+",
  defaultValue = "",
  variant = "base",
}) {
  const handleChange = (value) => {
    setValue(name, value);
    if (!hasError) return;
    trigger(name);
  };
  return (
    <Flex sx={CHS.maskWrapper} mb={mb}>
      <Text sx={variant === "base" ? CHS.maskLabel : CHS.maskFlushedLabel}>
        {label}
      </Text>
      <Box position="relative">
        {hasError && (
          <Box
            sx={variant === "base" ? CHS.errorBlock : CHS.errorFlushedBlock}
          />
        )}
        <PhoneInput
          inputClass={
            variant === "base" ? styles.inputBase : styles.inputFlushed
          }
          containerClass={styles.container}
          specialLabel=""
          placeholder={placeholder}
          value={defaultValue}
          onChange={handleChange}
          country="ee"
        />
      </Box>
      {hasError && <Box sx={CHS.errorM}>{errorMessage}</Box>}
    </Flex>
  );
}
