import { BrowserRouter } from "react-router-dom";
import Navigation from "navigation/Navigation";
import AuthProvider from "context/auth/auth.provider";
import CurrentUserProvider from "context/currentUser/currentUser.provider";
import NotificationsProvider from "context/notifications/notifications.provider";
import UtilsProvider from "context/utilsContext/UtilsContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CurrentUserProvider>
          <NotificationsProvider>
            <UtilsProvider>
              <Navigation />
            </UtilsProvider>
          </NotificationsProvider>
        </CurrentUserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
