export default {
  variants: {
    brand: {
      container: {
        boxShadow: "0 9px 23px rgba(0, 0, 0, 0.04)",
        borderRadius: "lg",
        my: {
          base: "2.5",
          md: "1.25",
        },
        borderTopWidth: "0",
        _last: {
          borderBottomWidth: "0",
        },
      },
      button: {
        px: {
          base: "2.5",
          md: "7.5",
        },
        py: {
          base: "2.5",
          md: "5.5",
        },
        fontFamily: "mediumAvenir",
        fontSize: {
          base: "md",
          md: "xl",
        },
        lineHeight: {
          base: "7",
          md: "10",
        },
        backgroundColor: "white.900",
        borderRadius: "lg",

        color: "black.900",

        _expanded: {
          color: "brand",
        },
        _hover: {
          backgroundColor: "white.900",
        },
      },
      panel: {
        px: {
          base: "2.5",
          md: "7.5",
        },
        pt: {
          base: "0",
          md: "2",
        },
        pb: {
          base: "2.5",
          md: "18",
        },
        color: "grey.500",
      },
    },
  },

  defaultProps: {
    variant: "brand",
  },
};
