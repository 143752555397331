import { gql } from "@apollo/client";

export const GET_GROUPS = gql`
  query getGroups {
    getGroups {
      id
      ownerId
      name
      type
      avatarUrl
      createdAt
      groupRoles {
        id
        groupId
        role
        groupMembers {
          groupId
          userId
          roleId
          tipPercentage
          createdAt
          user {
            id
            fullName
            avatarUrl
          }
        }
      }
      owner {
        id
        fullName
        avatarUrl
      }
      fileUrls {
        qrCodePng
        qrCodePdf
        businessCardPng
        businessCardPdf
        invertedBusinessCardPng
        invertedBusinessCardPdf
      }
      businessCardText
    }
  }
`;
