import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { getFormatDate } from "utils/getFormatDate";
import { HandIcon } from "components/Icons";
import { textSlice } from "utils/textSlice";
import CHS from "./chakra.styles";

export function PaymentTransactionsRow({ data, handleModal }) {
  const {
    icon = "",
    amount = 0,
    createdAt = new Date(),
    moderationStatus,
    type,
    user,
  } = data;

  return (
    <Box sx={CHS.rowWrapper}>
      <Flex
        sx={CHS.rowBody}
        onClick={() => {
          if (moderationStatus !== "paid") {
            handleModal(data);
          }
        }}
      >
        <Box sx={CHS.iconBlock}>
          {user?.avatarUrl ? (
            <Image src={user?.avatarUrl} alt={icon} sx={CHS.img} />
          ) : (
            <HandIcon width="8.5" height="10" fill="white.900" />
          )}
        </Box>
        <Flex sx={CHS.textBlock}>
          <Box>
            <Text size="medium" variant="standard">
              {user?.fullName}
            </Text>
          </Box>
          <Box>
            <Text size="notify2xs">
              <HideText email={user?.email} />
            </Text>
          </Box>
        </Flex>
        <Flex sx={CHS.valueBlock}>
          {type === "outcome" && moderationStatus && (
            <Box sx={CHS.valueBlockInner}>status: {moderationStatus}</Box>
          )}
          <Box>
            <Text size="primary" variant="brand" sx={CHS.valueBlockInner}>
              {amount} €
            </Text>
          </Box>
          <Box>
            <Text size="small" variant="standard" sx={CHS.valueBlockInner}>
              {getFormatDate(createdAt)}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export function HideText({ email = "" }) {
  const [click, setClick] = useState(false);
  if (click)
    return (
      <Text
        size="notify2xs"
        onClick={(e) => {
          e.stopPropagation();
          setClick(false);
        }}
      >
        {email}
      </Text>
    );

  return (
    <Text
      size="notify2xs"
      onClick={(e) => {
        e.stopPropagation();
        setClick(true);
      }}
    >
      {textSlice({ text: email, count: 20 })}
    </Text>
  );
}
