import * as yup from "yup";
import { ERRORS_MESSAGES as M, PASSWORD_VALIDATION } from "constants/constants";

export const registerSchema = yup.object().shape({
  fullName: yup
    .string()
    .matches(/^[A-Z][a-z]{1,19} [A-Z][a-z].{1,19}$/, M.VALID_FULL_NAME)
    .required(M.REQUIRE_MESSAGE),
  phone: yup
    .string()
    .test("", M.REQUIRE_MESSAGE, (val) => val && val.length > 1)
    .required(M.REQUIRE_MESSAGE),
  email: yup.string().email(M.VALID_EMAIL).required(M.REQUIRE_MESSAGE),
  password: yup
    .string()
    .matches(PASSWORD_VALIDATION, M.PASSWORD_CONTAIN_MESSAGE)
    .required(M.REQUIRE_MESSAGE),
  confirmPassword: yup
    .string()
    .typeError()
    .oneOf([yup.ref("password")], M.PASSWORD_DOES_NOT_MATCH)
    .required(M.REQUIRE_MESSAGE),
});
