import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Slide,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { LogoIcon } from "components/Icons/LogoIcon";
import { BurgerMenuIcon } from "components/Icons/BurgerMenuIcon";
import { CloseIcon } from "components/Icons/CloseIcon";
import { Tabs } from "components/Tabs/Tabs";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import CHS from "./chakra.styles";
import { useAuthContext } from "../../hooks";

export function Header({ isAuthLayout }) {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  function getTabName() {
    if (location.pathname === PATHS.team) return "Team";
    return "Solo";
  }

  const text = getTabName();
  const { isAuth } = useAuthContext();

  const goHome = () => {
    navigate(isAuth ? PATHS.cab : PATHS.solo);
  };

  const isPathLogin = location.pathname === PATHS.login;
  return (
    <Box sx={CHS.wrapper}>
      <Container>
        <Box sx={CHS.content}>
          {!isAuthLayout && <BurgerMenu isMobile={isMobile} text={text} />}
          <LogoIcon
            cursor="pointer"
            onClick={goHome}
            width={isMobile ? 24 : 38}
            height={isMobile ? 10 : 16}
          />
          {!isMobile && !isAuthLayout && <Tabs />}
          {!isMobile || isAuthLayout ? (
            <Flex
              flexDirection={isAuthLayout ? "row-reverse" : "row"}
              mt={2.5}
              alignItems="center"
            >
              <Box ml={isAuthLayout ? 0 : 10} mr={isAuthLayout ? 5 : 0}>
                <RouterLink to={isPathLogin ? PATHS.register : PATHS.login}>
                  <Text sx={CHS.linkText}>
                    {isPathLogin ? t("app.registration") : t("app.sign_in")}
                  </Text>
                </RouterLink>
              </Box>
            </Flex>
          ) : (
            <Text fontSize="md" color="black.900">
              {text}
            </Text>
          )}
        </Box>
      </Container>
    </Box>
  );
}

function BurgerMenu({ isMobile, text }) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      {isMobile && (
        <BurgerMenuIcon
          cursor="pointer"
          w={5}
          h={3.5}
          onClick={onToggle}
          fill="brand"
        />
      )}
      <Slide direction="top" in={isOpen} style={{ zIndex: 10 }}>
        <Box sx={CHS.dropMenu} rounded="md" shadow="md">
          <Box sx={CHS.dropMenuHeader}>
            <CloseIcon
              w={5}
              h={5}
              onClick={onClose}
              fill="brand"
              cursor="pointer"
            />
            <LogoIcon w="24" h="10" />
            <Text fontSize="md" fontWeight="medium" color="black.900" w={12}>
              {text}
            </Text>
          </Box>
          <Center flexDirection="column">
            <Box mb={5}>
              <Tabs />
            </Box>
            <Flex flexDirection="column" mb={5}>
              <Button sx={CHS.btn} as={RouterLink} to={PATHS.login}>
                {t("app.sign_in")}
              </Button>
              <Button as={RouterLink} to="/site/register">
                {t("app.sign_up")}
              </Button>
            </Flex>
            <Box sx={CHS.horizontalLine} />
          </Center>
        </Box>
      </Slide>
    </>
  );
}
