export default {
  wrapper: {
    py: "10",
    px: "10",
    minW: "66",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: "100vh",
  },
  formWrap: {
    flexDirection: "column",
    width: "100",
    textAlign: "center",
    input: {
      textAlign: "center",
    },
  },
  btnValueChange: {
    justifyContent: "space-between",
    mb: "7.5",
  },
  btnWrapper: {
    flexDirection: "column",
    gap: "2.5",
    button: {
      height: "12.5",
    },
  },
  logo: {
    marginBottom: "10",
  },
};
