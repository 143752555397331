export const getTipsPercentage = (groupMembers) => {
  const obj = {};

  if (groupMembers) {
    groupMembers?.forEach((item) =>
      item?.groupMembers?.forEach((i) => {
        obj[i.user.id] = i.tipPercentage;
      })
    );
  }

  return obj;
};

export const getRolesArrayForMenu = (groupRoles, itemRole) => {
  const menuArr = [];

  if (groupRoles) {
    groupRoles?.forEach((item) => {
      if (
        item.role !== "Owner" &&
        item.role !== "No role" &&
        item.role !== itemRole
      ) {
        menuArr.push({ id: item.id, role: item.role });
      }
    });
  }

  return menuArr;
};
