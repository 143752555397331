import { gql } from "@apollo/client";

export const READ_NOTIFICATIONS = gql`
  mutation readNotifications($notificationsId: [Int!]!) {
    readNotifications(notificationsId: $notificationsId) {
      id
      userId
      name
      text
      isWatched
      createdAt
    }
  }
`;
