export default {
  wrapper: {
    pt: "5",
    pb: "10",
    minW: "66",
    minH: "100vh",
    width: "100%",
    flexDirection: "column",
    gap: "50px",
    alignItems: "center",
    maxH: "600px",
  },
  logoBlock: {
    flexDirection: "column",
    alignItems: "center",
    gap: "5",
  },
};
