import { Icon } from "@chakra-ui/react";

export default function BurgerMenuIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 12" {...props}>
      <rect width="24" height="2.5" rx="1.25" />
      <rect x="8" y="9.5" width="16" height="2.5" rx="1.25" />
    </Icon>
  );
}
