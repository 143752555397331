export default {
  header: {
    alignItems: "center",
  },
  fieldsBlock: {
    flexDirection: "column",
    backgroundColor: "white.900",
    padding: "4",
    borderRadius: "lg",
    mb: "5",
  },
};
