import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useGetActivityLogs } from "graphql/hooks/useQueries";
import { useEffect, useState } from "react";
import { COUNTER_PER_ACTIVITY_LOGS } from "constants/constants";
import { ArrowLeft } from "components/Icons/CabineteIcon/ArrowLeft";
import { ArrowRightIcon } from "components/Icons/CabineteIcon/ArrowRightIcon";
import Pagination from "rc-pagination";
import { useNotificationsContext } from "hooks";
import CHS from "./chakra.styles";

export function ActivityLogsTab() {
  const { notifications } = useNotificationsContext();
  const [offset, setOffset] = useState(0);

  const { data, loading, error, refetch } = useGetActivityLogs({
    variables: {
      limit: COUNTER_PER_ACTIVITY_LOGS,
      offset,
    },
    fetchPolicy: "network-only",
  });

  const activityLogsData = data?.getActivityLogs?.rows;
  const dataCount = data?.getActivityLogs?.count;

  const handlePagination = (page) => {
    setOffset((page - 1) * COUNTER_PER_ACTIVITY_LOGS);
  };

  const getDefaultPage = () => {
    return offset / COUNTER_PER_ACTIVITY_LOGS + 1;
  };

  useEffect(() => {
    refetch();
  }, [notifications]);

  if (!data || loading || error) return null;

  return (
    <Flex width="100%" flexDirection="column">
      <Accordion allowMultiple w="full">
        {activityLogsData.map((log) => {
          const date = new Date(Number(log.createdAt)).toLocaleString();
          const userData = log.user;
          return (
            <AccordionItem key={log.id}>
              <AccordionButton
                sx={{
                  padding: "16px !important",
                }}
              >
                <VStack flex="1" alignItems="start">
                  <Text size="notifyXsInterMedium">Action: {log.action}</Text>
                  <Text size="notifyXsInterMedium">Date: {date}</Text>
                  {log.info && (
                    <Text size="notifyXsInterMedium" sx={CHS.text}>
                      Info: {log.info}
                    </Text>
                  )}
                  <Text size="notifyXsInterMedium">Status: {log.status}</Text>
                  {log.ip && (
                    <Text size="notifyXsInterMedium">IP: {log.ip}</Text>
                  )}
                </VStack>
                {!!userData && <AccordionIcon w={6} h={6} />}
              </AccordionButton>
              {!!userData && (
                <AccordionPanel pb={4}>
                  <Text size="notifyXsInterMedium">User info</Text>
                  <Text size="notifyXsInterMedium">
                    Full Name: {userData.fullName}
                  </Text>
                  <Text size="notifyXsInterMedium">
                    Email: {userData.email}
                  </Text>
                  <Text size="notifyXsInterMedium">
                    Phone Number : {userData.phoneNumber}
                  </Text>
                  {userData.iban && (
                    <Text size="notifyXsInterMedium">
                      IBAN: {userData.iban}
                    </Text>
                  )}
                  {userData.work && (
                    <Text size="notifyXsInterMedium">
                      Work: {userData.work}
                    </Text>
                  )}
                </AccordionPanel>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
      <Pagination
        defaultCurrent={1}
        current={getDefaultPage()}
        total={dataCount}
        pageSize={COUNTER_PER_ACTIVITY_LOGS}
        onChange={handlePagination}
        showLessItems
        prevIcon={ArrowLeft}
        nextIcon={ArrowRightIcon}
        showPrevNextJumpers={true}
        style={{ marginTop: "20px" }}
      />
    </Flex>
  );
}
