import { Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CenterButtonMainMenuIcon, HomeMainMenuIcon } from "components/Icons";
import { QrModal } from "components/QrModal";
import { NotificationButton } from "components/NotificationButton/NotificationButton";
import { Notification } from "components/Notification/Notification";
import { PATHS } from "constants/constants";
import { useNotificationsContext, useCurrentUserContext } from "hooks";
import CHS from "./chakra.styles";

export function FooterCab({ setScrollContainer }) {
  const navigate = useNavigate();
  const { notifications } = useNotificationsContext();

  const watchedNotifications = notifications?.filter(
    (notification) => !notification?.isWatched
  );

  const { isOpen, onToggle, onClose } = useDisclosure();

  const { currentUser } = useCurrentUserContext();

  const handleOnToggle = () => {
    onToggle();
    setScrollContainer((prev) => !prev);
  };

  const handleOnClose = () => {
    onClose();
    setScrollContainer(false);
  };

  const {
    isOpen: isOpenNotification,
    onToggle: onToggleNotification,
    onClose: onCloseNotification,
  } = useDisclosure();

  const handleOnToggleNotification = () => {
    setScrollContainer(true);
    onToggleNotification();
  };

  const handleOnCloseNotification = () => {
    setScrollContainer(false);
    onCloseNotification();
  };

  const handleHomeBtn = async () => {
    await onClose();
    await onCloseNotification();
    navigate(PATHS.cab);
  };

  return (
    <>
      <Flex sx={CHS.wrapper}>
        <IconButton
          onClick={handleHomeBtn}
          colorScheme="brand"
          aria-label="HomeMainMenuIcon"
          icon={
            <HomeMainMenuIcon
              width="6"
              height="6.5"
              fill="white.900"
              stroke="white.900"
            />
          }
        />
        {!currentUser?.isAdmin && (
          <IconButton
            onClick={handleOnToggle}
            sx={CHS.centerBtn}
            variant="outline"
            colorScheme="brand"
            aria-label="CenterButtonMainMenuIcon"
            icon={
              <CenterButtonMainMenuIcon
                width="7.5"
                height="7.5"
                fill="brand"
                stroke="brand"
              />
            }
          />
        )}
        <NotificationButton
          onToggle={handleOnToggleNotification}
          watchedNotifications={watchedNotifications}
        />
      </Flex>
      <QrModal isOpen={isOpen} onClose={handleOnClose} />
      <Notification
        isOpen={isOpenNotification}
        onClose={handleOnCloseNotification}
        watchedNotifications={watchedNotifications}
      />
    </>
  );
}
