import { Button as ChakraButton } from "@chakra-ui/react";

function Button(props) {
  const { children, type, ...rest } = props;

  return (
    <ChakraButton type={type} {...rest}>
      {children}
    </ChakraButton>
  );
}

export default Button;
