import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function QrCodeModal({ isOpen, onClose, data }) {
  const { t } = useTranslation();

  return (
    <Modal
      variant="transparent"
      size="transparent"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("app.qr__code")}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Flex bgColor="white.900">
            <Image src={data?.url?.qrCodePng} w="full" />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
