export function setTokenWithExpiry(token, hours) {
  const now = new Date();
  const expiryTime = now.getTime() + hours * 60 * 60 * 1000; // time expire token
  localStorage.setItem("TOKEN", token);
  localStorage.setItem("TOKEN_EXPIRY", expiryTime);
}

export function checkToken() {
  const now = new Date();
  const tokenExpiry = +localStorage.getItem("TOKEN_EXPIRY");
  return now.getTime() <= tokenExpiry;
}
