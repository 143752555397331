export const transformEditUserProfile = (data, currentEmail, fullName) => {
  const phoneArr = data.phoneNumber.split("+");
  const formatPhoneNumber = phoneArr[1] ? phoneArr[1] : phoneArr[0];

  if (!data) return {};

  const reqObj = {
    iban: data.iban ? data.iban : null,
    work: data.work ? data.work : null,
    phoneNumber: `+${formatPhoneNumber}`,
  };

  if (data?.email !== currentEmail) {
    Object.assign(reqObj, { email: data.email });
  }

  if (
    (data?.photo && Object.keys(data?.photo)?.length > 1) ||
    data.photo === null
  ) {
    Object.assign(reqObj, { file: data.photo });
  }

  if (data.password) {
    Object.assign(reqObj, {
      passwordChange: {
        oldPassword: data.oldPassword,
        newPassword: data.password,
      },
    });
  }

  if (data.fullName !== fullName) {
    Object.assign(reqObj, {
      fullName: data.fullName,
    });
  }

  return reqObj;
};
