import React, { useEffect, useRef } from "react";
import { Box, Flex, Image, Slide, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNotificationsContext } from "hooks";
import { NOTIFICATIONS_TYPES, PAYMENT_SYSTEM_TYPES } from "constants/constants";
import withdrawIcon from "assets/img/withdraw-icon.png";
import appleIcon from "assets/img/apple-icon.png";
import visaIcon from "assets/img/visa-icon.png";
import mastercardIcon from "assets/img/mastercard-icon.png";
import googleIcon from "assets/img/google-icon.png";
import groupDefaultIcon from "assets/img/group-item-1.png";
import { getDateConvertToNotifyRow } from "utils/getDateConvertToNotifyRow";
import { useReadNotifications } from "graphql/hooks/useMutations";
import { GET_NOTIFICATION } from "graphql/queries";

import CHS from "./chakra.styles";
import { textSlice } from "../../utils/textSlice";

export function Notification({ isOpen, onClose, watchedNotifications }) {
  const ref = useRef(null);
  const { notifications } = useNotificationsContext();
  const { t } = useTranslation();
  const [readNotification] = useReadNotifications();
  const notificationsId = notifications?.map((el) => el.id);

  const arrayCreateAt = notifications?.map((notify) => notify?.createdAt);
  const isHaveNotifications = notifications?.length > 0;

  const handleClose = async (e) => {
    if (e.target === ref.current) {
      onClose();
    }
  };
  useEffect(() => {
    return () => {
      if (isOpen && watchedNotifications?.length > 0) {
        readNotification({
          variables: {
            notificationsId,
          },
          refetchQueries: [GET_NOTIFICATION],
        });
      }
    };
  }, [isOpen]);

  return (
    <Slide
      direction="bottom"
      in={isOpen}
      style={{
        zIndex: 3,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box sx={CHS.dropMenu} onClick={handleClose} ref={ref}>
        <Box sx={CHS.modal}>
          <Box sx={CHS.toMozillaScrollWrapper}>
            <Box sx={CHS.modalBody}>
              {isHaveNotifications
                ? notifications?.map((notify, index) => {
                    return (
                      <React.Fragment key={notify?.id}>
                        <NotifyRow
                          type={notify?.text}
                          title={notify?.name}
                          subTitle={notify?.text}
                          link={notify?.addingToGroup?.group?.name}
                          pointBool={!notify?.isWatched}
                          group={notify?.addingToGroup?.group}
                          transaction={notify?.creditingToBalance?.value}
                          // paySystem={notify?.paymentSystem}
                          createdAt={notify?.createdAt}
                          prevAt={arrayCreateAt[index - 1]}
                        />
                      </React.Fragment>
                    );
                  })
                : t("app.list_of_notifications_is_empty")}
            </Box>
          </Box>
        </Box>
      </Box>
    </Slide>
  );
}

export function NotifyRow({
  type = "",
  title = "",
  subTitle = "",
  link = "",
  pointBool = false,
  group = "",
  transaction = "",
  paySystem = "",
  createdAt,
  prevAt,
}) {
  const getPaymentIcon = (paymentSystem) => {
    switch (paymentSystem) {
      case PAYMENT_SYSTEM_TYPES.APPLE_PAY:
        return appleIcon;
      case PAYMENT_SYSTEM_TYPES.GOOGLE_PAY:
        return googleIcon;
      case PAYMENT_SYSTEM_TYPES.VISA:
        return visaIcon;
      case PAYMENT_SYSTEM_TYPES.MASTERCARD:
        return mastercardIcon;
      default:
        return withdrawIcon;
    }
  };

  const castToString = (date) => new Date(+date).toDateString();

  const isEquality = castToString(prevAt) === castToString(createdAt);

  return (
    <>
      {!isEquality && (
        <Flex
          alignItems="center"
          justifyContent="center"
          mb={2.5}
          sx={CHS.topRowAbsolute}
        >
          <Text size="notify2xs">{getDateConvertToNotifyRow(createdAt)}</Text>
        </Flex>
      )}
      <Flex sx={CHS.rowWrap}>
        <Flex sx={!pointBool ? CHS.row : CHS.todayBlock}>
          <Box sx={CHS.iconBlock}>
            {group && (
              <Image
                src={group?.avatarUrl ? group?.avatarUrl : groupDefaultIcon}
                alt="gr"
                sx={CHS.img}
                objectFit="cover"
              />
            )}
            {type === NOTIFICATIONS_TYPES.WITHDRAW && (
              <Image src={withdrawIcon} alt="wd" sx={CHS.img} />
            )}
            {transaction && (
              <Image src={getPaymentIcon(paySystem)} alt="tr" sx={CHS.img} />
            )}
            {!group &&
              type !== NOTIFICATIONS_TYPES.WITHDRAW &&
              !transaction && (
                <Image src={withdrawIcon} alt="wd" sx={CHS.img} />
              )}
          </Box>
          <Box sx={CHS.content}>
            <Box>
              <Text size="notifyXs" variant="standard">
                {type === NOTIFICATIONS_TYPES.ADDED_TO_GROUP
                  ? group?.owner?.fullName
                  : title}
              </Text>
            </Box>
            <Box>
              <Flex>
                <Text size="notify2xs">{subTitle}</Text>
                <Text size="notify2xs" sx={CHS.link}>
                  {textSlice({
                    text: link,
                    count: 13,
                    space: true,
                  })}
                </Text>
              </Flex>
            </Box>
          </Box>
          <Box h={10}>
            {transaction && (
              <Flex>
                {type === NOTIFICATIONS_TYPES.WITHDRAW ? (
                  <Flex sx={CHS.badgeBlock} bgColor="yellow.100">
                    <Text size="notifyXsInterMedium" variant="standard">
                      {`-${transaction}€`}
                    </Text>
                  </Flex>
                ) : (
                  <Flex sx={CHS.badgeBlock} bgColor="white.300">
                    <Text size="notifyXsInterMedium" variant="brand">
                      {`+${transaction}€`}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
