import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ArrowIcon } from "components/Icons";
import { WithdrawModal } from "components/Modals/Withdraw";
import { useUtilsContext } from "../../context/utilsContext/UtilsContext";

import CHS from "./chakra.styles";

export function MyBalance({ balance = 0, refetchTransactions }) {
  const { isOpenWithdrawModal, onOpenWithdrawModal, onCloseWithdrawModal } =
    useUtilsContext();

  const { t } = useTranslation();

  const showWithdrawModal = () => {
    onOpenWithdrawModal();
  };

  return (
    <>
      <Box sx={CHS.wrap} onClick={showWithdrawModal}>
        <Text sx={CHS.title}>{t("app.my_balance")}</Text>
        <Text sx={CHS.balance}>
          {(String(balance?.balance)?.includes(".") &&
            Number(balance?.balance).toFixed(2)) ||
            balance?.balance}{" "}
          €
        </Text>
        <Flex sx={CHS.link}>
          <Text>{t("app.withdraw")}</Text>
          <ArrowIcon w="5" h="5" fill="white.900" />
        </Flex>
      </Box>
      <WithdrawModal
        isOpen={isOpenWithdrawModal}
        onClose={onCloseWithdrawModal}
        balance={balance}
        refetchTransactions={refetchTransactions}
      />
    </>
  );
}
