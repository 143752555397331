export default {
  wrapper: {
    minHeight: "100vh",
    height: "full",
    width: "full",
  },
  imageBlock: {
    width: {
      base: "100%",
      lg: "50%",
    },

    backgroundPosition: {
      base: "center 70px",
      md: "top center",
    },
    backgroundRepeat: "no-repeat",
    backgroundColor: "blue.100",
  },
  rightBlock: {
    width: "50%",
    minWidth: "550px",
  },
  rightBlockMobile: {
    position: "absolute",
    width: "90%",
    background: "white.900",
    top: 25,
    left: "50%",
    transform: "translate(-50%, 0px)",
    backgroundColor: "transparent",
    display: {
      base: "flex",
      lg: "block",
    },
    justifyContent: "center",
  },
  headerWrapperMobile: {
    position: "absolute",
    left: 0,
    top: "-25",
    width: "100vw",
    marginLeft: "-5.5%",
  },

  headerWrapperDesktop: {
    "> div": {
      px: "8.5",
      backgroundColor: "white.900",
    },
  },
  container: {
    px: {
      base: 4,
      lg: 0,
    },
    py: {
      base: 4,
      lg: 0,
    },
    display: "flex",
    width: {
      base: "auto",
      lg: "100%",
    },
    maxWidth: {
      base: "550px",
      md: "100%",
    },
    height: "calc(100% - 120px)",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white.900",
    borderRadius: "base",
  },
  spacer: {
    height: "12.5",
    marginBottom: "-11",
    background: "transparent",
  },
  layoutCabWrapper: {
    backgroundColor: "grey.400",
    justifyContent: "center",
  },
  layoutCabInt: {
    backgroundColor: "grey.250",
    width: "full",
    maxWidth: "110",
    minHeight: "100vh",
    boxShadow: "0px 20px 20px 20px rgb(1 9 108 / 75%)",
  },
  paymentLayout: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    backgroundColor: "grey.250",
    minHeight: "100vh",
  },
};
