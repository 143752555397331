import { gql } from "@apollo/client";

export const CONFIRM_WITHDRAWAL_APPLICATION = gql`
  mutation confirmWithdrawalApplication(
    $innerTransactionId: Int!
    $accountServicerReference: String!
  ) {
    confirmWithdrawalApplication(
      innerTransactionId: $innerTransactionId
      accountServicerReference: $accountServicerReference
    )
  }
`;
