import { gql } from "@apollo/client";

export const EDIT_GROUP = gql`
  mutation editGroupProfile($groupId: Int!, $input: GroupEditingInput!) {
    editGroupProfile(groupId: $groupId, input: $input) {
      id
      name
      type
      avatarUrl
      groupRoles {
        id
        groupId
        role
      }
    }
  }
`;
