export default {
  entityList: {
    flexWrap: "wrap",
    py: "4",
    gap: "2",
  },

  entityItem: {
    maxWidth: "100%",
    py: "1",
    pl: "3",
    pr: "2",
    alignItems: "center",
    bgColor: "grey.200",
    borderRadius: "md",
    overflow: "hidden",
  },

  entityItemText: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontSize: "md",
    color: "black.900",
  },

  closeIcon: {
    w: "3",
    h: "3",
    ml: "2",
    fill: "brand",

    _hover: {
      cursor: "pointer",
      fill: "red.500",
    },
  },
};
