export default {
  default: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "md",
  },
  target: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "md",
    color: "white.900",
    background: "primary",
  },
};
