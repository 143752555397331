export default {
  variants: {
    base: {
      field: {
        borderColor: "transparent",
        borderRadius: "base",
        fontWeight: "medium",
        fontSize: "xs",
        lineHeight: "5",
        height: "37px",
        width: "130px",
        paddingTop: "0",
        textAlign: "inherit",
        pl: "2.5",
        _focus: {
          borderWidth: "0.5",
          borderStyle: "solid",
          borderColor: "brand",
        },
      },
    },
  },
  defaultProps: {
    variant: "base",
  },
};
