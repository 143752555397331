import { gql } from "@apollo/client";

export const CONFIRM_NEW_EMAIL = gql`
  mutation confirmNewEmailOfUser($confirmationCode: String!) {
    confirmNewEmailOfUser(confirmationCode: $confirmationCode) {
      id
      fullName
      email
      phoneNumber
      work
      iban
      avatarUrl
      groupInvitations {
        groupId
        email
        group {
          id
          name
          owner {
            fullName
          }
        }
      }
    }
  }
`;
