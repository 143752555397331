export default {
  itemWrap: {
    position: "relative",
    borderRadius: "lg",
    bgColor: "white.900",
    px: "4",
    py: "4",
    w: "full",

    _after: {
      position: "absolute",
      content: "''",
      display: "block",
      bottom: "0",
      right: "4",
      left: "4",
      h: "1px",
      bgColor: "grey.500",
      opacity: "0.4",
    },
  },

  item: {
    justifyContent: "space-between",
    alignItems: "center",
  },

  itemImg: {
    minW: "11",
    w: "11",
    h: "11",
    borderRadius: "lg",
    overflow: "hidden",
  },

  smallText: {
    fontSize: "2xs",
    lineHeight: "5",
  },

  percent: {
    w: "10",
    h: "10",
    fontSize: "xs",
    borderRadius: "lg",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "grey.300",
  },

  percentIcon: {
    pointerEvents: "none",
    width: "auto",
    paddingRight: "5px",
  },
};
