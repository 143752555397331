export const textSlice = ({ text = "", count = 50, space = false }) => {
  const textLength = text?.length || 0;
  const hasSpace = text?.trim()?.includes(" ");

  if (space && hasSpace) {
    const parts = text?.split(" ");
    if (parts?.every((part) => part?.length <= count)) {
      return text;
    }
  }

  if (textLength >= count) {
    const slicedText = text?.slice(0, count);
    const ellipsis = "...";
    if (slicedText) {
      return slicedText + ellipsis;
    }
  }

  return text;
};
