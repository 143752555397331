export default {
  itemWrap: {
    borderRadius: "lg",
    bgColor: "white.900",
    px: "3.75",
    py: "3.75",
    w: "full",
  },

  item: {
    justifyContent: "space-between",
    alignItems: "center",
  },

  itemImg: {
    w: "53px",
    h: "53px",
    borderRadius: "lg",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "53px",
  },

  title: {
    display: "flex",
    alignItems: "center",
  },

  trash: {
    w: "5",
    h: "5",
    marginLeft: "12px",
    fill: "none",
    stroke: "dark.100",
    _hover: { stroke: "red.500", cursor: "pointer" },
  },
  eye: {
    w: "5",
    h: "5",
    marginRight: "12px",
    fill: "dark.100",
    stroke: "dark.100",
    _hover: { stroke: "brand", cursor: "pointer" },
  },
  eye2: {
    w: "5",
    h: "5",
    fill: "dark.100",
    stroke: "dark.100",
    _hover: { stroke: "brand", cursor: "pointer" },
  },

  redact: {
    w: "4.5",
    h: "5",
    fill: "dark.100",
    _hover: { fill: "brand", cursor: "pointer" },
  },
};
