export default {
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    backgroundColor: "blue.100",
    px: {
      sm: "none",
      md: "none",
      lg: "52",
    },
    paddingTop: {
      base: "10",
      md: "15",
    },
    paddingBottom: {
      base: "10",
      md: "5",
    },
  },
  col: {
    height: "full",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  linksBlock: {
    display: "flex",
    justifyContent: "space-between",
    width: "full",
    maxWidth: "817px",
  },
  linksBlockMobile: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    rowGap: 3,
    textAlign: "center",
  },
};
