import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { LogoIcon } from "components/Icons/LogoIcon";
import { ErrorIcon } from "components/Icons";
import { useEffect } from "react";
import { useCancelPayment } from "graphql/hooks/useMutations";
import CHS from "./chakra.styles";

export function PaymentCanceled() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");

  const [cancelPayment] = useCancelPayment();

  useEffect(() => {
    cancelPayment({
      variables: {
        stripeId: sessionId,
      },
    });
  }, []);

  return (
    <Flex sx={CHS.wrapper}>
      <LogoIcon width="40" height="18" />
      <Flex sx={CHS.logoBlock}>
        <Text size="exLarge" variant="brand">
          {t("app.payment_canceled")}
        </Text>
        <ErrorIcon width="32" height="32" />
      </Flex>
      <Box>
        <Text>{t("app.link_to_our_site")}:</Text>
        <Link style={{ color: "#2C37B2" }} to={process.env.REACT_APP_DOMAIN}>
          {process.env.REACT_APP_DOMAIN}
        </Link>
      </Box>
    </Flex>
  );
}
