import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { DEFAULT_TOKEN_LIFETIME, PATHS } from "constants/constants";
import { useSignIn } from "graphql/hooks/useQueries";
import { useAuthContext, useGetIpHook } from "hooks";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { AuthTitleBlock } from "components/AuthTitleBlock/AuthTitleBlock";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validation";
import CHS from "./chakra.styles";

const INITIAL_VALUES_LOGIN = {
  email: "",
  password: "",
};

export default function Login() {
  const [formSending, setFormSending] = useState(false);
  const { t } = useTranslation();
  const { login } = useAuthContext();
  const [err, setErr] = useState("");
  const [signIn] = useSignIn();
  const { ip } = useGetIpHook();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUES_LOGIN,
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    setFormSending(true);
    signIn({
      variables: {
        email: data.email,
        password: data.password,
      },
      context: {
        headers: {
          "user-ip": ip || "",
        },
      },
      onCompleted: (response) => {
        setFormSending(false);
        reset(INITIAL_VALUES_LOGIN);
        login(response?.signin?.Authorization, DEFAULT_TOKEN_LIFETIME);
      },
      onError: ({ graphQLErrors }) => {
        setFormSending(false);
        if (!graphQLErrors) return;
        if (
          graphQLErrors[0]?.message === "Wrong password" ||
          graphQLErrors[0]?.message === "Email not found"
        ) {
          setErr(t("app.wrong_password_or_email"));
        } else {
          setErr(graphQLErrors[0]?.message);
        }
      },
    });
  };

  return (
    <Box>
      <AuthTitleBlock
        title={t("app.sign_in")}
        subTitle={t(
          "app.receive_send_and_manage_tips_directly_touchless_and_cashless"
        )}
        mb={5}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldRHF
          mb={5}
          register={register}
          name="email"
          label={t("app.e_mail")}
          placeholder={t("app.enter_your_email")}
          hasError={errors?.email}
          errorMessage={errors.email?.message}
        />
        <TextFieldRHF
          mb={{ base: 5, md: 10 }}
          register={register}
          type="password"
          name="password"
          label={t("app.password")}
          placeholder={t("app.enter_your_password")}
          hasError={errors?.password}
          errorMessage={errors.password?.message}
        />
        {err ? <Box sx={CHS.customError}>{err}</Box> : ""}
        <Button
          size="registerButton"
          mb={2.5}
          type="submit"
          disabled={formSending}
        >
          {t("app.sign_in_now")}
        </Button>
        <Box sx={CHS.linkBlock}>
          <Link to={PATHS.forgotPassword}>{t("app.forgot_your_password")}</Link>
        </Box>
      </form>
    </Box>
  );
}
