export default {
  customItem: {
    alignItems: "center",
    fontFamily: "mediumInter",
    fontSize: "md",
    fontWeight: "medium",

    svg: {
      mr: "5",
    },
  },

  menuIcon: {
    w: "2",
    h: "3.5",
    fill: "black.900",

    _groupHover: {
      fill: "brand",
    },
  },
};
