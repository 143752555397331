import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export const UtilsContext = createContext();
export const useUtilsContext = () => {
  const context = useContext(UtilsContext);
  if (!context) {
    throw new Error("useUtilsContext must be used within a UtilsProvider");
  }
  return context;
};

function UtilsProvider({ children }) {
  const {
    isOpen: isOpenWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const {
    isOpen: isOpenGroupModal,
    onOpen: onOpenGroupModal,
    onClose: onCloseGroupModal,
  } = useDisclosure();

  const {
    isOpen: addWorkerIsOpen,
    onOpen: addWorkerOnOpen,
    onClose: addWorkerOnClose,
  } = useDisclosure();
  const {
    isOpen: groupListItemIsOpen,
    onOpen: groupListItemOnOpen,
    onClose: groupListItemOnClose,
  } = useDisclosure();
  const [groupId, setGroupId] = useState();

  const location = useLocation();

  const handleCloseAllModal = () => {
    onCloseWithdrawModal();
    onCloseGroupModal();
    addWorkerOnClose();
    groupListItemOnClose();
  };

  useEffect(() => {
    handleCloseAllModal();
  }, [location]);

  window.onpopstate = () => {
    handleCloseAllModal();
  };

  const value = useMemo(
    () => ({
      isOpenWithdrawModal,
      onOpenWithdrawModal,
      onCloseWithdrawModal,
      isOpenGroupModal,
      onOpenGroupModal,
      onCloseGroupModal,
      addWorkerIsOpen,
      addWorkerOnOpen,
      addWorkerOnClose,
      groupListItemIsOpen,
      groupListItemOnOpen,
      groupListItemOnClose,
      groupId,
      setGroupId,
    }),
    [
      isOpenWithdrawModal,
      isOpenGroupModal,
      addWorkerIsOpen,
      groupListItemIsOpen,
      groupId,
    ]
  );
  return (
    <UtilsContext.Provider value={value}>{children}</UtilsContext.Provider>
  );
}

export default UtilsProvider;
