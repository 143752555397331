import { Icon } from "@chakra-ui/react";

export function TriangleSel({ props }) {
  return (
    <Icon viewBox="0 0 10 7" fill="none" w={2} h={1.75} {...props}>
      <path
        d="M4.256 6.17159C4.65334 6.61401 5.34666 6.61401 5.744 6.17159L9.56398 1.91818C10.1421 1.27442 9.68526 0.25 8.81998 0.25H1.18001C0.31474 0.25 -0.142143 1.27442 0.436019 1.91818L4.256 6.17159Z"
        fill="#2C37B2"
      />
    </Icon>
  );
}
