export default {
  text: {
    fontSize: "md",
    lineHeight: "6",
    letterSpacing: "0.01em",
  },

  option: {
    alignItems: "center",
    gap: 4.75,
    cursor: "pointer",
  },
  settingsBlock: {
    flexDirection: "column",
    rowGap: 7,
  },
};
