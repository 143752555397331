import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
  mutation createGroup($input: CreatingGroupInput!, $file: Upload) {
    createGroup(input: $input, file: $file) {
      id
      name
      type
      avatarUrl
      groupRoles {
        id
        groupId
        role
        groupMembers {
          groupId
          userId
          roleId
          tipPercentage
          createdAt
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;
