export const transformSignUp = (data) => {
  if (data) {
    return {
      fullName: data.fullName,
      phoneNumber: `+${data.phone}`,
      email: data.email,
      password: data.password,
      confirmationPassword: data.confirmPassword,
    };
  }
  return {};
};
