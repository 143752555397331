export default {
  dropMenu: {
    backgroundColor: "brand",
    height: "full",
    color: "white.900",
    pl: "5",
    pr: "4",
    pt: "5",
    pb: "7.5",
    maxWidth: "110",
  },
  introBlock: {
    justifyContent: "space-between",
    mb: "15",
  },
  personBlock: {
    flexDirection: "column",
    gap: 1.25,
  },
  text: {
    fontSize: "md",
    lineHeight: "6",
    letterSpacing: "0.01em",
  },
  profession: {
    fontSize: "xs",
    lineHeight: "5",
    letterSpacing: "0.01em",
    color: "grey.450",
  },
  option: {
    alignItems: "center",
    gap: 4.75,
  },
  settingsBlock: {
    flexDirection: "column",
    rowGap: 7,
  },
  logOutBlock: {
    position: "fixed",
    bottom: 7.5,
    gap: 5,
    cursor: "pointer",
  },
  lang: {
    cursor: "pointer",
    fontSize: "md",
  },
  line: {
    borderWidth: 1,
    borderStyle: "solid",
  },
};
