export default {
  normal: "normal",
  none: 1,
  shorter: 1.28,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: "2",
  3: ".75rem", // 12px
  4: "1rem", // 16px
  5: "1.125rem", // 18px
  6: "1.375rem", // 22px
  7: "1.5rem", // 24px
  8: "1.75rem", // 28px
  9: "1.875", // 30px
  10: "2rem", // 32px
  11: "2.25rem", // 36px
  12: "2.5rem", // 40px
  13: "2.75rem", // 44px
  14: "3rem", // 48
  15: "3.25rem", // 52px
  16: "3.5rem", // 56px
  17: "3.75rem", // 60px
  18: "3.875", // 62px
  19: "5.25rem", // 84px
};
