import { useMemo, useState } from "react";
import { useAuthContext } from "hooks";
import CurrentUserContext from "context/currentUser/currentUser.context";
import { useGetUser } from "graphql/hooks/useQueries";

function CurrentUserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const { isAuth } = useAuthContext();

  const { refetch } = useGetUser({
    onCompleted: (response) => {
      setCurrentUser(response?.getUser);
    },
    skip: !isAuth,
  });

  const updateUser = (user) => {
    setCurrentUser((prevState) => ({ ...prevState, ...user }));
  };

  const userData = useMemo(
    () => ({
      currentUser,
      updateUser,
      refetch,
    }),
    [currentUser]
  );

  return (
    <CurrentUserContext.Provider value={userData}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserProvider;
