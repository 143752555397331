export default {
  table: {
    backgroundColor: "white.900",
    borderRadius: "lg",
    w: "full",
    p: "3.75",
    flexDirection: "column",
  },
  emptyList: {
    px: "3.75",
    py: "3.75",
    borderRadius: "lg",
    bgColor: "white.900",
  },
};
