export default {
  maskWrapper: {
    flexDirection: "column",
    w: "full",
  },
  maskLabel: {
    fontStyle: "md",
    fontSize: "md",
    color: "black.900",
    marginBottom: 3,
  },
  maskFlushedLabel: {
    fontStyle: "md",
    fontSize: "2xs",
    color: "grey.500",
    marginBottom: "-3",
  },
  errorBlock: {
    position: "absolute",
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "red.500",
    borderRadius: "xs",
    bottom: 0,
    width: "full",
    zIndex: 11,
    height: "15",
    pointerEvents: "none",
  },
  errorFlushedBlock: {
    position: "absolute",
    borderBottom: "2px solid",
    borderBottomColor: "red.500",
    bottom: 0,
    width: "full",
    zIndex: 11,
    pointerEvents: "none",
  },
  errorM: {
    color: "red.500",
    mt: 2,
  },
};
