import { gql } from "@apollo/client";

export const INVITE_WORKER_TO_GROUP = gql`
  mutation inviteWorkerToGroup($groupId: Int!, $email: String!) {
    inviteWorkerToGroup(groupId: $groupId, email: $email) {
      success
      numberOfUsedInvitations
      totalInvitations
      delayBeforeNextSend
    }
  }
`;
