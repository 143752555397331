import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ACCESS, PATHS } from "constants/constants";
import { routes } from "constants/routes";
import PrivateRoute from "navigation/PrivateRoute";
import PublicRoute from "navigation/PublicRoute";

const renderRoutes = (_routes) => {
  return _routes.map((route) => {
    const { path, access, layout: Layout, component: Component } = route;
    return (
      <Route
        key={path}
        path={path}
        element={
          access === ACCESS.public ? (
            <PublicRoute>
              <Layout>
                <Component />
              </Layout>
            </PublicRoute>
          ) : (
            <PrivateRoute>
              <Layout>
                <Component />
              </Layout>
            </PrivateRoute>
          )
        }
      />
    );
  });
};

export default function Navigation() {
  return (
    <Routes>
      {renderRoutes(routes)}
      <Route path="*" element={<Navigate replace to={PATHS.solo} />} />
    </Routes>
  );
}
