import { Box, Flex, Text, Button, useMediaQuery } from "@chakra-ui/react";
import { useConfirmEmail } from "graphql/hooks/useMutations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorIcon } from "components/Icons";
import { PATHS } from "constants/constants";
import { useGetIpHook } from "hooks";

export function ConfirmEmail() {
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSmallThan530] = useMediaQuery("(min-width: 530px)");
  const { token } = useParams();
  const { ip } = useGetIpHook();

  const [confirmEmail, { loading, error }] = useConfirmEmail({
    variables: {
      token,
    },
    context: {
      headers: {
        "user-ip": ip || "",
      },
    },
    skip: !token,
    onCompleted: () => {
      setIsSuccess(true);
    },
    onError: ({ message }) => {
      console.error(message);
    },
  });

  useEffect(() => {
    confirmEmail();
  }, []);

  return (
    <Box>
      <Flex sx={{ textAlign: "center", flexDirection: "column", gap: 10 }}>
        {loading && (
          <Text size={isSmallThan530 ? "exLarge" : "big"} variant="standard">
            {t("app.completion_of_registration")}
          </Text>
        )}
        {isSuccess && (
          <Box>
            <Text size={isSmallThan530 ? "exLarge" : "big"} variant="success">
              {t("app.success")}!
            </Text>
            <Text size={isSmallThan530 ? "exLarge" : "big"} variant="standard">
              {t("app.you_have_successfully_verified_your_email")}
            </Text>
            <Button
              onClick={() => navigate(PATHS.login)}
              variant="brandSolidRounded"
              mt="4"
              w="50"
              h="14"
            >
              {t("app.go_to_login")}
            </Button>
          </Box>
        )}
        {error && !isSuccess && (
          <>
            <Box>
              <Text
                size={isSmallThan530 ? "exLarge" : "big"}
                variant="standard"
              >
                {t("app.sorry")}
              </Text>
              <Text
                mt={2}
                size={isSmallThan530 ? "exLarge" : "big"}
                variant="standard"
              >
                {error.message}
              </Text>
            </Box>
            <Box>
              <ErrorIcon w={20} h={20} />
            </Box>
            <Text
              mt={2}
              size={isSmallThan530 ? "exLarge" : "big"}
              variant="standard"
            >
              {t("app.please_confirm_your_email")}
            </Text>
          </>
        )}
      </Flex>
    </Box>
  );
}
