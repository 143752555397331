import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Heading,
  Text,
  VStack,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { useUtilsContext } from "context/utilsContext/UtilsContext";

import { PlusCabIcon } from "components/Icons";
import Button from "components/Button/Button";
import { GroupListItem } from "components/GroupsList/GroupListItem";
import { useDeleteGroup } from "graphql/hooks/useMutations";
import { AlertModal } from "components/AlertModal";
import { CreateGroupModal } from "components/Modals/CreateGroup";
import { useGetGroups } from "graphql/hooks/useQueries";
import { GET_GROUPS } from "graphql/queries";
import CHS from "./chakra.styles";

export function GroupList() {
  const [deleteGroupItem, setDeleteGroupItem] = useState();
  const [editGroupItem, setEditGroupItem] = useState();

  const { t } = useTranslation();

  const {
    isOpen: isOpenDeleteGroupAlert,
    onOpen: onOpenDeleteGroupAlert,
    onClose: onCloseDeleteGroupAlert,
  } = useDisclosure();

  const { isOpenGroupModal, onOpenGroupModal, onCloseGroupModal } =
    useUtilsContext();

  const [deleteGroup] = useDeleteGroup();

  const { data } = useGetGroups();

  const createGroupHandler = () => {
    setEditGroupItem(false);
    onOpenGroupModal();
  };

  const editGroupHanlder = (id) => {
    const group = data?.getGroups?.find((item) => item.id === id);
    setEditGroupItem(group);
    onOpenGroupModal();
  };

  const showDeleteGroupAlert = (id) => {
    const group = data?.getGroups?.find((item) => item.id === id);
    onOpenDeleteGroupAlert();
    setDeleteGroupItem(group);
  };

  const deleteGroupHandler = () => {
    deleteGroup({
      variables: {
        id: deleteGroupItem.id,
      },
      onCompleted: (response) => {
        console.log(response);
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [GET_GROUPS],
    });

    onCloseDeleteGroupAlert();
  };

  return (
    <>
      <Flex sx={CHS.header}>
        <Heading variant="cabHeadBox">{t("app.my_group")}</Heading>
        {data?.getGroups?.length > 0 && (
          <Button
            variant="cabBtn"
            size="cabIconBtn"
            onClick={createGroupHandler}
          >
            <PlusCabIcon w="3" h="3" fill="brand" />
          </Button>
        )}
      </Flex>
      {!data?.getGroups?.length ? (
        <Box sx={CHS.emptyList}>
          <Text mb="2.5">{t("app.you_are_not_on_the_team_yet")}</Text>
          <Button variant="cabBtn" onClick={createGroupHandler}>
            +{t("app.create_group")}
          </Button>
        </Box>
      ) : (
        <VStack spacing="1.5" w="full">
          {data?.getGroups?.map((item) => (
            <React.Fragment key={item.id}>
              <GroupListItem
                key={item.id}
                item={item}
                deleteHandler={showDeleteGroupAlert}
                editHandler={editGroupHanlder}
              />
            </React.Fragment>
          ))}
        </VStack>
      )}

      {isOpenDeleteGroupAlert && (
        <AlertModal
          isOpen={isOpenDeleteGroupAlert}
          onClose={onCloseDeleteGroupAlert}
          isClosed
          isMoreButtons
          funcLeftBtn={onCloseDeleteGroupAlert}
          funcRightBtn={deleteGroupHandler}
        >
          <Heading variant="cabHeadPage" lineHeight="11">
            {`${t("app.you_really_want_to_delete")} `}
            <Text size="linkText" variant="brand">
              {deleteGroupItem?.name}
            </Text>
            {` ${t("app.group")}`}
          </Heading>
        </AlertModal>
      )}

      {isOpenGroupModal && (
        <CreateGroupModal
          isOpen={isOpenGroupModal}
          onClose={onCloseGroupModal}
          isEditMode={!!editGroupItem}
          editedGroup={editGroupItem}
        />
      )}
    </>
  );
}
