import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Flex,
  Text,
  useDisclosure,
  Avatar,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { GroupMemberItem } from "components/GroupMemberItem";
import { getTipsPercentage, getRolesArrayForMenu } from "utils/groupUtils";
import {
  useDistributeTipPercentage,
  useLeaveGroup,
} from "graphql/hooks/useMutations";
import { transformDistributePercentage } from "connectors";
import { GET_GROUPS } from "graphql/queries";
import { HeaderCab } from "components/HeaderCab";
// import Button from "components/Button/Button";
import { AddWorkerModal } from "components/Modals/AddWorker";
import { AlertModal } from "components/AlertModal";
import { useUtilsContext } from "context/utilsContext/UtilsContext";
import { ModalTitle } from "components/Modals/ModalTitle";
import { useTranslation } from "react-i18next";
import { useCurrentUserContext } from "hooks";
import { getSortAryByOwner } from "utils/getSortByOwner";
import CHS from "./chakra.styles";
import { textSlice } from "../../../utils/textSlice";

export function GroupModal({ isOpen, onClose, group }) {
  const [formSendingLeave, setFormSendingLeave] = useState(false);
  const [formSending, setFormSending] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const { t } = useTranslation();

  const [tipsError, setTipsError] = useState("");

  const { addWorkerIsOpen, addWorkerOnOpen, addWorkerOnClose } =
    useUtilsContext();

  const {
    id: groupId,
    name: groupName,
    owner: groupOwner,
    avatarUrl: groupAvatarUrl,
    groupRoles,
  } = group;

  const {
    isOpen: isOpenLeaveGroup,
    onOpen: onOpenLeaveGroup,
    onClose: onCloseLeaveGroup,
  } = useDisclosure();

  const [distributeTipPercentage] = useDistributeTipPercentage();
  const [leaveGroup] = useLeaveGroup();

  const addWorkerHandler = () => {
    addWorkerOnOpen();
  };

  const leaveGroupHandler = () => {
    setFormSendingLeave(true);
    leaveGroup({
      variables: {
        groupId,
      },
      onCompleted: () => {
        setFormSendingLeave(false);
        onCloseLeaveGroup();
        onClose();
      },
      onError: (error) => {
        setFormSendingLeave(false);
        console.log(error);
      },
      refetchQueries: [GET_GROUPS],
    });
  };

  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { isDirty },
  } = useForm();

  const onSubmit = (values) => {
    setFormSending(true);
    distributeTipPercentage({
      variables: transformDistributePercentage(values, groupId),
      onCompleted: () => {
        setFormSending(false);
        reset();
        onClose();
      },
      onError: (error) => {
        setFormSending(false);
        console.log(error);
      },
      refetchQueries: [GET_GROUPS],
    });
  };

  useEffect(() => {
    if (group) {
      reset(getTipsPercentage(groupRoles));
    }
  }, [group]);

  useEffect(() => {
    const subscription = watch((value) => {
      const isInvalidArr = Object.values(value).some(
        (num) => Number(num) < 0 || !Number.isInteger(+num)
      );

      const sum = Object.values(value).reduce(
        (a, b) => Number(a) + Number(b),
        0
      );

      if (isInvalidArr) {
        setTipsError(t("app.only_positive_integers_or_0_are_allowed"));
      } else if (!sum || sum !== 100) {
        setTipsError(`${t("app.the_total_must_be_100_current_amount")} ${sum}`);
      } else {
        setTipsError("");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader sx={CHS.header}>
              <HeaderCab />
              <Flex alignItems="center" justifyContent="space-between" p={4}>
                <ModalTitle
                  onClose={onClose}
                  title={textSlice({ text: groupName, count: 12, space: true })}
                />
                <Avatar
                  w={12.5}
                  h={12.5}
                  name={groupAvatarUrl}
                  src={groupAvatarUrl}
                />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <Text color="red.500" textAlign="right">
                {tipsError}
              </Text>
              {getSortAryByOwner(groupRoles)?.map((item) => {
                return (
                  item?.groupMembers?.length > 0 && (
                    <React.Fragment key={item?.id}>
                      <Flex sx={CHS.sectionWrap}>
                        <Heading variant="cabHeadBox" mb="5">
                          {item.role}
                        </Heading>
                        <VStack spacing="0" sx={CHS.list}>
                          {item?.groupMembers?.map((member) => (
                            <React.Fragment key={member?.createdAt}>
                              <GroupMemberItem
                                groupMember={member}
                                ownerId={groupOwner?.id}
                                name={String(member?.user.id)}
                                register={register}
                                hasError={!!tipsError}
                                roles={getRolesArrayForMenu(
                                  groupRoles,
                                  item?.role
                                )}
                                closeModal={onClose}
                              />
                            </React.Fragment>
                          ))}
                        </VStack>
                      </Flex>
                    </React.Fragment>
                  )
                );
              })}
              {currentUser?.id === groupOwner?.id ? (
                <>
                  <Button
                    variant="cabOutline"
                    size="cabBigModal"
                    onClick={addWorkerHandler}
                  >
                    {t("app.add_worker")}
                  </Button>
                  <Button
                    variant="cabSolid"
                    size="cabBigModal"
                    type="submit"
                    mt="2"
                    disabled={!isDirty || !!tipsError || formSending}
                  >
                    {t("app.save_changes")}
                  </Button>
                </>
              ) : (
                <Button
                  variant="cabSolid"
                  size="cabBigModal"
                  onClick={() => onOpenLeaveGroup()}
                >
                  {t("app.leave_group")}
                </Button>
              )}
            </ModalBody>
          </ModalContent>
        </form>
      </Modal>

      {isOpenLeaveGroup && (
        <AlertModal
          isOpen={isOpenLeaveGroup}
          onClose={onCloseLeaveGroup}
          isClosed
          isMoreButtons
          funcLeftBtn={onCloseLeaveGroup}
          funcRightBtn={leaveGroupHandler}
          disabled={formSendingLeave}
        >
          <Heading variant="cabHeadPage" lineHeight="11">
            {`${t("app.you_really_want_to_leave")} `}
            <Text size="linkText" variant="brand">
              {group?.name}
            </Text>
            {` ${t("app.group")}`}
          </Heading>
        </AlertModal>
      )}

      {addWorkerIsOpen && (
        <AddWorkerModal
          isOpen={addWorkerIsOpen}
          onClose={addWorkerOnClose}
          groupId={group?.id}
        />
      )}
    </>
  );
}
