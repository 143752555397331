import Dropzone from "components/Dropzone";
import { Controller } from "react-hook-form";

export function DropzoneFieldRHF({
  name,
  control,
  setValue,
  getValues,
  hasError,
  errorMessage,
  onChangeCallback,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        const handleChange = (file) => {
          // Кастыль для Firefox для загрузки файла из FileDialog
          setTimeout(() => {
            setValue(name, file, { shouldValidate: true });
            onChangeCallback?.(file);
          }, 0);
        };
        return (
          <Dropzone
            {...rest}
            hasError={hasError}
            errorMessage={errorMessage}
            value={getValues(name)}
            onChange={handleChange}
          />
        );
      }}
    />
  );
}
