export default {
  linkBlock: {
    color: "brand",
    textAlign: "center",
    fontSize: "md",
  },
  customError: {
    color: "red.500",
    mb: "4",
  },
};
