import { useEffect, useMemo, useState } from "react";
import { useCurrentUserContext } from "hooks";
import { useNotificationCreated } from "graphql/hooks/useSubscriptions";
import NotificationsContext from "context/notifications/notifications.context";
import { useGetNotification } from "graphql/hooks/useQueries";

function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const { currentUser } = useCurrentUserContext();

  const { refetch } = useGetNotification({
    onCompleted: ({ getNotifications }) => setNotifications(getNotifications),
  });

  const { data } = useNotificationCreated({
    skip: !currentUser?.id,
    variables: { userId: Number(currentUser?.id) },
  });

  useEffect(() => {
    if (data?.notificationCreated) {
      setNotifications((prevState) => [
        data?.notificationCreated,
        ...prevState,
      ]);
    }
  }, [data?.notificationCreated]);

  useEffect(() => {
    refetch();
  }, [currentUser?.id]);

  const notificationsData = useMemo(
    () => ({
      notifications,
    }),
    [notifications]
  );

  return (
    <NotificationsContext.Provider value={notificationsData}>
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsProvider;
