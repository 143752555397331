export const getFormatDate = (createdAt = new Date()) => {
  const lang = localStorage.getItem("i18nextLng");

  const getLocale = (iso) => {
    switch (iso) {
      case "ru":
        return "ru";
      case "en":
        return "en-US";
      case "ett":
        return "en-US";
      default:
        return "en-US";
    }
  };

  return new Intl.DateTimeFormat(getLocale(lang), {
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(createdAt);
};
