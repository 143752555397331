export default {
  wrapper: {
    backgroundColor: "blue.100",
    py: {
      base: "5",
      lg: "7",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: "full",
    justifyContent: "space-between",
  },
  dropMenu: {
    boxShadow: "0px 4px 44px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white.900",
    borderBottomRadius: "lg",
    borderTopRadius: "0",
    px: {
      base: "4",
      sm: "4",
    },
    py: {
      base: "5",
      sm: "5",
    },
  },
  dropMenuHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  horizontalLine: {
    height: "px",
    width: "full",
    border: "1px solid",
    borderColor: "grey.550",
    marginBottom: "5",
  },
  btn: {
    backgroundColor: "brand",
    color: "white.900",
    borderRadius: "12xl",
    fontSize: "md",
    fontWeight: "medium",
    paddingX: "10",
    paddingY: "2.5",
    height: 12,
  },
  linkText: {
    fontSize: "md",
    color: "black.900",
  },
};
