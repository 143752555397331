import { gql } from "@apollo/client";

export const NOTIFICATION_CREATED = gql`
  subscription Subscription($userId: Int!) {
    notificationCreated(userId: $userId) {
      id
      userId
      name
      text
      isWatched
      createdAt
      addingToGroup {
        groupId
        group {
          id
          name
          avatarUrl
          owner {
            fullName
          }
        }
      }
      creditingToBalance {
        value
      }
    }
  }
`;
