import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { SuccessIcon } from "components/Icons";
import { LogoIcon } from "components/Icons/LogoIcon";
import { useSuccessPayment } from "graphql/hooks/useMutations";
import { useEffect } from "react";
import { useTimerHook } from "hooks";
import CHS from "./chakra.styles";

export function PaymentSuccess() {
  const { t } = useTranslation();
  const [successPayment] = useSuccessPayment();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const { count } = useTimerHook();

  const pathName = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    if (!sessionId) return;
    successPayment({
      variables: {
        stripeId: sessionId,
      },
    });
  }, []);

  return (
    <Flex sx={CHS.wrapper}>
      <LogoIcon width="40" height="18" />
      <Flex sx={CHS.logoBlock}>
        <Text size="exLarge" variant="brand">
          {t("app.payment_success")}
        </Text>
        <SuccessIcon width="32" height="32" />
      </Flex>
      <Box>
        <Text align="center">
          {t("app.this_page_will_redirect", { count })}
        </Text>
        <Link style={{ color: "#2C37B2" }} to={pathName}>
          {pathName}
        </Link>
      </Box>
    </Flex>
  );
}
