export default {
  modalHead: {
    alignItems: "center",
  },
  wrap: {
    w: "full",
    p: "4",
    borderRadius: "lg",
    bgColor: "white.900",
  },

  balancWrap: {
    w: "full",
    justifyContent: "space-between",
  },

  balanceText: {
    fontFamily: "boldInter",
    lineHeight: "14",
    fontWeight: "bold",
    width: "max-content",
  },

  infoText: {
    mt: "2.5",
    textAlign: "center",
  },

  balanceBox: {
    position: "relative",
    w: "50%",
    maxWidth: "160px",
  },

  verticalLine: {
    opacity: "0.2",
    borderStyle: "solid",
    borderColor: "black.900",
    borderWidth: "1px",
  },

  withdrawBox: {
    w: "50%",
    maxWidth: "160px",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },

  ibanTitle: {
    fontFamily: "mediumInter",
    fontWeight: "medium",
    fontSize: "xl",
    lineHeight: "11",
  },
};
