import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  BusinessCardIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  QrIcon,
} from "components/Icons";
import { saveAs } from "file-saver";
import { RepeatIcon } from "@chakra-ui/icons";
import { CARD_TYPE } from "constants/constants";
import { BusinessCardModal } from "components/Modals/BusinessCard";
import CHS from "./chakra.styles";

export function CodeItem({
  userId,
  type,
  data,
  openQrModal,
  openEditCardModal,
  mainData,
  setCurrentCard,
}) {
  const [isHorizontal, setIsHorizontal] = useState(true);
  const { t } = useTranslation();
  const itemObj = {
    card: {
      icon: <BusinessCardIcon w="10" h="7" fill="black.900" />,
      title: t("app.business_card"),
    },
    qr: {
      icon: <QrIcon w="8" h="8" fill="black.900" />,
      title: t("app.qr__code"),
    },
  };

  const {
    isOpen: isOpenCardModal,
    onOpen: onOpenCardModal,
    onClose: onCloseCardModal,
  } = useDisclosure();

  const lookHandler = (obj) => {
    if (type === CARD_TYPE.CARD) {
      setCurrentCard(obj);
      onOpenCardModal();
    } else {
      openQrModal(obj);
    }
  };

  const downloadPdfHandler = () => {
    if (type === CARD_TYPE.CARD) {
      const saveFile = !isHorizontal
        ? data?.url?.invertedBusinessCardPdf
        : data?.url?.businessCardPdf;
      saveAs(saveFile, "xTips.pdf");
    } else {
      saveAs(data?.url?.qrCodePdf, "xTips.pdf");
    }
  };

  const downloadPngHandler = () => {
    if (type === CARD_TYPE.CARD) {
      const saveFile = !isHorizontal
        ? data?.url?.invertedBusinessCardPng
        : data?.url?.businessCardPng;
      saveAs(saveFile, "xTips.png");
    } else {
      saveAs(data?.url?.qrCodePng, "xTips.png");
    }
  };

  const isMyCard = userId === mainData?.ownerId || userId === data?.id;

  return (
    <>
      <Flex sx={CHS.wrapItem}>
        <Flex sx={CHS.contentWrap}>
          <Flex sx={CHS.imgWrap}>
            <Flex sx={CHS.imgBlock}>
              <Box sx={!isHorizontal ? CHS.rotateBlock : ""}>
                {itemObj[type].icon}
              </Box>
            </Flex>
            {type === CARD_TYPE.CARD && (
              <IconButton
                onClick={() => setIsHorizontal((prevState) => !prevState)}
                sx={CHS.reverseBtn}
                aria-label="btn"
                icon={<RepeatIcon boxSize={7} />}
              />
            )}
          </Flex>
          <Text sx={CHS.textTitle}>{itemObj[type].title}</Text>
          {type === CARD_TYPE.CARD && (
            <Text sx={CHS.textSize}>
              {!isHorizontal
                ? t("app.vertical_style")
                : t("app.horizontal_style")}
            </Text>
          )}
        </Flex>
        <VStack spacing="2.5" align="flex-start">
          <Flex justifyContent="space-between" w="full">
            <Button
              leftIcon={<EyeIcon w="4.5" h="4.5" fill="brand" />}
              variant="cabOutline"
              onClick={() => lookHandler(data)}
            >
              {t("app.look")}
            </Button>
            {type === CARD_TYPE.CARD && isMyCard && (
              <Button
                leftIcon={<EditIcon w="4.5" h="4.5" fill="brand" />}
                variant="editBtn"
                onClick={() => openEditCardModal(mainData)}
                sx={{ display: "none" }}
              />
            )}
          </Flex>
          <Button
            variant="cabDownBtn"
            leftIcon={
              <DownloadIcon w="4.5" h="4.5" stroke="white.900" fill="none" />
            }
            onClick={downloadPdfHandler}
          >
            {t("app.download_pdf")}
          </Button>
          <Button
            variant="cabDownBtn"
            leftIcon={
              <DownloadIcon w="4.5" h="4.5" stroke="white.900" fill="none" />
            }
            onClick={downloadPngHandler}
          >
            {t("app.download_png")}
          </Button>
        </VStack>
      </Flex>
      <BusinessCardModal
        isOpen={isOpenCardModal}
        onClose={onCloseCardModal}
        url={data?.url}
        isHorizontal={isHorizontal}
      />
    </>
  );
}
