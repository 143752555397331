import CHS from "components/Sidebar/chakra.styles";
import { Avatar, Box, Flex } from "@chakra-ui/react";
import { useCurrentUserContext } from "hooks";

export function UserInfo() {
  const { currentUser } = useCurrentUserContext();
  return (
    <Flex gap={2.5} alignItems="center">
      <Box>
        <Avatar
          h={16}
          w={16}
          name={currentUser?.fullName}
          src={currentUser?.avatarUrl}
        />
      </Box>
      <Flex sx={CHS.personBlock}>
        <Box sx={CHS.text}>{currentUser?.fullName}</Box>
        <Box sx={CHS.profession}>{currentUser?.work}</Box>
      </Flex>
    </Flex>
  );
}
