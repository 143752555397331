import { Box, Flex } from "@chakra-ui/react";
import { PATHS, sidebarLinks } from "constants/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CHS from "./chakra.styles";

export function SidebarMenu({ onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
    onClose();
  };
  const getTitle = (path) => {
    switch (path) {
      case PATHS.cab:
        return t("app.home");
      case PATHS.settings:
        return t("app.settings");
      case PATHS.qrcodes:
        return t("app.qr_code");
      default:
        return "";
    }
  };
  return (
    <Flex sx={CHS.settingsBlock}>
      {sidebarLinks.map(({ path, icon }) => (
        <Flex key={path} sx={CHS.option} onClick={() => goTo(path)}>
          {icon}
          <Box sx={CHS.text}>{getTitle(path)}</Box>
        </Flex>
      ))}
    </Flex>
  );
}
