import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { getFormatDate } from "utils/getFormatDate";
import { HandIcon } from "components/Icons";
import CHS from "./chakra.styles";

export function LatestTransactionsRow({ data }) {
  const {
    icon = "",
    title = "Account refill",
    amount = 0,
    createdAt = new Date(),
    moderationStatus,
    type,
  } = data;
  return (
    <Box sx={CHS.rowWrapper}>
      <Flex sx={CHS.rowBody}>
        <Box sx={CHS.iconBlock}>
          {icon ? (
            <Image src={icon} alt={icon} sx={CHS.img} />
          ) : (
            <HandIcon width="8.5" height="10" fill="white.900" />
          )}
        </Box>
        <Flex sx={CHS.textBlock}>
          <Box>
            <Text size="medium" variant="standard">
              {title}
            </Text>
          </Box>
          <Box>
            <Text size="notify2xs">
              {type !== "outcome"
                ? "You are credited to the means"
                : "Transfer to bank account"}
            </Text>
          </Box>
        </Flex>
        <Flex sx={CHS.valueBlock}>
          {type === "outcome" && moderationStatus && (
            <Box>status: {moderationStatus}</Box>
          )}
          <Box>
            <Text size="primary" variant="brand">
              {amount} €
            </Text>
          </Box>
          <Box>
            <Text size="small" variant="standard">
              {getFormatDate(createdAt)}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
