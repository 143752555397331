import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useMediaQuery,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import CHS from "./chakra.styles";
import privacyPolicy from "../../assets/docs/PRIVACY_POLICY.pdf";
import termsAndConditions from "../../assets/docs/TERMS_AND_CONDITIONS.pdf";
import legalNotesContact from "../../assets/docs/LEGAL_NOTES.pdf";
import cookiePolicy from "../../assets/docs/COOKIE_POLICY.pdf";

export function Footer() {
  const [isMobile] = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation();
  return (
    <Box sx={CHS.wrapper}>
      <Container>
        <Flex sx={CHS.col} gap={isMobile ? "auto" : "10"}>
          <Box
            sx={isMobile ? CHS.linksBlockMobile : CHS.linksBlock}
            mb={isMobile ? "10" : 0}
          >
            <Link href={privacyPolicy} target="_blank">
              {t("app.privacy_policy")}
            </Link>
            <Link href={cookiePolicy} target="_blank">
              {t("app.cookie_policy")}
            </Link>
            <Link href={termsAndConditions} target="_blank">
              {t("app.terms_conditions")}
            </Link>
            <Link href={legalNotesContact} target="_blank">
              {t("app.legal_notes")}
            </Link>
            <Link href="mailto:xtips@xtips.ee">{t("app.contact")}</Link>
          </Box>
          <Center>
            <Text color="grey.500" textAlign="center">
              © {new Date().getFullYear()}
              {` `}
              {t("app.xTips_srl_all_rights_reserved")}
            </Text>
          </Center>
        </Flex>
      </Container>
    </Box>
  );
}
