export const transformDistributePercentage = (data, id) => {
  if (data) {
    return {
      groupId: id,
      members: Object.entries(data)?.map((arr) => ({
        userId: Number(arr[0]),
        tipPercentage: Number(arr[1]),
      })),
    };
  }
  return [];
};
