import { gql } from "@apollo/client";

export const GET_MY_INVITATIONS = gql`
  query getMyInvitations {
    getUser {
      groupInvitations {
        groupId
        email
        group {
          id
          name
          owner {
            fullName
          }
        }
      }
    }
  }
`;
