export default {
  wrapItem: {
    justifyContent: "space-between",
    w: "full",
    py: "5",
    pl: "5",
    pr: "2",
    bgColor: "white.900",
    borderRadius: "lg",
  },

  contentWrap: {
    flexDirection: "column",
    justifyContent: "center",
  },

  textTitle: {
    pt: "3",
    fontSize: "md",
    lineHeight: "8",
    color: "black.900",
  },

  textSize: {
    pt: "1",
    fontSize: "2xs",
    lineHeight: "5",
  },
  reverseBtn: {
    width: "fit-content",
    borderRadius: "50%",
    background: "transparent",
    borderWidth: "1px",
    borderColor: "brand",
    borderStyle: "solid",
    color: "brand",
  },
  imgWrap: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  imgBlock: {
    width: "12.5",
    height: "12.5",
    alignItems: "center",
    justifyContent: "start",
  },
  rotateBlock: {
    transform: "rotate(-90deg)",
  },
};
