import {
  VStack,
  Flex,
  Container,
  Accordion,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import { AccordionItem } from "components/AccordionItem";
import { AdvantageItem } from "components/AdvantageItem";
import { StepItem } from "components/StepItem";
import advImg1 from "assets/img/solo/advantages/item-1.png";
import advImg2 from "assets/img/solo/advantages/item-2.png";
import advImg3 from "assets/img/solo/advantages/item-3.png";
import advImg4 from "assets/img/solo/advantages/item-4.png";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import glStyless from "../Team/global.styles";
import styless from "./chakra.styles";

export default function Solo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signUp = () => {
    navigate(PATHS.register);
  };

  return (
    <>
      <Flex sx={styless.sectionIntoSoloWrap} w="full">
        <Container>
          <VStack spacing={{ base: 5, md: 10 }}>
            <Heading sx={glStyless.mainTitle} textAlign={{ base: "center" }}>
              {t("app.dont_waste_your_tip_again")}
            </Heading>
            <Text maxW={{ md: "lg" }} textAlign={{ base: "center" }}>
              {t("app.xTips_is_a_simple_solution_create_your_own_QR_Code")}
            </Text>
            <Button
              sx={glStyless.singUpButton}
              variant="brandSolidRounded"
              onClick={signUp}
            >
              {t("app.sign_app_now")}
            </Button>
          </VStack>
        </Container>
      </Flex>
      <Container>
        <VStack sx={styless.sectionAdvantagesSolo} spacing={5}>
          <Heading sx={glStyless.secondaryTitle}>
            {t("app.our_advantages")}
          </Heading>
          <Text sx={glStyless.sectionText}>
            {t("app.check_out_our_list_of_awesome_features_below")}
          </Text>
          <Flex sx={styless.advatagesListSolo}>
            <AdvantageItem
              img={advImg1}
              title={t("app.QR_code_for_the_team")}
              text="A super quick way to leave tips for your entire team"
            />
            <AdvantageItem
              img={advImg2}
              title={t("app.apple_pay_google_pay")}
              text={t("app.customer_oriented_payment_methods")}
            />
            <AdvantageItem
              img={advImg3}
              title={t("app.auto_split")}
              text={t(
                "app.all_tips_can_go_directly_through_the_QR_code_and_be_divided"
              )}
            />
            <AdvantageItem
              img={advImg4}
              title={t("app.quick_registration")}
              text={t("app.register_absolutely_free")}
            />
          </Flex>
        </VStack>
      </Container>
      <Flex sx={styless.sectionHowWorksWrapSolo}>
        <Container>
          <Flex sx={styless.sectionHowWorksSolo}>
            <VStack
              sx={styless.sectionHowWorksSoloInner}
              spacing={{ base: 5, lg: 10 }}
              w="full"
            >
              <Heading sx={glStyless.secondaryTitle}>
                {t("app.how_does_it_work")}
              </Heading>
              <VStack
                sx={styless.sectionHowWorksSoloList}
                spacing={{ base: "10", md: "5" }}
                pt={5}
              >
                <StepItem
                  step="1"
                  title={t("app.scan")}
                  text={t(
                    "app.the_customer_only_needs_an_internet_connection_No_registration_or_app"
                  )}
                />
                <StepItem
                  step="2"
                  title={t("app.reward")}
                  text={t(
                    "app.the_customer_chooses_the_amount_and_method_of_payment"
                  )}
                />
                <StepItem
                  step="3"
                  title={t("app.done")}
                  text={t(
                    "app.you_can_get_a_tip_even_if_the_customer_pays_with_a_bank_card"
                  )}
                />
              </VStack>
              <Button
                variant="brandSolidRounded"
                sx={glStyless.singUpButton}
                onClick={signUp}
              >
                {t("app.sign_app_now")}
              </Button>
            </VStack>
          </Flex>
        </Container>
      </Flex>

      <Flex w="full" sx={styless.sectionQuestionsSolo}>
        <Container>
          <VStack
            spacing={{ base: "10", md: "15" }}
            sx={styless.sectionQuestionsInner}
          >
            <Heading sx={glStyless.secondaryTitle}>
              {t("app.frequently_asked_questions")}
            </Heading>
            <Accordion w="full" defaultIndex={[0]} allowMultiple>
              <AccordionItem
                title={t("app.what_is_xTips")}
                text={t("app.what_is_xTips_data")}
              />
              <AccordionItem
                title={t("app.how_to_leave_a_tip_without_cash")}
                text={t("app.how_to_leave_a_tip_without_cash_data")}
              />
              <AccordionItem
                title={t("app.is_your_country_available")}
                text={t("app.is_your_country_available_data")}
              />
              <AccordionItem
                title={t(
                  "app.how_much_does_the_worker_receive_when_i_leave_a_tip"
                )}
                text={t(
                  "app.how_much_does_the_worker_receive_when_i_leave_a_tip_data"
                )}
              />
              <AccordionItem
                title={t("app.how_much_does_xTips_cost")}
                text={t("app.how_much_does_xTips_cost_data")}
              />
              <AccordionItem
                title={t("app.are_the_tips_fixed_or_can_i_choose_the_amount")}
                text={t(
                  "app.are_the_tips_fixed_or_can_i_choose_the_amount_data"
                )}
              />
              <AccordionItem
                title={t("app.how_do_i_receive_tips_with_xTips")}
                text={t("app.how_do_i_receive_tips_with_xTips_data")}
              />
            </Accordion>
          </VStack>
        </Container>
      </Flex>

      <Flex w="full" sx={styless.sectionBannerSoloWrap}>
        <Container>
          <Flex sx={styless.sectionBannerSolo}>
            <VStack
              spacing={{ base: "5", md: "7.5" }}
              sx={styless.sectionBannerSoloInner}
            >
              <Text sx={glStyless.sectionSignUpText}>
                {t(
                  "app.sign_up_now_and_experience_all_the_benefits_of_using_the_contactless_tip_method"
                )}
              </Text>
              <Button
                variant="brandSolidRounded"
                sx={glStyless.singUpButton}
                onClick={signUp}
              >
                {t("app.sign_app_now")}
              </Button>
            </VStack>
          </Flex>
        </Container>
      </Flex>
    </>
  );
}
