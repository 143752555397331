export default {
  header: {
    w: "full",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "3",
  },
  emptyList: {
    px: "3.75",
    py: "3.75",
    borderRadius: "lg",
    bgColor: "white.900",
  },
};
