import { useGetUsers } from "graphql/hooks/useQueries";
import { COUNTER_PER_USERS_LOGS } from "constants/constants";
import { ArrowLeft } from "components/Icons/CabineteIcon/ArrowLeft";
import { ArrowRightIcon } from "components/Icons/CabineteIcon/ArrowRightIcon";
import Pagination from "rc-pagination";
import { useNotificationsContext } from "hooks";
import { useEffect, useState } from "react";
import { Flex, Text, VStack } from "@chakra-ui/react";
import CHS from "./chakra.styles";

export function UsersTab() {
  const { notifications } = useNotificationsContext();
  const [offset, setOffset] = useState(0);

  const { data, loading, error, refetch } = useGetUsers({
    variables: {
      limit: COUNTER_PER_USERS_LOGS,
      offset,
    },
    fetchPolicy: "network-only",
  });

  const usersData = data?.getUsers?.rows;
  const dataCount = data?.getUsers?.count;

  const handlePagination = (page) => {
    setOffset((page - 1) * COUNTER_PER_USERS_LOGS);
  };

  const getDefaultPage = () => {
    return offset / COUNTER_PER_USERS_LOGS + 1;
  };

  useEffect(() => {
    refetch();
  }, [notifications]);

  if (!data || loading || error) return null;

  return (
    <Flex width="100%" flexDirection="column">
      <VStack w="full">
        {usersData?.map((user) => {
          const date = new Date(Number(user.createdAt)).toLocaleString();

          return (
            <VStack alignItems="start" key={user.id} w="full" sx={CHS.card}>
              <Text size="notifyXsInterMedium" color="brand">
                Full Name: {user.fullName}
              </Text>
              <Text size="notifyXsInterMedium">Date: {date}</Text>
              <Text size="notifyXsInterMedium">Email: {user.email}</Text>
              <Text size="notifyXsInterMedium">
                Phone Number: {user.phoneNumber}
              </Text>
              {user.iban && (
                <Text size="notifyXsInterMedium">IBAN: {user.iban}</Text>
              )}
              <Text size="notifyXsInterMedium" color="brand">
                Balance: {user.balance.balance}
              </Text>
              <Text size="notifyXsInterMedium" color="brand">
                Balance with fee: {user.balance.net}
              </Text>
            </VStack>
          );
        })}
      </VStack>
      <Pagination
        defaultCurrent={1}
        current={getDefaultPage()}
        total={dataCount}
        pageSize={COUNTER_PER_USERS_LOGS}
        onChange={handlePagination}
        showLessItems
        prevIcon={ArrowLeft}
        nextIcon={ArrowRightIcon}
        showPrevNextJumpers={true}
        style={{ marginTop: "20px" }}
      />
    </Flex>
  );
}
