import Button from "components/Button/Button";
import { HStack } from "@chakra-ui/react";
import CHS from "./chakra.styles";

export function TabsCustom({ options, onClick = (val) => {}, tabNum = "" }) {
  return (
    <HStack>
      {options?.map((option) => {
        return (
          <Button
            sx={tabNum === option.id ? CHS.target : CHS.default}
            key={option.id}
            variant="cabOutline"
            onClick={() => onClick(option.id)}
          >
            {option.label}
          </Button>
        );
      })}
    </HStack>
  );
}
