import { gql } from "@apollo/client";

export const CHANGE_ROLE_OF_GROUP_MEMBER = gql`
  mutation changeRoleOfGroupMember(
    $groupId: Int!
    $userId: Int!
    $newRoleId: Int!
  ) {
    changeRoleOfGroupMember(
      groupId: $groupId
      userId: $userId
      newRoleId: $newRoleId
    ) {
      groupId
      userId
      roleId
    }
  }
`;
