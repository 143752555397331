import { gql } from "@apollo/client";

export const CREATE_PAYMENT = gql`
  mutation createPayment($input: CreatingPaymentInput!) {
    createPayment(input: $input) {
      id
      status
      payment_status
      url
    }
  }
`;
