import { Icon } from "@chakra-ui/react";

export default function DownloadIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M7.99998 10L10.6666 7.33333M7.99998 10V2V10ZM7.99998 10L5.33331 7.33333L7.99998 10Z"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33331 11.3333L1.74731 12.9899C1.81941 13.2784 1.98586 13.5344 2.2202 13.7174C2.45454 13.9004 2.74332 13.9999 3.04065 13.9999H12.9593C13.2566 13.9999 13.5454 13.9004 13.7798 13.7174C14.0141 13.5344 14.1805 13.2784 14.2526 12.9899L14.6666 11.3333"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
