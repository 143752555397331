import { useContext } from "react";
import CurrentUserContext from "context/currentUser/currentUser.context";

export const useCurrentUserContext = () => {
  const currentUserContext = useContext(CurrentUserContext);

  if (!currentUserContext) {
    throw new Error(
      "useCurrentUserContext must be used within a CurrentUserProvider"
    );
  }

  return currentUserContext;
};
