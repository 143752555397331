import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  InputRightElement,
  IconButton,
  InputGroup,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import CHS from "./chakra.styles";

export default function TextField(props) {
  const {
    id,
    name,
    label,
    placeholder,
    type = "text",
    variant = "base",
    isDisabled,
    inputProps,
    hasError,
    errorMessage,
    autoComplete = "on",
    ...textFieldProps
  } = props;
  const [currentInputType, setCurrentInputType] = useState(type);
  const handleChangeType = useCallback(() => {
    setCurrentInputType((prevState) => {
      return prevState === "password" ? "text" : "password";
    });
  }, []);
  const isBaseVariant = variant === "base";
  return (
    <FormControl isInvalid={hasError} mb={0} {...textFieldProps}>
      {label ? (
        <FormLabel htmlFor={id} variant={variant}>
          {label}
        </FormLabel>
      ) : null}
      <InputGroup>
        <Input
          variant={variant}
          id={id}
          type={currentInputType}
          placeholder={placeholder}
          isDisabled={isDisabled}
          borderColor={
            hasError && isBaseVariant ? "red.500 !important" : "transparent"
          }
          borderBottomColor={
            hasError && !isBaseVariant ? "red.500 !important" : "grey.550"
          }
          borderWidth={isBaseVariant ? 2 : null}
          {...inputProps}
          autoComplete={autoComplete}
        />
        {type === "password" && (
          <InputRightElement sx={CHS.inputRE}>
            <IconButton
              backgroundColor="transparent"
              aria-label={label}
              variant="iconButton"
              size="iconButton"
              icon={
                currentInputType === "password" ? (
                  <ViewIcon w="6" h="6" stroke="black.900" />
                ) : (
                  <ViewOffIcon w="6" h="6" stroke="black.900" />
                )
              }
              onClick={handleChangeType}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {hasError && errorMessage && (
        <FormErrorMessage maxW="120">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}
