import { gql } from "@apollo/client";

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword(
    $token: String!
    $password: String!
    $confirmationPassword: String!
  ) {
    recoverPassword(
      token: $token
      password: $password
      confirmationPassword: $confirmationPassword
    ) {
      Authorization
    }
  }
`;
