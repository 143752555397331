import { Icon } from "@chakra-ui/react";

export default function QrCodeIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_654_1490)">
        <path d="M12.3225 5.98917C12.3225 6.50601 11.9035 6.92498 11.3867 6.92498H4.74765C4.2308 6.92498 3.81184 6.50601 3.81184 5.98917C3.81184 5.47232 4.2308 5.05336 4.74765 5.05336H11.3867C11.9035 5.05336 12.3225 5.47232 12.3225 5.98917ZM22.1069 20.0642V7.52979C22.1069 6.49778 21.2673 5.65817 20.2353 5.65817H16.1177V8.84338H18.9199C19.4368 8.84338 19.8557 9.26234 19.8557 9.77919C19.8557 10.296 19.4368 10.715 18.9199 10.715H16.1177V12.5866H18.9199C19.4368 12.5866 19.8557 13.0056 19.8557 13.5224C19.8557 14.0393 19.4368 14.4582 18.9199 14.4582H16.1177V16.3298H18.9199C19.4368 16.3298 19.8557 16.7488 19.8557 17.2656C19.8557 17.7825 19.4368 18.2015 18.9199 18.2015H15.6902C15.0638 19.3942 13.8134 20.2103 12.3754 20.2106L9.28631 20.2114V20.2567C9.28631 21.2887 10.1259 22.1283 11.1579 22.1283H20.2353C21.2673 22.1283 22.1069 21.2887 22.1069 20.2567C22.1069 19.7399 22.5258 19.3209 23.0427 19.3209C23.5595 19.3209 23.9785 19.7399 23.9785 20.2567C23.9785 22.3207 22.2993 24 20.2353 24H11.1579C9.09391 24 7.4147 22.3207 7.4147 20.2567V20.2119L3.76599 20.2127C2.76622 20.2127 1.82554 19.8236 1.11849 19.1167C0.411303 18.4097 0.0218201 17.4695 0.0218201 16.4695V3.74323C0.0218201 1.67921 1.70103 0 3.76505 0H12.3745C14.4385 0 16.1177 1.67921 16.1177 3.74323V3.78656H20.2353C22.2993 3.78656 23.9785 5.46577 23.9785 7.52979V20.0642C23.9785 20.581 23.5595 21 23.0427 21C22.5258 21 22.1069 20.581 22.1069 20.0642ZM14.2461 3.74323C14.2461 2.71122 13.4065 1.87162 12.3745 1.87162H3.76505C2.73304 1.87162 1.89344 2.71122 1.89344 3.74323V16.4695C1.89344 16.9696 2.08818 17.4396 2.44177 17.7931C2.79527 18.1465 3.26519 18.3411 3.76505 18.3411L12.3749 18.339C13.4067 18.3388 14.246 17.4992 14.246 16.4674L14.2461 3.74323Z" />
        <path d="M6.168 8C6.44414 8 6.668 8.22386 6.668 8.5V10.6664H9.3336V13.3336H6.668V15.4992C6.668 15.7753 6.44414 15.9992 6.168 15.9992H4.5C4.22386 15.9992 4 15.7753 4 15.4992V13.833C4 13.5568 4.22395 13.3329 4.50015 13.333L6.668 13.3336V10.6664H4.5C4.22386 10.6664 4 10.4425 4 10.1664V8.5C4 8.22386 4.22386 8 4.5 8H6.168ZM11.4999 13.333C11.7761 13.3329 12 13.5567 12 13.833V15.5C12 15.7761 11.7761 16 11.5 16H9.8336C9.55746 16 9.3336 15.7761 9.3336 15.5V13.3336L11.4999 13.333ZM11.5 8C11.7761 8 12 8.22386 12 8.5V10.1664C12 10.4425 11.7761 10.6664 11.5 10.6664H9.3336V8.5C9.3336 8.22386 9.55746 8 9.8336 8H11.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_654_1490">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </Icon>
  );
}
