import * as yup from "yup";
import { ERRORS_MESSAGES as M } from "constants/constants";

export const confirmEmail = yup.object().shape({
  confirmationCode: yup
    .string()
    .min(6, M.MUST_BE_6_CHARACTERS_LONG)
    .max(6, M.MUST_BE_6_CHARACTERS_LONG)
    .required(M.REQUIRE_MESSAGE),
});
