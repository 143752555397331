export const errConvert = (graphQLErrors, t, setError) => {
  if (!graphQLErrors) return;
  const errStr = graphQLErrors?.[0]?.message
    .replace("SequelizeUniqueConstraintError:", "")
    .split(" ")
    .splice(1)
    .join(" ");
  switch (errStr) {
    case "Email is already registered":
      setError("email", {
        type: "custom",
        message: t("app.email_is_already_registered"),
      });
      break;
    case "Phone number is already registered":
      setError("phone", {
        type: "custom",
        message: t("app.phone_is_already_registered"),
      });
      break;
    default:
      console.log(graphQLErrors?.[0]?.message);
  }
};
