import BalanceBG from "assets/img/balance-bg.png";

export default {
  wrap: {
    pt: "5.5",
    pb: "3",
    px: "5",
    bgColor: "brand",
    borderRadius: "lg",
    color: "white.900",
    bgImage: BalanceBG,
    bgRepeat: "no-repeat",
    bgPosition: "right center",
    cursor: "pointer",
  },

  title: {
    fontSize: "xs",
    lineHeight: "shorter",
    color: "white.900",
  },

  balance: {
    fontFamily: "boldInter",
    fontSize: "2xl",
    lineHeight: "42px",
    fontWeight: "bold",
    color: "white.900",
  },

  link: {
    mt: "3",
    alignItems: "center",

    p: {
      mr: "2",
      fontWeight: "bold",
      fontSize: "xs",
      lineHeight: "8",
      color: "white",
    },

    _hover: {
      cursor: "pointer",
      opacity: "0.9",
    },
  },
};
