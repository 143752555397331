import * as yup from "yup";
import { ERRORS_MESSAGES as M } from "constants/constants";

export const createGroupSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, M.MIN_2_CHARACTERS)
    .max(40, M.MAX_40_CHARACTERS)
    .required(M.REQUIRE_MESSAGE),
  type: yup
    .string()
    .min(2, M.MIN_2_CHARACTERS)
    .max(40, M.MAX_40_CHARACTERS)
    .required(M.REQUIRE_MESSAGE),
  roles: yup.array().min(1, M.REQUIRE_MESSAGE_ROLE_BTN),
});
