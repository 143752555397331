import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers($limit: Int!, $offset: Int!) {
    getUsers(limit: $limit, offset: $offset) {
      count
      rows {
        id
        phoneNumber
        iban
        fullName
        email
        createdAt
        balance {
          balance
          net
        }
      }
    }
  }
`;
