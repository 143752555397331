import {
  Box,
  Container,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { LogoIcon } from "components/Icons/LogoIcon";
import { BurgerMenuIcon } from "components/Icons";

import { Sidebar } from "components/Sidebar";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import CHS from "./chakra.styles";
import { useAuthContext } from "../../hooks";
import { useUtilsContext } from "../../context/utilsContext/UtilsContext";

export function HeaderCab({ setScrollContainer = () => {} }) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  const {
    onCloseWithdrawModal,
    onCloseGroupModal,
    addWorkerOnClose,
    groupListItemOnClose,
  } = useUtilsContext();
  const { isAuth } = useAuthContext();

  const goLending = () => {
    navigate(isAuth ? PATHS.cab : PATHS.solo);
    if (PATHS.cab) {
      onCloseWithdrawModal();
      onCloseGroupModal();
      addWorkerOnClose();
      groupListItemOnClose();
    }
  };

  const handleOnToggle = () => {
    onToggle();
    setScrollContainer((prev) => !prev);
  };

  const handleOnClose = () => {
    onClose();
    setScrollContainer(false);
  };

  return (
    <Box>
      <Container>
        <Flex sx={CHS.wrapper}>
          <LogoIcon cursor="pointer" w={24} h={10} onClick={goLending} />
          <IconButton
            backgroundColor="transparent"
            onClick={handleOnToggle}
            aria-label="BurgerMenuIcon"
            icon={<BurgerMenuIcon width={6} height={3} fill="grey.600" />}
          />
          <Sidebar isOpen={isOpen} onClose={handleOnClose} />
        </Flex>
      </Container>
    </Box>
  );
}
