export default {
  // Global Styles for 2 pages
  mainTitle: {
    width: "full",
    fontSize: {
      base: "3xl",
      lg: "5xl",
    },
    lineHeight: {
      base: "14",
      lg: "19",
    },
  },

  secondaryTitle: {
    fontSize: {
      base: "xl",
      lg: "4xl",
    },
    textAlign: {
      base: "center",
      md: "left",
    },
    lineHeigth: {
      base: "10",
      lg: "18",
    },
  },

  sectionText: {
    textAlign: "center",
    color: "black.900",
  },

  singUpButton: {
    w: {
      base: "50",
      lg: "42",
    },
    height: "14",
  },

  sectionSignUpText: {
    fontFamily: "normalAvenir",
    color: "black.900",
    fontSize: {
      base: "xl",
      lg: "3xl",
    },
    textAlign: {
      base: "center",
      md: "left",
    },
    lineHeight: {
      base: "10",
      lg: "14",
    },
  },
};
