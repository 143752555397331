import React from "react";
import { Image, Text, VStack } from "@chakra-ui/react";
import styless from "./chakra.styles";

export function AdvantageItem({ img, title, text }) {
  return (
    <VStack sx={styless.advantageItem} spacing="5">
      <Image src={img} alt={title} />
      <Text sx={styless.advantageItemTitle}>{title}</Text>
      <Text sx={styless.advantageItemText}>{text}</Text>
    </VStack>
  );
}
