import { gql } from "@apollo/client";

export const FILE_URLS = gql`
  fragment fileUrlsFragment on UserFileUrl {
    qrCodePng
    qrCodePdf
    businessCardPng
    businessCardPdf
    invertedBusinessCardPng
    invertedBusinessCardPdf
  }
`;
