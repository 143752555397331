import { gql } from "@apollo/client";

export const CANCEL_PAYMENT = gql`
  mutation cancelPayment($stripeId: String!) {
    cancelPayment(stripeId: $stripeId) {
      id
      stripeId
      status
    }
  }
`;
