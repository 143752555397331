export default {
  variants: {
    base: {
      overlay: {
        bg: "transparent",
      },
      dialog: {
        borderRadius: "0",
        bgColor: "#F0F3F8;",
        my: "0",
      },
      header: {
        py: 0,
        px: 0,
      },
      body: {
        py: 5,
        px: 4,
      },
    },

    transparent: {
      overlay: {
        bgColor: "rgba(0, 0, 0, 0.7)",
      },
      dialog: {
        bgColor: "transparent",
        boxShadow: "none",
      },
      header: {
        px: "4",
        fontFamily: "mediumInter",
        fontSize: "2xl",
        fontWeight: "medium",
        lineHeight: "11",
        color: "white.900",
      },

      closeButton: {
        top: "3.5",
        right: "4",
        w: "10",
        h: "10",
        borderRadius: "50%",
        bgColor: "white.900",

        svg: {
          w: "4",
          h: "4",
          path: {
            fill: "black.900",
          },
        },

        _hover: {
          bgColor: "white.900",
        },
      },

      body: {
        pt: "2",
        pb: "26",
        px: "4",
      },
    },
  },
  sizes: {
    base: {
      dialog: {
        maxW: "420px",
        minHeight: "100vh",
      },
    },
    transparent: {
      dialog: {
        maxW: "420px",
        minHeight: "auto",
      },
    },
  },
  defaultProps: {
    variant: "base",
    size: "base",
  },
};
