import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthTitleBlock } from "components/AuthTitleBlock/AuthTitleBlock";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { forgotPasswordSchema } from "validation";
import { useForgotPassword } from "graphql/hooks/useMutations";
import { PATHS } from "constants/constants";

const INITIAL_VALUES_FORGOT_PASSWORD = {
  email: "",
};

export default function ForgotPassword() {
  const [formSending, setFormSending] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPassword();
  const [emailIsSent, setEmailIsSent] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUES_FORGOT_PASSWORD,
    resolver: yupResolver(forgotPasswordSchema),
  });

  const handleNavigateToHome = () => {
    navigate(PATHS.solo);
  };

  const onSubmit = (data) => {
    setFormSending(true);
    forgotPassword({
      variables: {
        email: data?.email,
      },
      onCompleted: () => {
        setFormSending(false);
        reset(INITIAL_VALUES_FORGOT_PASSWORD);
        setEmailIsSent(true);
      },
      onError: ({ graphQLErrors }) => {
        setFormSending(false);
        if (graphQLErrors) {
          switch (graphQLErrors?.[0]?.message) {
            case "Email not found":
              setError("email", {
                type: "custom",
                message: t("app.email_not_found"),
              });
              break;
            default:
              console.log(graphQLErrors?.[0]?.message);
          }
        }
      },
    });
  };

  return (
    <Box>
      <AuthTitleBlock
        title={t("app.forget_password")}
        subTitle={t(
          "app.receive_send_and_manage_tips_directly_touchless_and_cashless"
        )}
      />
      {!emailIsSent ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldRHF
            mb={10}
            register={register}
            name="email"
            label={t("app.e_mail")}
            placeholder={t("app.enter_your_email")}
            hasError={errors?.email}
            errorMessage={errors?.email?.message}
          />
          <Button size="registerButton" type="submit" disabled={formSending}>
            {t("app.send")}
          </Button>
        </form>
      ) : (
        <>
          <Text size="answerTitle" variant="success">
            {t("app.success")}!
          </Text>
          <Text mb={10} fontSize="lg">
            {t("app.check_your_email_and_follow_the_attached_link")}
          </Text>
          <Button size="registerButton" onClick={handleNavigateToHome}>
            {t("app.go_to_site")}
          </Button>
        </>
      )}
    </Box>
  );
}
