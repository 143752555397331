import { gql } from "@apollo/client";

export const EDIT_USER_PROFILE = gql`
  mutation editUserProfile($input: UserProfileEditingInput!) {
    editUserProfile(input: $input) {
      id
      fullName
      email
      phoneNumber
      work
      iban
      avatarUrl
      groupInvitations {
        groupId
        email
        group {
          id
          name
          owner {
            fullName
          }
        }
      }
    }
  }
`;
