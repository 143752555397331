import { useState } from "react";

import { TabsCustom } from "components/TabsCustom/TabsCustom";
import {
  ActivityLogsTab,
  AdminPaymentTab,
  UsersTab,
} from "components/AdminPanelTabs";
import { Flex } from "@chakra-ui/react";

const optionsTabs = [
  { id: "payment", label: "Payment" },
  { id: "activity_logs", label: "Activity logs" },
  { id: "users", label: "Users" },
];

const TAB_VIEWS = {
  payment: <AdminPaymentTab />,
  activity_logs: <ActivityLogsTab />,
  users: <UsersTab />,
};

export default function Admin() {
  const [tabNum, setTabNum] = useState("payment");

  const handleTabsClick = (val) => {
    setTabNum(val);
  };

  const TabViews = TAB_VIEWS[tabNum];

  return (
    <>
      <TabsCustom
        options={optionsTabs}
        onClick={handleTabsClick}
        tabNum={tabNum}
      />
      <Flex mt={6}>{TabViews}</Flex>
    </>
  );
}
