import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_GROUPS, GET_MY_INVITATIONS } from "graphql/queries";
import { Box, Heading, Text } from "@chakra-ui/react";
import { MyBalance } from "components/MyBalance";
import { GroupList } from "components/GroupsList";
import { LatestTransactions } from "components/LatestTransactions";
import { getInvitationsState, getInviteObjShow } from "utils/invitationState";
import { useAcceptGroupInvitation } from "graphql/hooks/useMutations";
import { useCurrentUserContext, useNotificationsContext } from "hooks";
import { AlertModal } from "components/AlertModal";
import {
  useGetMyInvitations,
  useGetInnerTransactions,
} from "graphql/hooks/useQueries";
import { COUNTER_PER_PAGE, PATHS } from "constants/constants";
import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";

const useTransactions = () => {
  const [filter, setFilter] = useState("All");
  const [offset, setOffset] = useState(0);

  const { data: dataTransactions, refetch: refetchTransactions } =
    useGetInnerTransactions({
      variables: {
        input: {
          limit: COUNTER_PER_PAGE,
          offset,
          filter,
        },
      },
      fetchPolicy: "network-only",
    });

  return {
    dataTransactions,
    refetchTransactions,
    filter,
    setFilter,
    offset,
    setOffset,
  };
};

export default function Home() {
  const [inviteState, setInviteState] = useState([]);
  const [formSending, setFormSending] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { currentUser, refetch } = useCurrentUserContext();
  const { notifications } = useNotificationsContext();

  const [acceptGroupInvitation] = useAcceptGroupInvitation();
  const { data: invitationsData } = useGetMyInvitations();

  const closeInviteAlert = (id) => {
    const newArr = inviteState?.map((item) => {
      if (item.id === id) {
        return { ...item, show: false };
      }
      return item;
    });
    setInviteState([...newArr]);
  };

  const {
    dataTransactions,
    refetchTransactions,
    filter,
    setFilter,
    offset,
    setOffset,
  } = useTransactions();

  const acceptInvitationCb = (answer, groupId) => {
    setFormSending(true);
    acceptGroupInvitation({
      variables: {
        isAccepted: answer,
        groupId,
      },
      onCompleted: () => {
        setFormSending(false);
      },
      onError: (error) => {
        setFormSending(false);
        console.log(error);
      },
      refetchQueries: [GET_GROUPS, GET_MY_INVITATIONS],
    });
  };

  useEffect(() => {
    refetch();
  }, [notifications]);

  useEffect(() => {
    setInviteState(
      getInvitationsState(invitationsData?.getUser?.groupInvitations)
    );
  }, [invitationsData]);

  return (
    <>
      <Heading variant="cabHeadPage" mb="2.5">
        {t("app.welcome_back")}
      </Heading>

      <Text fontSize="xs" color="grey.450" mb="5">
        {t("app.hi")}, {currentUser?.fullName}
      </Text>

      <MyBalance
        balance={currentUser?.balance}
        refetchTransactions={refetchTransactions}
      />

      <Box my="7.5">
        <GroupList />
      </Box>
      {currentUser?.isAdmin && (
        <Button
          variant="alertBtnOutlined"
          size="cabAlert"
          onClick={() => {
            navigate(PATHS.admin);
          }}
        >
          {t("app.admin_panel")}
        </Button>
      )}

      <LatestTransactions
        data={dataTransactions}
        refetch={refetchTransactions}
        offset={offset}
        setOffset={setOffset}
        filter={filter}
        setFilter={setFilter}
      />

      {invitationsData?.getUser?.groupInvitations?.map((item) => (
        <AlertModal
          key={item?.groupId}
          isOpen={getInviteObjShow(inviteState, item?.groupId)}
          onClose={() => closeInviteAlert(item?.groupId)}
          isClosed
          isMoreButtons
          funcLeftBtn={() => acceptInvitationCb(false, item?.groupId)}
          funcRightBtn={() => acceptInvitationCb(true, item?.groupId)}
          nameLeftBtn="decline"
          nameRightBtn="accept"
          disabled={formSending}
        >
          <Heading variant="cabHeadPage" lineHeight="11">
            {`${item?.group?.owner?.fullName} ${t("app.want_to_add_you_to")} `}
            <Text size="linkText" variant="brand">
              {item?.group?.name}
            </Text>
            {` ${t("app.group")}`}
          </Heading>
        </AlertModal>
      ))}
    </>
  );
}
