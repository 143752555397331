import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useChangeBusinessCardText } from "graphql/hooks/useMutations";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { changeVisitCardTextSchema } from "validation/changeVisitCardText.schema";

export function EditCard({ isOpen, onClose, data, refetchGroup, refetchUser }) {
  const [formSending, setFormSending] = useState(false);
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return { text: data?.businessCardText };
    }, [data]),
    resolver: yupResolver(changeVisitCardTextSchema),
  });

  useEffect(() => {
    reset({ text: data?.businessCardText });
  }, [data]);

  const [changeBusinessCardText] = useChangeBusinessCardText();

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (values) => {
    if (!values.text.trim()) return;
    const isGroup = !data?.isUser;

    const transformObj = {
      text: values.text,
      ...(isGroup && { groupId: data?.id }),
    };

    setFormSending(true);
    changeBusinessCardText({
      variables: {
        ...transformObj,
      },
      onCompleted: () => {
        setFormSending(false);
        handleClose();
        refetchGroup();
        refetchUser();
      },
      onError: () => {
        setFormSending(false);
      },
    });
  };

  return (
    <Modal
      variant="transparent"
      size="transparent"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("app.edit_business_card")}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Flex
            bgColor="white.900"
            borderRadius="8px"
            p={5}
            flexDirection="column"
            gap={2.5}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Textarea
                _focusVisible={{
                  borderColor: errors?.text?.message ? "red.500" : "brand",
                }}
                size="md"
                mb={4}
                name="text"
                height={40}
                {...register("text")}
              />
              {errors?.text?.message && (
                <Text size="notify2xs" variant="error" mb={4}>
                  {errors?.text?.message}
                </Text>
              )}
              <Button
                variant="cabDownBtn"
                h={12}
                type="submit"
                disabled={formSending}
              >
                {t("app.update")}
              </Button>
            </form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
