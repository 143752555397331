import { PaymentTransactions } from "components/PaymentTransactions";
import { ModalTransaction } from "components/Modals/ModalTransaction";
import { AlertModal } from "components/AlertModal";
import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useCurrentUserContext, useNotificationsContext } from "hooks";
import { useNavigate } from "react-router-dom";
import { useConfirmWithdrawalApplication } from "graphql/hooks/useMutations";
import { useGetWithdrawalApplications } from "graphql/hooks/useQueries";

const useTransactions = (filter) => {
  const { data: dataTransactions, refetch: refetchTransactions } =
    useGetWithdrawalApplications({
      variables: {
        applicationStatusFilter: filter,
      },
      fetchPolicy: "network-only",
    });

  return {
    dataTransactions,
    refetchTransactions,
  };
};

export function AdminPaymentTab() {
  const { currentUser, refetch } = useCurrentUserContext();
  const { notifications } = useNotificationsContext();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    if (!currentUser?.isAdmin) {
      navigate(-1);
    }
  }, [currentUser]);

  const [transactionData, setTransactionData] = useState();

  const handleModal = (value) => {
    setTransactionData(value);
    setModal(true);
  };

  const { dataTransactions, refetchTransactions } = useTransactions(filter);

  const [confirmWithdrawal] = useConfirmWithdrawalApplication({
    onCompleted() {
      refetchTransactions();
      setAlertModal(true);
    },
  });

  useEffect(() => {
    refetch();
  }, [notifications]);

  const handleSetTransaction = ({ id, accountServicerReference }) => {
    confirmWithdrawal({
      variables: {
        innerTransactionId: id,
        accountServicerReference,
      },
    });
    setModal(false);
    setTransactionData(undefined);
  };

  if (!currentUser?.isAdmin) return <Box>Oops</Box>;

  return (
    <Box width="100%">
      <PaymentTransactions
        data={dataTransactions}
        refetch={refetchTransactions}
        handleModal={handleModal}
        filter={filter}
        setFilter={setFilter}
      />
      <ModalTransaction
        isOpen={modal}
        onClose={() => {
          setModal(false);
        }}
        transactionData={transactionData}
        handleSetTransaction={handleSetTransaction}
      />
      <AlertModal
        onClose={() => {
          setAlertModal(false);
        }}
        isOpen={alertModal}
      >
        Withdrawal successful
      </AlertModal>
    </Box>
  );
}
