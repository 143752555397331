import { gql } from "@apollo/client";

export const GET_WITHDRAWAL_APPLICATIONS = gql`
  query getWithdrawalApplications(
    $applicationStatusFilter: GettingInnerTransactionsSorting
  ) {
    getWithdrawalApplications(
      applicationStatusFilter: $applicationStatusFilter
    ) {
      withdrawalApplications {
        id
        userId
        amount
        percentage
        createdAt
        moderationStatus
        type
        accountServicerReference
        user {
          id
          fullName
          avatarUrl
          email
        }
      }
    }
  }
`;
