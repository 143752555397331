import { gql } from "@apollo/client";

export const SUCCESS_PAYMENT = gql`
  mutation successPayment($stripeId: String!) {
    successPayment(stripeId: $stripeId) {
      id
      stripeId
      status
    }
  }
`;
