import { Box, Button, Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { useEffect } from "react";
import CHS from "./chakra.styles";

export function ModalTransaction({
  onClose,
  isOpen,
  transactionData,
  handleSetTransaction,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      accountServicerReference: "",
    },
  });
  const onSubmit = (data) => {
    handleSetTransaction({
      id: transactionData?.id,
      accountServicerReference: data?.accountServicerReference,
    });
  };

  useEffect(() => {
    setValue(
      "accountServicerReference",
      transactionData?.accountServicerReference
        ? transactionData?.accountServicerReference
        : ""
    );
  }, [isOpen]);

  return (
    <Flex sx={CHS.alertWrapper} display={isOpen ? "flex" : "none"}>
      <Box sx={CHS.alertModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex sx={CHS.body} flexDirection="column">
            <Flex justifyContent="center">
              {transactionData?.user?.fullName}
            </Flex>
            <Flex justifyContent="center">
              amount: {transactionData?.amount} €
            </Flex>
            <Flex justifyContent="center">
              <TextFieldRHF
                register={register}
                name="accountServicerReference"
                label=" "
                hasError={errors?.sum}
                errorMessage={errors?.sum?.message}
                mb={5}
                type="string"
              />
            </Flex>
            <Flex marginTop="5" justifyContent="space-around">
              <Button
                marginRight="5"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Confirm</Button>
            </Flex>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
}
