import React, { useEffect, useState } from "react";
import {
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@chakra-ui/react";
import { HeaderCab } from "components/HeaderCab";
import { ModalTitle } from "components/Modals/ModalTitle";
import Button from "components/Button/Button";
import { AlertModal } from "components/AlertModal";
import { useTranslation } from "react-i18next";
import { DropzoneFieldRHF } from "components/react-hook-form/DropzoneRHF";
import { transformBeforeUpdateGroup, transformEditGroup } from "connectors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createGroupSchema } from "validation";
import { TextFieldRHF } from "components/react-hook-form/TextFieldRHF";
import { AddEntity } from "components/AddEntity";
import { useCreateGroup, useEditGroup } from "graphql/hooks/useMutations";
import { GET_GROUPS } from "graphql/queries";
import CHS from "./chakra.styles";

const INITIAL_VALUES_CREATE_GROUP = {
  photo: null,
  name: "",
  type: "",
  roles: [],
};

export function CreateGroupModal({ isOpen, onClose, isEditMode, editedGroup }) {
  const [formSending, setFormSending] = useState(false);
  const [errorEditMessage, setErrorEditMessage] = useState("");

  const {
    isOpen: isOpenSuccessAlert,
    onOpen: onOpenSuccessAlert,
    onClose: onCloseSuccessAlert,
  } = useDisclosure();

  const {
    isOpen: isOpenErrorEditAlert,
    onOpen: onOpenErrorEditAlert,
    onClose: onCloseErrorEditAlert,
  } = useDisclosure();

  const { t } = useTranslation();

  const [createGroup] = useCreateGroup();
  const [editGroup] = useEditGroup();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUES_CREATE_GROUP,
    resolver: yupResolver(createGroupSchema),
  });

  const closeHandler = () => {
    reset(INITIAL_VALUES_CREATE_GROUP);
    onClose();
  };

  const onSubmit = (data) => {
    if (isEditMode) {
      setFormSending(true);
      editGroup({
        variables: {
          groupId: editedGroup?.id,
          input: transformEditGroup(data),
        },
        onCompleted: () => {
          setFormSending(false);
          closeHandler();
        },
        onError: (error) => {
          setFormSending(false);
          setErrorEditMessage(error?.message);
          onOpenErrorEditAlert();
        },
        refetchQueries: [GET_GROUPS],
      });
    } else {
      setFormSending(true);
      createGroup({
        variables: {
          input: {
            name: data.name,
            type: data.type,
            roles: data.roles,
          },
          file: data.photo,
        },
        onCompleted: () => {
          setFormSending(false);
          onOpenSuccessAlert();
        },
        onError: ({ graphQLErrors }) => {
          setFormSending(false);
          if (graphQLErrors) {
            switch (graphQLErrors?.[0]?.message) {
              case "SequelizeUniqueConstraintError: Name must be unique, this name already exists":
                setError("name", {
                  type: "custom",
                  message: t(
                    "app.name_must_be_unique_this_name_already_exists"
                  ),
                });
                break;
              default:
                console.log(graphQLErrors?.[0]?.message);
            }
          }
        },
        refetchQueries: [GET_GROUPS],
      });
    }
  };

  const closeSuccessAlertHandler = () => {
    closeHandler();
    onCloseSuccessAlert();
  };

  const onCloseErrorEditAlertHandler = () => {
    closeHandler();
    onCloseErrorEditAlert();
  };

  useEffect(() => {
    if (isEditMode) {
      reset(transformBeforeUpdateGroup(editedGroup));
    }
  }, [isEditMode, editedGroup]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader sx={CHS.header}>
          <HeaderCab />
          <Flex alignItems="center" justifyContent="space-between" ml={4}>
            <ModalTitle
              onClose={closeHandler}
              title={isEditMode ? t("app.update_group") : t("app.create_group")}
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDirection="column">
              <Center mb={7.5}>
                <DropzoneFieldRHF
                  control={control}
                  name="photo"
                  getValues={getValues}
                  setValue={setValue}
                  width="31"
                  height="31"
                />
              </Center>
              <Flex sx={CHS.fieldsBlock}>
                <TextFieldRHF
                  mb={4}
                  variant="flushed"
                  register={register}
                  name="name"
                  label={t("app.name")}
                  placeholder={t("app.enter_group_name")}
                  hasError={errors?.name}
                  errorMessage={errors?.name?.message}
                />
                <TextFieldRHF
                  mb={4}
                  variant="flushed"
                  register={register}
                  name="type"
                  label={t("app.type")}
                  placeholder={t("app.enter_group_type")}
                  hasError={errors?.type}
                  errorMessage={errors?.type?.message}
                />
                <AddEntity
                  name="roles"
                  label="Roles"
                  placeholder="Type to add role"
                  setValue={setValue}
                  setError={setError}
                  getValues={getValues}
                  hasError={errors?.roles}
                  errorMessage={errors?.roles?.message}
                />
              </Flex>
              <Button
                type="submit"
                size="cabBigModal"
                borderRadius="base"
                disabled={formSending}
              >
                {isEditMode ? t("app.update") : t("app.create")}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>

      {isOpenSuccessAlert && (
        <AlertModal
          isOpen={isOpenSuccessAlert}
          onClose={closeSuccessAlertHandler}
        >
          <Heading variant="cabHeadPage" lineHeight="11">
            {t("app.group_created_successfully")}
          </Heading>
        </AlertModal>
      )}

      {isOpenErrorEditAlert && (
        <AlertModal
          isOpen={isOpenErrorEditAlert}
          onClose={onCloseErrorEditAlertHandler}
        >
          <Heading variant="cabHeadPage" lineHeight="11">
            {errorEditMessage}
          </Heading>
        </AlertModal>
      )}
    </Modal>
  );
}
