import TextField from "components/TextField";

export function TextFieldRHF(props) {
  const { name, register, hasError, errorMessage, inputProps, ...rest } = props;

  return (
    <TextField
      inputProps={{ ...register(name), ...inputProps }}
      hasError={hasError}
      errorMessage={errorMessage}
      {...rest}
    />
  );
}
