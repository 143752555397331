import { Icon } from "@chakra-ui/react";

export function CloseIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect
        x="1.41406"
        width="20"
        height="2"
        rx="1"
        transform="rotate(45 1.41406 0)"
      />
      <rect
        x="0.000488281"
        y="14.1421"
        width="20"
        height="2"
        rx="1"
        transform="rotate(-45 0.000488281 14.1421)"
      />
    </Icon>
  );
}
