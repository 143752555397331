import { Box } from "@chakra-ui/react";
import { NavLink as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PATHS } from "constants/constants";
import CHS from "./chakra.styles";

export function Tabs() {
  const { t } = useTranslation();
  return (
    <Box sx={CHS.tabs}>
      <RouterLink to={PATHS.team}>{t("app.team")}</RouterLink>
      <RouterLink to={PATHS.solo}>{t("app.solo")}</RouterLink>
    </Box>
  );
}
