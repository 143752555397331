import { useMemo } from "react";

export const useDropzoneStyles = (hasError, width, height) => {
  return {
    mainContainer: {
      position: "relative",
      width,
      height,
    },

    baseDropzone: {
      position: "absolute",
      right: "0",
      bottom: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "36px",
      height: "36px",
      bgColor: "brand",
      borderRadius: "50%",
      outline: "none",
      cursor: "pointer",
      _hover: {
        opacity: "0.8",
      },
    },

    baseDropzoneSmall: {
      position: "absolute",
      top: "0",
      left: "0",
      width,
      height,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgColor: "transparent",
      borderRadius: "50%",
      outline: "none",
      cursor: "pointer",
      zIndex: "20",
      _hover: {
        opacity: "0.8",
      },
    },

    previewContainerSmall: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width,
      height,
      padding: 0,
      borderRadius: "50%",
      borderWidth: "1px",
      borderColor: hasError ? "red.500" : "transparent",
      backgroundColor: "grey.200",
      overflow: "hidden",
      zIndex: "10",
    },

    focusedStyle: {
      bgColor: "blue.500",
    },

    acceptStyle: {
      bgColor: "green.500",
    },

    rejectStyle: {
      bgColor: "red.500",
    },

    previewContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      padding: 0,
      borderRadius: "50%",
      borderWidth: "2px",
      borderColor: hasError ? "red.500" : "transparent",
      backgroundColor: "grey.200",
      overflow: "hidden",

      _hover: {
        button: {
          display: "block",
        },
      },
    },

    previewImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    delBtn: {
      position: "absolute",
      display: "none",
      top: "50%",
      left: "50%",
      width: "36px",
      height: "36px",
      backgroundColor: "brand",
      borderRadius: "8px",
      transform: "translate(-50%, -50%)",
    },
  };
};

export const useDropzoneDropStyles = ({
  styles,
  isFocused,
  isDragAccept,
  isDragReject,
  small,
}) => {
  return useMemo(
    () => ({
      ...(!small ? styles.baseDropzone : styles.baseDropzoneSmall),
      ...(isFocused && styles.focusedStyle),
      ...(isDragAccept && styles.acceptStyle),
      ...(isDragReject && styles.rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject, styles, small]
  );
};
