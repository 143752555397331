export default {
  "*": {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },

  body: {
    fontFamily: "normalInter",
    color: "black.900",
    button: {
      fontFamily: "mediumInter",
      fontSize: "md",
      lineHeight: "8",
      color: "white.900",
      backgroundColor: "brand",
      _hover: {
        opacity: 0.8,
      },
    },
    ".active": {
      color: "brand",
    },
    "a:hover": {
      textShadow: "0px 0px 1px",
      transition: ".3s",
    },
    select: {
      webkitAppearance: "none",
      mozAppearance: "none",
      appearance: "none",
      px: 2.5,
      py: 1.25,
      borderRadius: "xs",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "grey.300",
      textAlign: "center",
    },
    WebkitTapHighlightColor: "transparent",

    //! =================== Pagination STYLE ==============================
    ".rc-pagination": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ":after": {
        content: "none",
      },
    },
    ".rc-pagination-item": {
      width: "11.5",
      height: "11.5",
      borderRadius: "50%",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
      fontSize: "sm",
      lineHeight: "5",
      borderColor: "transparent",
      "&:hover": {
        borderColor: "brand",
        a: {
          color: "brand",
        },
      },
    },
    ".rc-pagination-item-active": {
      backgroundColor: "brand",
      borderColor: "transparent",
      color: "white.900",
      a: {
        color: "white.900",
      },
      "&:hover": {
        borderColor: "transparent",
        a: {
          color: "white.900",
        },
      },
      "&:focus": {
        borderColor: "transparent",
        a: {
          color: "white.900",
        },
      },
    },
    ".rc-pagination-jump-prev button, .rc-pagination-jump-next button": {
      ":after": {
        content: `"..."`,
      },
    },
    ".rc-pagination-options": {
      display: "none",
    },
    ".rc-pagination-prev, .rc-pagination-next": {
      margin: "0",
      minWidth: "0",
    },
    ".rc-pagination-jump-next, .rc-pagination-jump-prev": {
      margin: "0",
      minWidth: "12px",
    },
    ".rc-pagination-disabled": {
      path: {
        fill: "dark.100",
      },
    },
    ".chakra-modal__content-container": {
      minHeight: "100vh",
    },
  },
};
